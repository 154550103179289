import { Outlet, Link, useLocation, NavLink } from "react-router-dom";
import React from "react";

const Layout = () => {
	
	const navLogout = React.useRef();
    const location = useLocation();
    let boolHomeRoute= (location.pathname=='/' || location.pathname=='/login' || location.pathname=='/csc');
	//|| location.pathname=='/apstest'
   // console.log("location.pathname: "+location.pathname);
  function checkSessionMinsremain(){
	//console.log('navLogout');
	//navigate('logout');
	if (sessionMins==1)
		sessionMins=1;//navLogout.current?.click();
	else{
		sessionMins=sessionMins-1;
		const strTitle=sessionMins+' mins session time';
/*
		if (document.getElementById('tdlogin'))
			document.getElementById('tdlogin').title=strTitle;

*/		
		}
		
	//document.getElementById('navLogout').click();
}//end func

var sessionMins=45;
  let sessionTimer = setInterval(checkSessionMinsremain, 1000*60);
 
  //sessionStorage.setItem("minsremain",sessionMins);
  
  window.onclick = function(event) {
	  sessionMins=46;
	   checkSessionMinsremain();
	  //console.log('window.onclick');
	  //clearTimeout(sessionTimer);
	  //sessionTimer = setInterval(checkSessionMinsremain, 1000*60);
  }

	const logoutname=sessionStorage.getItem("USERNAME");
    return (
    <>
	{!boolHomeRoute && <table align='center' width='100%' border='0' style={{background:"#f2aa0f",marginTop:"0px"}} >
	<tbody>
	<tr>
		<td align='center' id='tdlogin' title='dash board' >
		<Link to="/dashboard"  ><img src='img/home.png' border='0' height='20' /></Link>
		</td>
		<td align='center' className='bannertextlarge' >
			{/*Application Processing System*/}
			Corporate Employment Services
			<div className='bannertextsmall'>Ministry of Immigration and Border Services<br/>Turks and Caicos Islands Government</div>
		</td>
		<td align='left' title={"logout "+logoutname}  >
		<span className='tooltip' ><a href='#' onClick={(e)=>{e.preventDefault(); navLogout.current?.click();}}  ><img src='img/logout.png' border='0' height='20'  /></a><span className='tooltiptext'>{"logout "+logoutname}</span></span>
		</td>
	
	</tr>
	</tbody>
	</table>
	}


      <Outlet />
<NavLink ref={navLogout} to={"/"}  style={{display:"none"}}
          state={{logout:true}}  >logout</NavLink>	  
    </>
  )
};

export default Layout;