import React, { useState, useEffect } from "react";
//import { confirm } from "react-confirm-box";
import axios from 'axios';
import {Link, NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import $ from 'jquery';

import { useNavigate } from 'react-router-dom'
//var dateFormat = require('dateformat');



const RenderWpForm = () => {

const resturl=sessionStorage.getItem("RESTURL");
//var boolFetchingSads=false;

const navigate = useNavigate();

window.addEventListener("keypress", function (event) {
 // detect event keys
 if (event.keyCode == 13) {
   //callsadfinder();
   
 }
});



const [tblwebusers, setTblwebusers] = useState([]);
const [searchcrit, setSearchcrit] = useState({txt:''});
const [webuser, setWebuser] = useState({});
const [dowait, setDowait] = useState({on:false});
const [modal, setModal] = useState({open:false});
const [idtype, setIdtype] = useState([
<option key='lsta1' value=''></option>
,<option key='lsta2' value='TCI Belonger Card'>TCI Belonger Status</option>
,<option key='lsta3' value='Passport'>Passport</option>
,<option key='lsta4' value='Driver License'>Driver License</option>
,<option key='lsta5' value='NIB or NHIB Card'>NIB or NHIB Card</option>
,<option key='lsta6' value='Other Id'>Other Id</option>
]);

const location = useLocation();


useEffect(() => {

if (tblwebusers.length<1){
	gettblwebusers();
	}//end if
	//qrylabor();
 },[]);

useEffect(() => {
	document.getElementsByName('searchcrit')[0].focus();
	
 },[JSON.stringify(tblwebusers)]);

useEffect(() => {
	console.log('webuser.VERIFY_DATE: '+webuser.VERIFY_DATE);//JSON.stringify(webuser)
	if (webuser.WEB_USER_ID!=undefined){
		if (modal.open==false)
			toggleModalsm();
			
		}//end if
		
 },[webuser]);



useEffect(() => {
		gettblwebusers();
 },[searchcrit]);

function searchcritChange(e){
	setSearchcrit({txt:e.target.value});
}//end if

function toggleModalsm() {
if (!document.querySelector(".modalsm"))
	return false;
	
const modalsm = document.querySelector(".modalsm");
	
console.log('modalsm is null: '+(modalsm==null));
modalsm.classList.toggle("show-modalsm");
const isopen=modalsm.classList.contains("show-modalsm");
setModal((prev)=>{return {...prev, open:isopen};})

if (document.querySelector(".close-button")){
	const closeButton = document.querySelector(".close-button");
	if(!closeButton.classList.contains('attached-listener')){
		closeButton.classList.add('attached-listener');
		closeButton.addEventListener("click", toggleModalsm);
		window.addEventListener("click", windowOnClick);
		}//end if
	}//end if
return false;
}//end func



function windowOnClick(event) {
const modalsm = document.querySelector(".modalsm");

if (event.target === modalsm) 
	toggleModalsm();
    
}//end func

function gettblwebusers(){
console.log('resturl: '+resturl);
   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {USERNAME:sessionStorage.getItem("USERNAME"), crit:searchcrit.txt.trim()} )
  };		

  fetch(resturl+'rarwpform/gettblwebusers',requestOptions)
      .then(res => res.json())
      .then(res => 	aftergettblwebusers(res) )
      .catch(err => console.log(err));

}//end func


function getwebuser(e, EMAIL){
e.preventDefault();
console.log('resturl: '+resturl);
   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {USERNAME:EMAIL} )
  };		

  fetch(resturl+'rarwpform/getwebuser',requestOptions)
      .then(res => res.json())
      .then(res => 	aftergetwebuser(res) )
      .catch(err => console.log(err));

}//end func

function aftergetwebuser(res){
if (res.length==undefined)
	return;

let datas={};

for(var attributename in res[0]){
		const data=res[0][attributename];
		datas[attributename] = (data==null)?'':data;
	}//end for	
	
setWebuser(datas);

}//end func

function webuserChange(e){
//const val=e.target.value;
console.log('e.target.value: '+e.target.value)
const {name, value}=e.target;

setWebuser((prev) => {
return {...prev, [name]:value}
});	
}//end func

function verifywebuser(e){
	
e.preventDefault();
console.log('resturl: '+resturl);
   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {WEB_USER_ID:webuser.WEB_USER_ID, USERNAME:sessionStorage.getItem("USERNAME")} )
  };		

  fetch(resturl+'rarwpform/verifywebuser',requestOptions)
      .then(res => res.json())
      .then(res => 	afterverifywebuser(res) )
      .catch(err => console.log(err));	
	
}//end func

function afterverifywebuser(res){
if (res.length!=undefined){
	setWebuser((prev)=>{
		return {...prev, VERIFY_DATE:res[0].VERIFY_DATE}
	});
	}//end if
	
}//end func

function aftergettblwebusers(res){
if (res.length==undefined)
	return;
	
console.log('aftergettblwebusers, res.length: '+res.length);

let htmrows=[];
res.map((col, index) => {
	const WEB_USER_ID=col['WEB_USER_ID'];
	const trkey='tr_'+WEB_USER_ID;
	//const rdkey='rd_'+appid;
	
	const htmrow=<tr key={trkey} >
	<td align='left' >{col['EMAIL']}</td>
	<td align='left' ><a href="#" className="nodecor" onClick={(e)=>{getwebuser(e, col['EMAIL']);}} ><img src="img/folderyel.png"  height='18'  border="0" />{col['FULLNAME']}</a></td>
	<td align='left' >{col['PHONE']}</td>
	<td align='left' >{col['WPT_USERNAME']}</td>
	<td align='left' >{col['CHR_VERIFY_DATE']}</td>
	<td align='left'  >{col['VERIFY_USER']}</td>
	</tr>;
	htmrows.push(htmrow);
});

setTblwebusers(htmrows);
}//end func


	
  return (
    <>
	
<div align='center' style={{marginTop:"10px", marginBottom:"10px"}}>
search criteria:&nbsp;<input name='searchcrit' defaultValue={searchcrit.text} onChange={(e)=>{searchcritChange(e);}} onFocus={(e)=>{e.target.select();}} placeholder='search by name' />
</div>
	
	<table align='center' className='customers' style={{width:"75%"}} >
	<thead>
	<tr>
		<th>email address</th>
		<th>full name</th>
		<th>phone #</th>
		<th>case worker</th>
		<th>verify date</th>
		<th>verify user</th>
		
		
	</tr>
	</thead>
	<tbody>
		{tblwebusers}
	</tbody>
	</table>

<div className="modalsm">
    <div className="modalsm-content">
        <span className="close-button"  >&times;</span>
        <div align='center' id='modalsmtext' >
		<p style={{color:"brown"}} >please verify that the Passport/Id number and name spelling is correct<br/>then click verify</p>
			{webuser.VERIFY_DATE!=null && webuser.VERIFY_DATE!='' &&
			<p><img src='img/greentick.png' border="0" />&nbsp;thank you.  verification is complete</p>
			}
			{(webuser.VERIFY_DATE==null || webuser.VERIFY_DATE=='') &&
			<table align='center'>
			<tbody>
			<tr>
			<td align='right' >Email: </td>
			<td align='left' ><input name='EMAIL' defaultValue={webuser.EMAIL} onChange={(e)=>{webuserChange(e);}}  /></td>
			</tr>
			<tr>
			<td align='right' >Full Name: </td>
			<td align='left' ><input name='FULLNAME' defaultValue={webuser.FULLNAME}  onChange={(e)=>{webuserChange(e);}} autoComplete='off' placeholder='full name' /></td>
			</tr>
			<tr>
			<td align='right' >Phone: </td>
			<td align='left' ><input name='PHONE' defaultValue={webuser.PHONE} onChange={(e)=>{webuserChange(e);}} autoComplete='off' placeholder='phone'   /></td>
			</tr>
			<tr>
			<td align='right' >Identification: </td>
			<td align='left' ><select name='ID_TYPE' value={webuser.ID_TYPE} title={webuser.ID_TYPE} onChange={(e)=>{webuserChange(e);}} >{idtype}</select></td>
			</tr>
			<tr>
			<td align='right' >Passport # or Id card #: </td>
			<td align='left' ><input  name="PASSP_OR_ID_NBR" defaultValue={webuser.PASSP_OR_ID_NBR} onChange={(e)=>{webuserChange(e);}}  placeholder='Passport # or Id card #'  autoComplete='off' /></td>
			</tr>
			{/*
			<tr>
			<td align='right' >Recent Work Permit Card Ref. No.</td>
			<td align='left' ><input name='MPE_REF' defaultValue={webuser.MPE_REF}  type="text" onChange={(e)=>{webuserChange(e);}} placeholder='skip this if you do not know'  /></td>
			</tr>
			
			*/}

			<tr>
			<td align='right' >&nbsp;</td>
			</tr>
			<tr>
			<td align='right' >&nbsp;</td>
			<td align='left' >
			{dowait.on && <div><img src='img/Loading_2.gif' border='0' height='36' /> . . . please wait</div>}
			{!dowait.on && <button className="button" id='btnupdate' onClick={(e)=>{verifywebuser(e);}} >verify this registration</button>}
			</td>
			</tr>		
			</tbody>
			</table>
			}
		</div>
    </div>
</div>
<div id="snackbar">&nbsp;</div>


    </>
  );
  


};


export default RenderWpForm;