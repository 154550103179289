import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import queryString from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom'
import {Link, NavLink } from "react-router-dom";

import "./modalsmall/modal.css"
import "./css/App.css";
import "./css/csc.css";
import "./css/tooltip.css";
import "./toasttooltip/toast.css"

import "./css/toastsnackbar.css"
import "./tabbedcontainer/tabbedcontainer.css";
import "./menu/menu.css";
import './css/index.css';
import './css/login.css';
import './css/darken.css';
import './css/responsive_sections.css';
import './css/mediaresponse.css';

import './autocomplete/jquery-ui.css';
import './autocomplete/jquery-ui.js';
import './modalsmall/modal.js';

//import "./toasttooltip/toast.js"


import WpForm from "./components/wpform";

import Csc from "./components/csc";
import WpApps from "./components/wpapps";

import WpAppstest from "./components/wpappstest";

import Wpusers from "./components/wpusers";
import Wpuserlogins from "./components/wpuserlogins";
import Wpvouchers from "./components/wpvouchers";
import Wpsummary from "./components/wpsummary";
import Invoices from "./components/invoices";


import Submitwpform from "./components/submitwpform";
import Prepwpform from "./components/prepwpform";
import Exportwpform from "./components/exportwpform";


import Importwpform from "./components/importwpform";

import Userreg from "./components/userreg";
import Registrations from "./components/registrations";
import Employers from "./components/employers";
import Person from "./components/person";

import Dashboard from "./components/dashboard";

import Layout from "./components/Layout";
import NoPage from "./components/NoPage";
import Login from "./components/Login";
//import Apstest from "./components/apstest";
import Myprofile from "./components/myprofile";



import $ from 'jquery'; 





class App extends Component {
  
global_instance_id;


state = {
  visible: true
  
};



  
  
  render() {
  
  
  
  
  const queryStringParams = queryString.parse(window.location.search);
//console.log('queryStringParams: '+JSON.stringify(queryStringParams));

    return (
	<>
<BrowserRouter>
      <Routes>
	  <Route path="/" element={<Layout />}>
        <Route index element={<Login />} />	  

      
      <Route path="wpform" element={<WpForm />} />
      <Route path="submitwpform" element={<Submitwpform />} />
      <Route path="prepwpform" element={<Prepwpform />} />
	  <Route path="exportwpform" element={<Exportwpform />} />
	  
	  
      <Route path="csc" element={<Csc />} />
      <Route path="wpapps" element={<WpApps />} />
      <Route path="wpappstest" element={<WpAppstest />} />
	  
      <Route path="wpusers" element={<Wpusers />} />
      <Route path="wpuserlogins" element={<Wpuserlogins />} />
      <Route path="wpvouchers" element={<Wpvouchers />} />
	  <Route path="wpsummary" element={<Wpsummary />} />
	  <Route path="invoices" element={<Invoices />} />
	  <Route path="myprofile" element={<Myprofile />} />

      <Route path="importwpform" element={<Importwpform />} />
	  
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="employers" element={<Employers />} />
      <Route path="person" element={<Person />} />
      <Route path="registrations" element={<Registrations />} />
      <Route path="userreg" element={<Userreg />} />
	  
      {/*<Route path="apstest" element={<Apstest />} />*/}
	  <Route path="login" element={<Login />} />
	  
	  
	<Route path="*" element={<Login />} />

      </Route>
      </Routes>
</BrowserRouter>
		  </>


    );
  }
}

export default App;
