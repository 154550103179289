import React, { useState, useEffect } from "react";
//import { confirm } from "react-confirm-box";
import axios from 'axios';
import {Link, NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import $ from 'jquery';

import { useNavigate } from 'react-router-dom'
//var dateFormat = require('dateformat');


/*
SELECT  *  FROM WPT_PERSON WHERE IMG_REF='H2624'
*/	

const RenderWpForm = () => {

const resturl=sessionStorage.getItem("RESTURL");
//var boolFetchingSads=false;

const navigate = useNavigate();

window.addEventListener("keypress", function (event) {
 // detect event keys
 if (event.keyCode == 13) {
   //callsadfinder();
   
 }
});


const navWpform = React.useRef();
const [tblperson, setTblperson] = useState([]);
const [wptapp, setWptapp] = useState([]);

const [mpe, setMpe] = useState({IMG_REF:'', FIRST_AND_LAST_NAME:'', CONTINUE:false});

const location = useLocation();


useEffect(() => {

let appid=0;

if (location.state.WPT_APP_ID!=null && location.state.WPT_APP_ID!=undefined && location.state.WPT_APP_ID!='null' && location.state.WPT_APP_ID!='undefined')
	appid=location.state.WPT_APP_ID;

if (appid!=null && appid>0 && wptapp.REF_NUM==undefined)
	getwpapp(appid);

 },[]);

useEffect(() => {
	
	if (wptapp.WPT_APP_ID!=undefined && tblperson.length<1){
		getpersons();
		}//end if
		
 },[wptapp]);




useEffect(() => {
	//console.log('mpe: '+JSON.stringify(mpe));

 },[mpe]);


function getwpapp(WPT_APP_ID){
console.log('getwpapp id: '+WPT_APP_ID);


   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {WPT_APP_ID:WPT_APP_ID} )
  };		

  fetch(resturl+'rarwpform/getwpapp',requestOptions)
      .then(res => res.json())
      .then(res => 	aftergetwpapp(res) )
      .catch(err => console.log(err));

}//end func

function aftergetwpapp(res){
setWptapp(res[0]);
}//end func

function getpersons(){

   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {MPE_OR_MPR:"wptapp", crit:wptapp.WPT_APP_ID} )
  };		

  fetch(resturl+'rarwpform/getemployers',requestOptions)
      .then(res => res.json())
      .then(res => 	aftergetpersons(res) )
      .catch(err => console.log(err));

}//end func



function aftergetpersons(res){
	
console.log('aftergetpersons, res.length: '+res.length);

let htmrows=[];
res.map((col, index) => {
	const personid=col['PERSON_ID'];
	const trkey='tr_'+personid;
	const rdKey='rd'+personid;
	
	
	const htmrow=<tr key={trkey} >
	<td align='center' >{col['GENDER']}</td>
	<td align='left' ><input type='radio' name='rdpersonid' id={rdKey} onClick={(e)=>{handleMpeChange(res[index])}}  /><label htmlFor={rdKey} className="hand" >{col['FIRST_NAME']}</label></td>
	<td align='left' >{col['MIDDLE_NAME']}</td>
	<td align='left' >{col['LAST_NAME']}</td>
	<td align='center' >{col['CHR_DOB']}</td>
	</tr>;
	htmrows.push(htmrow);
	

});

setTblperson(htmrows);
}//end func


function handleMpeChange(givenmpe){
setWptapp((prev)=>{
	return {...prev, PERSON_ID:givenmpe.PERSON_ID}
});

}//end func

function getpersonbyref(){
   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {IMG_REF:mpe.IMG_REF} )
  };		

  fetch(resturl+'rarwpform/getpersonbyref',requestOptions)
      .then(res => res.json())
      .then(res => 	aftergetpersonbyref(res) )
      .catch(err => console.log(err));	
}//end func

function aftergetpersonbyref(res){
setMpe((prev)=>{
return {...prev, FIRST_AND_LAST_NAME:res[0].FIRST_AND_LAST_NAME}
});
}//end func



function handleMpeContinue(){
setMpe((prev)=>{
	return {...prev,CONTINUE:true}	
})
if (mpe.IMG_REF!='')
	getpersonbyref();
}//end func

function callinsertdbmpe(e){
	
/*
	const cbnewmpe=document.getElementById('cbnewmpe').checked;
	if (cbnewmpe==false){
		alert('please tick the checkbox if there are no persons matching');
		return;
		}

*/
	
	console.log('callinsertdbmpe');
   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {USERNAME:sessionStorage.getItem("USERNAME"), WPT_APP_ID: wptapp.WPT_APP_ID} )
  };		

  fetch(resturl+'rarwpform/insertdbmpe',requestOptions)
      .then(res => res.json())
      .then(res => 	afterinsertdbmpe(res) )
      .catch(err => console.log(err));
}//end func

function afterinsertdbmpe(res){
	console.log('afterinsertdbmpr');
	//
	getpersons();// the newly inserted person record should show
}//end func


function updatewaperson(){
   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {USERNAME:sessionStorage.getItem("USERNAME"), WPT_APP_ID: wptapp.WPT_APP_ID, PERSON_ID: wptapp.PERSON_ID} )
  };		

  fetch(resturl+'rarwpform/updatewaperson',requestOptions)
      .then(res => res.json())
      .then(res => 	afterupdatewaperson(res) )
      .catch(err => console.log(err));
}//end func

function afterupdatewaperson(res){
if (res.length==undefined)
	alert('something went wrong.  please try again');
else
	toggleModalsm();
	
}//end func

function callsetMpe(attribNam, attribVal){
	console.log('callsetMpe attribNam: '+attribNam);
setMpe((prev) => {
return {...prev, [attribNam]:attribVal}
});

	
}//end func


function toggleModalsm() {
const modalsm = document.querySelector(".modalsm");
const trigger = document.querySelector(".trigger");
const closeButton = document.querySelector(".close-button");
	
console.log('modalsm is null: '+(modalsm==null));
modalsm.classList.toggle("show-modalsm");

if (document.querySelector(".trigger"))
	document.querySelector(".trigger").addEventListener("click", toggleModalsm);

if (document.querySelector(".close-button"))
	document.querySelector(".close-button").addEventListener("click", toggleModalsm);

window.addEventListener("click", windowOnClick);
}//end func

function windowOnClick(event) {
const modalsm = document.querySelector(".modalsm");
const trigger = document.querySelector(".trigger");
const closeButton = document.querySelector(".close-button");	
	
    if (event.target === modalsm) {
    	toggleModalsm();
    }
}//end func

function nofunc(e){
}//end func

const refnum=wptapp.REF_NUM;
const refurl=sessionStorage.getItem("UPLOADURL")+refnum+"/";
const mpefoto=refurl+"foto.png";
//const IPM_PERSON_READY=(Math.floor(wptapp.PERSON_ID)==1*wptapp.PERSON_ID)?true:false;
	
  return (
    <>
<table align='center'>
<tbody>
	<tr  key="tr_MPR_NAME" >
	<td align='right' >Employer: </td>
	<td align='left' >{wptapp.MPR_NAME}</td>
	<td align='center' rowSpan="6" ><img src={mpefoto} border='0' height="150" /></td>
	</tr>
	<tr key="tr_MPE_FIRST_NAME" >
	<td align='right' >Employee First Name: </td>
	<td align='left' >{wptapp.MPE_FIRST_NAME}</td>
	</tr>
	<tr key="tr_MPE_LAST_NAME" >
	<td align='right' >Employee Last Name: </td>
	<td align='left' >{wptapp.MPE_LAST_NAME}</td>
	</tr>	
	<tr key="tr_DOB" >
	<td align='right' >Gender: </td>
	<td align='left' >{wptapp.MPE_GENDER}</td>
	</tr>	
	<tr key="tr_DOB" >
	<td align='right' >Date of Birth: </td>
	<td align='left' >{wptapp.CHR_MPE_DOB}</td>
	</tr>	
	{/*
	<tr key="tr_CTY" >
	<td align='right' >Country of Birth: </td>
	<td align='left' >{wptapp.MPE_BIRTH_COUNTRY}</td>
	</tr>	
	
	*/}
</tbody>
</table>

<p  align='center' style={{marginTop:"9px", color:"brown"}} > before creating payment vouchers, the foreign worker needs to be matched or created in the database</p>

<fieldset className='fieldsetlg' style={{width:"75%",marginTop:"12px"}} >

<legend>Persons in the database that match the foreign worker by Name (partial), Gender, DOB</legend>

	{tblperson.length==0 && 
	<p align='center' style={{color:"brown"}} >no Persons were found in Permit Management System that match the foreign worker by Name (partial), Gender, DOB</p>
	}
	{tblperson.length>0 && <>
	<div align='center' style={{color:"brown"}} >please either click the matching person name in the table below, or click the create person button</div>
	<table align='center' className='customers' style={{width:"75%"}} >
	<thead>
	<tr>
		<th>Gender</th>
		<th>First Name</th>
		<th>Middle Name</th>
		<th>Last Name</th>
		<th>Date of Birth</th>
	</tr>
	</thead>
	<tbody>
		{tblperson}
	</tbody>
	</table>
	{
	wptapp.PERSON_ID==null &&
	<p align='center' >
	{/*
	<input type='checkbox' id='cbnewmpe' /><label htmlFor='cbnewmpe' className='hand' >no matching Permit Management System person record was found</label>
	<br/><br/>
	
	*/}
	<button className='button' onClick={(e)=>{callinsertdbmpe(e);}} >create a new person record</button>	
	</p>
	}
	{
	wptapp.PERSON_ID!=null &&
	<p align='center' >
	<button className='button' onClick={(e)=>{updatewaperson();}} >use the selected person record</button>	
	</p>
	}
	</>
	}
	
</fieldset>

          <NavLink
		  ref={navWpform}
          to={"/wpform"}
		  style={{display:"none"}}
          state={{
		  WPT_APP_ID:wptapp.WPT_APP_ID
		  }} 
        >
		&nbsp;click here to wpform
        </NavLink>


	<div className="modalsm">
		<div className="modalsm-content">
			<span className="close-button">&times;</span>
			<div align='center' id='modalsmtext' >
			<img src='img/greentick.png' border='0' height='48' /> all done!
			<p>click Ok button to return to voucher creation</p>
			<p><button className="button" style={{width:"120px"}} onClick={(e)=>{navigate('/wpvouchers',{state:{WPT_APP_ID:wptapp.WPT_APP_ID}});}} >Ok</button></p>
			
				{/*
			<NavLink ref={navWpapps} to={"/wpapps"} 
			  state={{WPT_APP_ID:wptapp.WPT_APP_ID}}  >Ok</NavLink>
				
				*/}
			</div>
		</div>
	</div>
	
    </>
  );
  


};


export default RenderWpForm;