import React, { useState, useEffect } from "react";
//import { confirm } from "react-confirm-box";
import axios from 'axios';
import {Link, NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import $ from 'jquery';

import { useNavigate } from 'react-router-dom'
//var dateFormat = require('dateformat');


const RenderWpForm = () => {

const customspremurl=sessionStorage.getItem("CUSTOMSPREMURL");
const resturl=sessionStorage.getItem("RESTURL");	

//var boolFetchingSads=false;

const navigate = useNavigate();

window.addEventListener("keypress", function (event) {
 // detect event keys
 if (event.keyCode == 13) {
   //callsadfinder();
   
 }
});

const navWpform = React.useRef();
const [linkwpform, setLinkwpform] = useState({WPT_APP_ID:0});
const [renders, setRenders] = useState({count:0});
const [mprmpe, setMprmpe] = useState({mprid:0,mpeid:0,mprname:'',mpename:''});
const [tblmpr, setTblmpr] = useState([]);
const [tblmpe, setTblmpe] = useState([]);

const [combotitle, setCombotitle] = useState([]);
const [comboctry, setComboctry] = useState([]);
const [combonaty, setCombonaty] = useState([]);
const [combogender, setCombogender] = useState([]);
const [comboprctype, setComboprctype] = useState([]);
const [combooccupcat, setCombooccupcat] = useState([]);
const [combolocation, setCombolocation] = useState([]);

const [chosenmpr, setChosenmpr] = useState({});
const [chosenmpe, setChosenmpe] = useState({});



const [newmpr, setNewmpr] = useState({
EMPL_ID:''
, COMPANY:''
, OWNER:''
, ADDRESS1:''
, LOCATION_ID:''
, OWNER_SEX:''
, OWNER_NAT:''
, PRC_TYPE:''
, PHONE:''
, EMAIL:''
, OCC_CAT_ID:''
});

const [newmpe, setNewmpe] = useState({
PERSON_ID:''
,TITLE:''
,FIRST_NAME:''
,MIDDLE_NAMES:''
,LAST_NAME:''
,FULL_NAME:''
,DOB:''
,GENDER:''
,COUNTRY_OF_BIRTH_ID:''
,NATIONALITY_ID:''
,PASSPORT:''
,NIB_NO:''
,NHIP_NO:''
,RACE_ETHNICITY:''
,DEPENDENT_ID:''
,LANGUAGE_SPOKEN1:''
});


const location = useLocation();

useEffect(() => {
getwpformoptions();
openCity('mpe');	
document.getElementById('cbnewmpe').checked=false;
document.getElementById('cbnewmpr').checked=false;
document.getElementById('cbnewmpe').disabled=true;
document.getElementById('cbnewmpr').disabled=true;
 },[]);


useEffect(() => {
const newcount=renders.count+1;
//console.log('newcount'+newcount);
/*
setRenders((prev) => {
return {...prev, count:newcount}
});

*/
	
 },[JSON.stringify(tblmpr),JSON.stringify(tblmpe)]);


useEffect(() => {
	//console.log('renders: '+JSON.stringify(renders));

 },[renders]);

useEffect(() => {
console.log('chosenmpr: '+JSON.stringify(chosenmpr));
console.log('chosenmpe: '+JSON.stringify(chosenmpe));

 },[chosenmpr, chosenmpe]);



useEffect(() => {
	//console.log('newmpr: '+JSON.stringify(newmpr));
	{/*
	const newcount=renders.count+1;
	setRenders((prev)=>{
		return {...prev, count:newcount}
		});
	
	*/}
 },[newmpr]);
 
useEffect(() => {

//console.log('mprmpe changed: '+JSON.stringify(mprmpe))	;

if ( (mprmpe.mprid>0) && (mprmpe.mpeid>0))
	openCity('insertwptapp');
	
 },[mprmpe]);

useEffect(() => {
	if (linkwpform.WPT_APP_ID>0)
		navWpform.current?.click();
 },[linkwpform]);




function handleChange(e, mpemprflag, id, name, obj){
	//console.log('handleChange obj: '+JSON.stringify(obj));
//let temp=mprmpe;

if (mpemprflag=='mpr'){
	//let temp=newmpr;
	let temp={};
	for(var attributename in newmpr){
		const data=obj[attributename.toLowerCase()];
		temp[attributename] = data;
		}//end for	
	setChosenmpr(temp);
	
	setMprmpe((prev) => {
	return {...prev, mprid:id, mprname:name}
	});
	}//end if
else{
	//let temp=newmpe;
	let temp={};
	for(var attributename in newmpe){
		//console.log('attributename.toLowerCase(): '+attributename.toLowerCase());
		const data=obj[attributename.toLowerCase()];
		temp[attributename] = data;
		}//end for	
	setChosenmpe(temp);

	setMprmpe((prev) => {
	return {...prev, mpeid:id, mpename:name}
	});
	}//end else
	
//setMprmpe(temp);
}//end func

function showsnackbar(innerHTML) {
  var x = document.getElementById("snackbar");
  x.innerHTML=innerHTML;
  x.className = "show";
  setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2500);
}//end func

function btnNewmprClick(e){
	
if (
	newmpr.COMPANY=='' ||
	newmpr.OWNER=='' ||
	newmpr.OWNER_SEX=='' ||
	newmpr.LOCATION_ID=='' ||
	newmpr.OCC_CAT_ID==''
	){
	showsnackbar('one or more mandatory field was not provided!');
	//console.log('newmpr:'+JSON.stringify(newmpr));
	return;
	}//end if	


const params = {
USERNAME:sessionStorage.getItem("USERNAME")
,mpr:newmpr
}
/*
.COMPANY
,OWNER:newmpr.OWNER
,OWNER_SEX:newmpr.OWNER_SEX
,OWNER_NAT:newmpr.OWNER_NAT
,LOCATION_ID:newmpr.LOCATION_ID
,ADDRESS1:newmpr.ADDRESS1
,OCC_CAT_ID:newmpr.OCC_CAT_ID
,PRC_TYPE:newmpr.PRC_TYPE
,PHONE:newmpr.PHONE
,EMAIL:newmpr.EMAIL
*/

   let requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( params )
  };		

  fetch(customspremurl+'rarmssql/insertdbmpr',requestOptions)
      .then(res => res.json())
      .then(res => 	afterinsertdbmpr(res) )
      .catch(err => console.log(err));
	
}//end func

function afterinsertdbmpr(res){

if (res.length==undefined)
	showsnackbar('something went wrong!  please try again');
else{
	document.getElementsByName('mpr_name')[0].value=newmpr.COMPANY;
	openCity('mpr');
	gettblmpempr('mpr');
	}
}//end func

function btnNewmpeClick(e){
//document.getElementById('modalsmtext').innerHTML=document.getElementById('divnewmpe').innerHTML;
//toggleModalsm();

if (
	newmpe.FIRST_NAME=='' ||
	newmpe.LAST_NAME=='' ||
	newmpe.DOB=='' ||
	newmpe.GENDER=='' ||
	newmpe.PASSPORT=='' ||
	newmpe.COUNTRY_OF_BIRTH_ID==''
	){
	showsnackbar('one or more mandatory field was not provided!');
	console.log('newmpe:'+JSON.stringify(newmpe));
	return;
	}//end if


const params = {
USERNAME:sessionStorage.getItem("USERNAME")
,mpe:newmpe
}
/*
,TITLE:newmpe.TITLE
,FIRST_NAME:newmpe.FIRST_NAME
,MIDDLE_NAMES:newmpe.MIDDLE_NAMES
,LAST_NAME:newmpe.LAST_NAME
,DOB:newmpe.DOB
,GENDER:newmpe.GENDER
,COUNTRY_OF_BIRTH_ID:newmpe.COUNTRY_OF_BIRTH_ID
,NATIONALITY_ID:newmpe.NATIONALITY_ID
,PASSPORT:newmpe.PASSPORT
,NIB_NO:newmpe.NIB_NO
,NHIP_NO:newmpe.NHIP_NO
,RACE_ETHNICITY:newmpe.RACE_ETHNICITY
,LANGUAGE_SPOKEN1:newmpe.LANGUAGE_SPOKEN1
*/

   let requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( params )
  };		

  fetch(customspremurl+'rarmssql/insertdbmpe',requestOptions)
      .then(res => res.json())
      .then(res => 	afterinsertdbmpe(res) )
      .catch(err => console.log(err));
	  
}//end func


function toggleModalsm() {
const modalsm = document.querySelector(".modalsm");
const trigger = document.querySelector(".trigger");
const closeButton = document.querySelector(".close-button");
	
console.log('modalsm is null: '+(modalsm==null));
modalsm.classList.toggle("show-modalsm");

if (document.querySelector(".trigger"))
	document.querySelector(".trigger").addEventListener("click", toggleModalsm);

if (document.querySelector(".close-button"))
	document.querySelector(".close-button").addEventListener("click", toggleModalsm);

window.addEventListener("click", windowOnClick);
}//end func


function windowOnClick(event) {
const modalsm = document.querySelector(".modalsm");
const trigger = document.querySelector(".trigger");
const closeButton = document.querySelector(".close-button");	
	
    if (event.target === modalsm) {
    	toggleModalsm();
    }
}//end func

function openCity(cityName) {


//console.log('openCity cityName: '+cityName);

  var i, tabcontent, tablinks;
  tabcontent = document.getElementsByClassName("tabcontent");
  //console.log('tabcontent.length: '+tabcontent.length);
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  tablinks = document.getElementsByClassName("tablinks");
  const tablinkId='btn_'+cityName;
  for (i = 0; i < tablinks.length; i++) {
	tablinks[i].className = tablinks[i].className.replace(" active", "");
	//console.log('tablinks[i].id: '+tablinks[i].id);
	if (tablinks[i].id==tablinkId)
		tablinks[i].className = tablinks[i].className+(" active");

  }//end for
  document.getElementById(cityName).style.display = "block";
  if (cityName=="newmpe"){
	  
/*
	const tempnewmpe=newmpe;
	newmpe.GENDER=document.getElementsByName("crit_gender")[0].value
	newmpe.DOB=document.getElementsByName("crit_dob")[0].value
	setNewmpe(tempnewmpe);
*/

	setNewmpe((prev) => {
	return {...prev
	, DOB:document.getElementsByName("crit_dob")[0].value
	, GENDER:document.getElementsByName("crit_gender")[0].value
	}});
	document.getElementsByName("FIRST_NAME")[0].focus();
	//document.getElementsByName("DOB")[0].value=document.getElementsByName("crit_dob")[0].value;
	//document.getElementsByName("GENDER")[0].value=document.getElementsByName("crit_gender")[0].value;
	}//end if
  else {
		document.getElementById("cbnewmpe").checked=false;
		if (cityName=="mpe")
			document.getElementsByName("crit_dob")[0].focus();
		if (cityName=="mpr")
			document.getElementsByName("mpr_name")[0].focus();
		}//end else
  
  //evt.currentTarget.className += " active";
}//end function

function nofunc(e){
}

function validateDate(isoDate) {

var result=false;

    if (isNaN(Date.parse(isoDate))) {
        result= false;
    } else {
        if (isoDate != (new Date(isoDate)).toISOString().substr(0,10)) {
            result= false;
        }
    }
    result= true;
	console.log('validateDate result: '+result);
	return result;
}//end func

function gettblmpempr(mpempr){

let name='', dob='', gender='';

if (document.getElementsByName('mpr_name')[0]){
	name=(mpempr=='mpe')?document.getElementsByName('mpe_name')[0].value.trim():document.getElementsByName('mpr_name')[0].value.trim();
	dob=(mpempr=='mpe')?document.getElementsByName('crit_dob')[0].value.trim():'';
	gender=(mpempr=='mpe')?document.getElementsByName('crit_gender')[0].value:'';
	}//end if


if (mpempr=='mpe'){
	if (validateDate(dob) && gender!=''){
		document.getElementsByName('mpe_name')[0].classList.remove("gnsbro");
		document.getElementsByName('mpe_name')[0].readOnly=false;
		}//end if
	else{
		document.getElementsByName('mpe_name')[0].classList.add("gnsbro");
		document.getElementsByName('mpe_name')[0].readOnly=true;
		}//end else

	if (!validateDate(dob) || gender=='' || name.replace(' ', '').length<3){
		console.log('exiting gettblmpempr for date or gender not set: '+dob);
		setTblmpe([]);
		return;
		}//end if
	
	}//end if
else if (mpempr=='mpr'){
	
	if (name.replace(' ', '').length<4){
		console.log('exiting gettblmpempr for name or date or gender not set: '+dob);
		setTblmpr([]);
		return;
		}//end if
		
	}//end if


	
   let requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {mpempr:mpempr, dob:dob, gender:gender, name:name} )
  };		

  fetch(customspremurl+'rarmssql/gettblmpempr',requestOptions)
      .then(res => res.json())
      .then(res => 	aftergettblmpr(res, mpempr) )
      .catch(err => console.log(err));

}//end func


function strNullCheck(strTemp){
// strTemp may be the string "NULL"
if (strTemp==null)
	return null;
else if (strTemp.toUpperCase()=="NULL")
	return null;
else if (strTemp.toUpperCase()=="'NULL'")
	return null;
else
	return strTemp
}//end func

function createWpApp(){

/*
const result=confirm('If you wish to proceed please click Ok or Yes, otherwise click Cancel or No');
if (!result)
	return;

*/

const username=strNullCheck(sessionStorage.getItem("USERNAME"));
const email=sessionStorage.getItem("EMAIL");
	
   let requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {
		  mpr:chosenmpr
		  , mpe:chosenmpe
		  , username: (username==null)?email:username
		  } )
  };		

  fetch(resturl+'rarwpform/createwpapp',requestOptions)
      .then(res => res.json())
      .then(res => 	aftercreatewpapp(res) )
      .catch(err => console.log(err));

}//end func

function aftercreatewpapp(res){
setLinkwpform({WPT_APP_ID:res[0].WPT_APP_ID});

//this.navWpform.click();
}//end func

function getwpformoptions(){
   let requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {} )
  };		

  fetch(resturl+'rarwpform/getwpformoptions',requestOptions)
      .then(res => res.json())
      .then(res => 	aftergetwpformoptions(res) )
      .catch(err => console.log(err));

}//end func


function aftergetwpformoptions(res){
	
let tempcombonaty=[];
let tempcomboctry=[];
let tempcombogender	=[];
let tempcombotitle=[];
let tempcomboprctype=[];
let tempcombooccupcat=[];
let tempcombolocation=[];

res.map((col, index) => {
	const tbl=col['COMBO'];
	
	const key="option_"+index;
	
	const strValue=col['VAL'];
	const strOption=col['OPT'];
	//console.log('afterfetchctrycodes nam: '+nam);
	if (tbl=='nationality')
		tempcombonaty.push(<option key={key} value={strValue}  >{strOption}</option>);
	else if (tbl=='country')
		tempcomboctry.push(<option key={key} value={strValue}  >{strOption}</option>);	
	else if (tbl=='gender')
		tempcombogender.push(<option key={key} value={strValue}  >{strOption}</option>);	
	else if (tbl=='title')
		tempcombotitle.push(<option key={key} value={strValue}  >{strOption}</option>);	
	else if (tbl=='prctype')
		tempcomboprctype.push(<option key={key} value={strValue}  >{strOption}</option>);	
	else if (tbl=='occupcat')
		tempcombooccupcat.push(<option key={key} value={strValue}  >{strOption}</option>);	
	else if (tbl=='location')
		tempcombolocation.push(<option key={key} value={strValue}  >{strOption}</option>);	
	
	});//end map
//console.log('aftergetwpformoptions tempcombonaty : '+JSON.stringify(tempcombonaty) );
setCombonaty(tempcombonaty);
setComboctry(tempcomboctry);
setCombotitle(tempcombotitle);
setCombogender(tempcombogender);
setComboprctype(tempcomboprctype);
setCombooccupcat(tempcombooccupcat);
setCombolocation(tempcombolocation);

	
}//end func





function afterinsertdbmpe(res){
	if (res.length==undefined)
		showsnackbar('something went wrong!  please try again');
	else{
		document.getElementsByName('crit_dob')[0].value=newmpe.DOB;
		document.getElementsByName('crit_gender')[0].value=newmpe.GENDER;
		document.getElementsByName('mpe_name')[0].value=newmpe.FIRST_NAME;
		openCity('mpe');
		gettblmpempr('mpe');
		}
}//end func

function newmpeInputChange(e){
	
const {name, value}=e.target;
setNewmpe((prev) => {
return {...prev, [name]:value}
});	
	
}//end func


function newmprInputChange(e){
	
const {name, value}=e.target;
setNewmpr((prev) => {
return {...prev, [name]:value}
});	
	
}//end func

function cbnewmprClick(e){
	console.log('cbnewmprClick ');
	const checked=e.target.checked;
//document.getElementById('btnnewmpe').style.visibility=(checked==true)?"visible":"hidden";
if (checked==true)
	openCity("newmpr");
else
	openCity("mpr");

}//end func

function cbnewmpeClick(e){
console.log('cbnewmpeClick ');
	const checked=e.target.checked;
//document.getElementById('btnnewmpe').style.visibility=(checked==true)?"visible":"hidden";

if (checked==true)
	openCity("newmpe");
else
	openCity("mpe");

}//end func

function aftergettblmpr(res, mpempr){
/*
if (res.length==undefined)
	return;
*/
	
console.log('aftergettblmpr, res.length: '+res.length);

let htmrows=[];

document.getElementById('cbnewmpe').disabled=(mpempr=='mpe' && res.length==undefined)?false:true;
document.getElementById('cbnewmpr').disabled=(mpempr=='mpr' && res.length==undefined)?false:true;

if (mpempr=='mpe'){
	if (res.length>0)
	res.map((col, index) => {
		//PERSON_ID,TITLE,FIRST_NAME,MIDDLE_NAMES,LAST_NAME,FULL_NAME,DOB,GENDER,COUNTRY_OF_BIRTH_ID,NATIONALITY_ID,PASSPORT,NIB_NO,NHIP_NO,RACE_ETHNICITY
		//BELOW is nvl(PERSON_ID, PORTAL_PERSON_ID) for persons created on the portal 
		//not having PERSON_ID from IPM
		console.log('col.fullname1: '+col.fullname1);
		const PERSON_ID=col['person_id'];
		
		const trkey='tr_'+index;
		const rdid='mpe_rd_'+index;
		const curr_res=res[index];
		
		const htmrow=<tr key={trkey} >
		<td align='left' >{col['TITLE']}</td>
		<td align='center'   >{col['CHR_DOB']}</td>
		<td align='center'   >{col['gender']}</td>
		<td align='left' style={{paddingLeft:"18px"}} ><input type='radio' name='rdmpe' id={rdid} defaultValue='' onClick={(e)=>{handleChange(e, 'mpe',PERSON_ID, col['fullname1'], curr_res);}} /><label htmlFor={rdid} className='hand' >{col.fullname1}</label></td>
		<td align='left'   >{col['COUNTRY_OF_BIRTH']}</td>
		<td align='left'   >{col['NATIONALITY']}</td>
		</tr>;
		htmrows.push(htmrow);
	});

	setTblmpe(htmrows);
	}//end if


if (mpempr=='mpr'){
	if (res.length>0)
	res.map((col, index) => {
		//PERSON_ID,TITLE,FIRST_NAME,MIDDLE_NAMES,LAST_NAME,FULL_NAME,DOB,GENDER,COUNTRY_OF_BIRTH_ID,NATIONALITY_ID,PASSPORT,NIB_NO,NHIP_NO,RACE_ETHNICITY
		const EMPL_ID=col['empl_id'];
		
		const curr_res=res[index];
		const trkey='tr_'+index;
		const rdid='mpr_rd_'+index;
		
		const htmrow=<tr key={trkey} >
		<td align='left' style={{paddingLeft:"18px"}} ><input type='radio' name='rdmpr' id={rdid} defaultValue='' onClick={(e)=>{handleChange(e, 'mpr', EMPL_ID, col['company'], curr_res);}} /><label htmlFor={rdid} className='hand' >{col['company']}</label></td>
		<td align='left'  >{col['owner']}</td>
		</tr>;
		htmrows.push(htmrow);
	});

	setTblmpr(htmrows);

	}//end if

	
}//end func

const rqd=<span style={{color:"red"}}>*&nbsp;</span>;
	
  return (
    <>
	<div align='center' ><h3 className="prepwpformtitle" >preparing new work permit application</h3></div>
	<p align='center' >
	please check if the Employee and Business Name/Employer already exists in the system.
	<br/>
	This can help avoid creating duplicate records for same Employee and Business Name/Employer
	</p>
	


<div className="tab" >
  <button className="tablinks" id='btn_mpr' onClick={(e)=>{openCity( 'mpr');}} >Lookup Employers</button>
  <button className="tablinks" id='btn_mpe' onClick={(e)=>{openCity( 'mpe');}} >Lookup Employees</button>
</div>
{/*<div align='center' style={{marginTop:"0px",marginBottom:"0px",backgroundColor:"blue"}}>apples</div>*/}
<div id="mpr" className="tabcontent"  >
{/*<h3>Lookup Business Owners/Employerss</h3>*/}
  <p>please check if the <span style={{color:"brown"}}>Business Name/Employer</span> already exists in the database.
  <br/>The lookup criteria can be found on Employer's passport bio page or belonger card</p>
  <table align='center' className='tblmpe'>
  	<tbody>
	<tr>
		<td align='right' >Business/Employer:</td>
		<td align='left' ><input name='mpr_name' onChange={(e)=>{gettblmpempr('mpr');}} autoComplete='off' placeholder='Business/Employer name' /></td>
	</tr>
	</tbody>
	</table>

	<table align='center' className='customers tblmpr' >
	<thead>
	<tr>
		<th>Business name</th>
		<th>Business Owner</th>
	</tr>
	</thead>
	<tbody>
		{tblmpr}
	</tbody>
	</table>
		<div align='center' >
		<input type='checkbox' id='cbnewmpr' onClick={(e)=>{cbnewmprClick(e);}}  />
		<label htmlFor='cbnewmpr' className="hand" >I searched but did not find the company/employer</label>
		</div>
	</div>

<div id="mpe" className="tabcontent">
{/*<h3>Lookup Employees/Employers</h3>*/}  
  <p>please check if the <span style={{color:"brown"}}>Employee</span> already exists in the database.
  <br/>The lookup criteria can be found on Employee's passport bio page</p>
  <table align='center' className='tblmpe'>
  	<tbody>
	<tr>
		<td align='right' >Employee's Date of Birth:</td>
		<td align='left' ><input name='crit_dob' onChange={(e)=>{gettblmpempr('mpe');}} type='date' />
			&nbsp;&nbsp;&nbsp;<span style={{color:"brown", fontSize:"12px"}}>mm/dd/yyyy</span>
		</td>
	</tr>
	<tr>
		<td align='right' >Gender:</td>
		<td align='left' >
			<select name='crit_gender' onChange={(e)=>{gettblmpempr('mpe');}} >
			<option value='' ></option>
			<option value='Female' >Female</option>
			<option value='Male' >Male</option>
			</select>
		</td>
	</tr>
	<tr>
		<td align='right' >Employee name:</td>
		<td align='left' ><input name='mpe_name' onChange={(e)=>{gettblmpempr('mpe');}} autoComplete='off' readOnly className="gnsbro" placeholder='Employee name' /></td>
	</tr>
	</tbody>
	</table>
	
	<table align='center' className='customers tblmpe'  >
	<thead>
	<tr>
		<th>Title</th>
		<th>Date of Birth</th>
		<th>Gender</th>
		<th>First Name, Middle Name, Last Name</th>
		<th>Country of Birth</th>
		<th>Nationality</th>
	</tr>
	</thead>
	<tbody>
		{tblmpe}
	</tbody>
	</table>
		<div align='center' >
		<input type='checkbox' id='cbnewmpe' onClick={(e)=>{cbnewmpeClick(e);}}  />
		<label htmlFor='cbnewmpe' className="hand" >I searched and did not find the employee</label>
		</div>	
	
	</div>	

<div id="insertwptapp" className="tabcontent"  >
<div align='center' >
  <fieldset className="fieldsetwpformside" >
 <legend style={{marginBottom:"9px"}} >proceeding to application form</legend>
	<table align='center' className='tblmpe'  >
	<tbody>
	<tr>
	<td align='right' >selected company/employer:</td>
	<td align='left' ><input value={mprmpe.mprname} size='38' onChange={(e)=>{nofunc(e)}} readOnly className="gnsbro" /></td>
	</tr>
	<tr>
	<td align='right' >selected employee:</td>
	<td align='left' ><input value={mprmpe.mpename} size='38'  onChange={(e)=>{nofunc(e)}} readOnly className="gnsbro" /></td>
	</tr>
	<tr>
	<td align='right' ></td>
	<td align='left' ><button className="button" onClick={(e)=>{createWpApp(e);}} >proceed to application form</button></td>
	</tr>
	</tbody>
	</table>
 
 </fieldset>
</div>	
</div>	


<div id="newmpe" className="tabcontent"  >
<div align='center' >
  <fieldset className="fieldsetwpformside" >
 <legend style={{marginBottom:"9px"}} >enter information for new employee record</legend>
 
 <table align='center' cellSpacing="0" cellPadding="0" >
 <tbody>
 <tr>
	<td align='right' >Title:</td>
	<td align='left' ><select name='TITLE' id='TITLE' defaultValue={newmpe.TITLE}  onChange={(e)=>{newmpeInputChange(e)}} ><option value='' ></option>{combotitle}</select></td>
 </tr>
 <tr>
	<td align='right' >{rqd}First name:</td>
	<td align='left' ><input name='FIRST_NAME' id='FIRST_NAME' defaultValue={newmpe.FIRST_NAME} type='text' autoComplete='off' onChange={(e)=>{newmpeInputChange(e)}} /></td>
 </tr>
 <tr>
	<td align='right' >Middle names:</td>
	<td align='left' ><input name='MIDDLE_NAMES' id='MIDDLE_NAMES' defaultValue={newmpe.MIDDLE_NAMES} type='text' autoComplete='off' onChange={(e)=>{newmpeInputChange(e)}} /></td>
 </tr>
 <tr>
	<td align='right' >{rqd}Last name:</td>
	<td align='left' ><input name='LAST_NAME' id='LAST_NAME' defaultValue={newmpe.LAST_NAME} type='text' autoComplete='off' onChange={(e)=>{newmpeInputChange(e)}} /></td>
 </tr>
 <tr>
	<td align='right' >{rqd}Date of birth:</td>
	<td align='left' ><input name='DOB' id='DOB'  defaultValue={newmpe.DOB}  type='date' onChange={(e)=>{newmpeInputChange(e)}} /></td>
 </tr>
 <tr>
	<td align='right' >{rqd}Gender:</td>
	<td align='left' ><select name='GENDER' id='GENDER'  value={newmpe.GENDER} onChange={(e)=>{newmpeInputChange(e)}} ><option value='' ></option><option value='Male' >Male</option><option value='Female' >Female</option></select></td>
 </tr>
 <tr>
	<td align='right' >{rqd}Passport #:</td>
	<td align='left' ><input name='PASSPORT' id='PASSPORT'  defaultValue={newmpe.PASSPORT}  type='text' autoComplete='off' onChange={(e)=>{newmpeInputChange(e)}} /></td>
 </tr>
 <tr>
	<td align='right' >{rqd}Country of Birth:</td>
	<td align='left' ><select name='COUNTRY_OF_BIRTH_ID'  defaultValue={newmpe.COUNTRY_OF_BIRTH_ID}  id='COUNTRY_OF_BIRTH_ID' onChange={(e)=>{newmpeInputChange(e)}} ><option value='' ></option>{comboctry}</select></td>
 </tr>
 <tr>
	<td align='right' >Nationality:</td>
	<td align='left' ><select name='NATIONALITY_ID'  defaultValue={newmpe.NATIONALITY_ID}  id='NATIONALITY_ID' onChange={(e)=>{newmpeInputChange(e)}} ><option value='' ></option>{combonaty}</select></td>
 </tr>
 <tr>
	<td align='right' >NIB no:</td>
	<td align='left' ><input name='NIB_NO' id='NIB_NO'  defaultValue={newmpe.NIB_NO} type='text' autoComplete='off' onChange={(e)=>{newmpeInputChange(e)}} /></td>
 </tr>
 <tr>
	<td align='right' >NHIP no:</td>
	<td align='left' ><input name='NHIP_NO' id='NHIP_NO'  defaultValue={newmpe.NHIP_NO} type='text' autoComplete='off' onChange={(e)=>{newmpeInputChange(e)}} /></td>
 </tr>
 <tr>
	<td align='right' >Race:</td>
	<td align='left' ><input name='RACE_ETHNICITY' id='RACE_ETHNICITY'  defaultValue={newmpe.RACE_ETHNICITY} type='text' autoComplete='off' onChange={(e)=>{newmpeInputChange(e)}} /></td>
 </tr>
 <tr>
	<td align='right' >Language Spoken:</td>
	<td align='left' ><input name='LANGUAGE_SPOKEN1' id='LANGUAGE_SPOKEN1'  defaultValue={newmpe.LANGUAGE_SPOKEN1} type='text' autoComplete='off' onChange={(e)=>{newmpeInputChange(e)}} /></td>
 </tr>
 {/*
 <tr>
	<td align='right' >Phone:</td>
	<td align='left' ><input name='PHONE' id='PHONE' defaultValue={newmpe.PHONE}  type='text' autoComplete='off' onChange={(e)=>{newmpeInputChange(e)}} /></td>
 </tr>
  <tr>
	<td align='right' >Email:</td>
	<td align='left' ><input name='EMAIL' id='EMAIL' defaultValue={newmpe.EMAIL}  type='text' autoComplete='off' onChange={(e)=>{newmpeInputChange(e)}} /></td>
 </tr> 
 */}
 </tbody>
 </table>

<div><button className="button" id='btnnewmpe' onClick={(e)=>{btnNewmpeClick(e)}} >submit new employee record</button></div>
</fieldset>
</div>	
</div>
	
	

<div id="newmpr" className="tabcontent"  >
<div align='center' >
  <fieldset className="fieldsetwpformside" >
 <legend style={{marginBottom:"9px"}} >enter information for new employer record</legend>
 
 <table align='center' cellSpacing="0" cellPadding="0" >
 <tbody>
 <tr>
	<td align='right' >{rqd}Company:</td>
	<td align='left' ><input name='COMPANY' id='COMPANY' defaultValue={newmpr.COMPANY} type='text' autoComplete='off' onChange={(e)=>{newmprInputChange(e)}} /></td>
 </tr>
 <tr>
	<td align='right' >{rqd}Business Type:</td>
	<td align='left' ><select name='OCC_CAT_ID'  defaultValue={newmpr.OCC_CAT_ID}  id='OCC_CAT_ID' onChange={(e)=>{newmprInputChange(e)}} ><option value='' ></option>{combooccupcat}</select></td>
 </tr>
 <tr>
	<td align='right' >{rqd}Business Location:</td>
	<td align='left' ><select name='LOCATION_ID'  defaultValue={newmpr.LOCATION_ID}  id='LOCATION_ID' onChange={(e)=>{newmprInputChange(e)}} ><option value='' ></option>{combolocation}</select></td>
 </tr>
 <tr>
	<td align='right' >{rqd}Owner:</td>
	<td align='left' ><input name='OWNER' id='OWNER' defaultValue={newmpr.OWNER} type='text' autoComplete='off' onChange={(e)=>{newmprInputChange(e)}} /></td>
 </tr>
 <tr>
	<td align='right' >{rqd}Owner Gender:</td>
	<td align='left' ><select name='OWNER_SEX' id='OWNER_SEX' defaultValue={newmpr.OWNER_SEX}  onChange={(e)=>{newmprInputChange(e)}} ><option value='' ></option>{combogender}</select></td>
 </tr>
 <tr>
	<td align='right' >Owner Nationality:</td>
	<td align='left' ><select name='OWNER_NAT' id='OWNER_NAT' defaultValue={newmpr.OWNER_NAT}  onChange={(e)=>{newmprInputChange(e)}} ><option value='' ></option>{combonaty}</select></td>
 </tr>
 <tr>
	<td align='right' >Owner PRC Type:</td>
	<td align='left' ><select name='PRC_TYPE' id='PRC_TYPE' defaultValue={newmpr.PRC_TYPE}  onChange={(e)=>{newmprInputChange(e)}} ><option value='' ></option>{comboprctype}</select></td>
 </tr>
 <tr>
	<td align='right' >Address:</td>
	<td align='left' ><input name='ADDRESS1' id='ADDRESS1'  defaultValue={newmpr.ADDRESS1}  type='text' autoComplete='off' onChange={(e)=>{newmprInputChange(e)}} /></td>
 </tr>
 <tr>
	<td align='right' >Phone:</td>
	<td align='left' ><input name='MPR_PHONE' id='MPR_PHONE' defaultValue={newmpr.PHONE}  type='text' autoComplete='off' onChange={(e)=>{newmprInputChange(e)}} /></td>
 </tr>
  <tr>
	<td align='right' >Email:</td>
	<td align='left' ><input name='MPR_EMAIL' id='MPR_EMAIL' defaultValue={newmpr.EMAIL}  type='text' autoComplete='off' onChange={(e)=>{newmprInputChange(e)}} /></td>
 </tr>
 </tbody>
 </table>

<div><button className="button" id='btnnewmpr' onClick={(e)=>{btnNewmprClick(e)}} >submit new employer record</button></div>
</fieldset>
</div>	
</div>	
	
	
	
	
	
	<div align='center' >
		

</div>	

<div className="modalsm">
    <div className="modalsm-content">
        <span className="close-button">&times;</span>
        <div align='center' id='modalsmtext' >
		<img src='img/ajax_loader.gif' border='0' height='48' /> . . . please wait
		</div>
    </div>
</div>
<div id="snackbar">&nbsp;</div>

        <NavLink
		  ref={navWpform}
          to={"/wpform"}
		  style={{display:"none"}}
          state={{
		  WPT_APP_ID:linkwpform.WPT_APP_ID
		  }} 
        >
		&nbsp;click here to wpform
        </NavLink>
<div id="snackbar">&nbsp;</div>
    </>
  );
  


};


export default RenderWpForm;