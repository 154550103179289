import React, { useState, useEffect } from "react";
//import { confirm } from "react-confirm-box";
import axios from 'axios';
import {Link, NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import $ from 'jquery';

import { useNavigate } from 'react-router-dom'
//var dateFormat = require('dateformat');

	

const RenderWpForm = () => {




//const resturl="https://customsapps.gov.tc/";
const resturl="https://customsprem.gov.tc/";
//const resturl="http://localhost:3001/";


const navigate = useNavigate();


const [tblproducts, setTblproducts] = useState([]);
const [searchcrit, setSearchcrit] = useState({txt:''});

const location = useLocation();


useEffect(() => {



if (tblproducts.length<1){
	gettblproducts(null);
	}//end if
	
	
 },[]);



useEffect(() => {
		document.getElementsByName('applicantname')[0].focus();
 },[JSON.stringify(tblproducts)]);




function searchcritChange(e){
	setSearchcrit({txt:e.target.value});
}//end if





function gettblproducts(e){

if (e==null)	{return;}


const crit=e.target.value.trim();
togglereseticon(crit);
console.log('gettblproducts: '+crit);

if (crit.length<3){
	setTblproducts([]);
	return;
	}//end if

   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {crit:crit} )
  };		

  fetch(resturl+'rarcsc/gettblproducts',requestOptions)
      .then(res => res.json())
      .then(res => 	aftergettblproducts(res) )
      .catch(err => console.log(err));

}//end func




function aftergettblproducts(res){
if (res.length==undefined)
	return;

	
console.log('aftergettblproducts, res.length: '+res.length);

let htmrows=[];
res.map((col, index) => {
	
	const trkey="tr_"+index;
	
	const htmrow=<tr key={trkey} >
	<td align='right' >{index+1}</td>
	<td align='left' >{col['APPLICANT_NAME']}</td>
	<td align='left' >{col['APPLICATIONTYPE']}</td>
	<td align='left' >{col['STATUS']}</td>
	<td align='left' >{col['LOCATION']}</td>
	</tr>;
	htmrows.push(htmrow);
});

setTblproducts(htmrows);
}//end func


function clearinput() {
  document.getElementById('reseticon').style.visibility='hidden';
  document.getElementById('divinputwithreset').classList.remove('divinputwithresetfocus');
  document.getElementsByName('applicantname')[0].value='';
  document.getElementsByName('applicantname')[0].focus();
  document.getElementById('reseticon').style.visibility='hidden';
  //this.applicantname= null;
  //this.componentDidMount();
  
  }//end func

function togglereseticon(text){

  if (text.trim()==''){
    document.getElementById('reseticon').style.visibility='hidden';
    document.getElementById('divinputwithreset').classList.remove('divinputwithresetfocus');
    }//end if
  else{
    document.getElementById('reseticon').style.visibility='visible';
    document.getElementById('divinputwithreset').classList.add('divinputwithresetfocus');
    }//end else
  
  }//end func

	
  return (
    <>
		<table border="0" align='center' width='100%' style={{background:"#f2aa0f", MarginTop:"0px"}} >
	<tbody>
	<tr>
	<td align='center' style={{paddingTop:"9px",paddingBottom:"9px"}} >
	<img src='img/csclogo.png' border='0' height='160' />
	</td>
	<td align='center'>
	{/*
		<span className='bannertextlarge'>Customer Service Center</span>
		<div className='bannertextmedium'>Work Permit Applications Portal</div>
	*/}
		<span className='bannertextlarge'>Customer Service Centre</span>
		<div className='bannertextsmall'>Ministry of Immigration and Border Services<br/>Turks and Caicos Islands Government</div>
	</td>
	<td align='center'  style={{paddingTop:"9px",paddingBottom:"9px"}}  >
		<img src='img/minlogo.png' border='0' height='160' />
	</td>
	</tr>
	</tbody>
	</table>

	
	<p align='center' style={{color:"Brown"}} >Products Ready for Collection</p>
<table align='center' border="0" width="75%" style={{marginTop:"10px", marginBottom:"10px"}}>
<tbody>
<tr>
<td align='right' style={{width:"50%"}} >Applicant Name: </td>
<td align='left' style={{width:"50%"}} >
<div className="inputwithreset" id="divinputwithreset" >
<input
autoComplete="off" 
name="applicantname"
id="applicantname"
type="search"
defaultValue={searchcrit.txt}
onChange={(e)=>{gettblproducts(e);}}
 />
<a  id="reseticon"  
 onClick={(e) => {clearinput(e); setTblproducts([]);} }
 ><img src="img/redx.png" border="0"
 /></a>

</div>
</td>
</tr>
</tbody>
</table>
	

        <table align="center" className="customers" style={{width:"95%"}} >
<thead>
<tr>
<th align="right" >#</th>
<th align="left" >Applicant Name</th>
<th align="left" >Application Type</th>
<th align="left" >Application Status</th>
<th align="left" >Location</th>
</tr>
</thead>

	<tbody>
		{tblproducts}
	</tbody>
	</table>
<p align='center' >register and submit work permit application online <a href="#" onClick={(e)=>{e.preventDefault(); navigate("/login");}} target="_none" >here</a></p>

       

    </>
  );
  


};


export default RenderWpForm;