import React, { useState, useEffect } from "react";
//import { confirm } from "react-confirm-box";
import axios from 'axios';
import {Link, NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import $ from 'jquery';

import { useNavigate } from 'react-router-dom'
//var dateFormat = require('dateformat');


/*
SELECT  *  FROM WPT_PERSON WHERE IMG_REF='H2624'
*/	



const RenderWpForm = () => {

const aftertriggerselect = (name, value) => {
	console.log('aftertriggerselect: '+name);
	return '';
	
}

const resturl=sessionStorage.getItem("RESTURL");
//var boolFetchingSads=false;

const navigate = useNavigate();

window.addEventListener("keypress", function (event) {
 // detect event keys
 if (event.keyCode == 13) {
   //callsadfinder();
   
 }
});


const navWpform = React.useRef();
const [linkwpform, setLinkwpform] = useState({WPT_APP_ID:0});

const [currdiv, setCurrdiv] = useState({name:'mpr'});
const [mpe, setMpe] = useState({IMG_REF:'', FIRST_AND_LAST_NAME:'', CONTINUE:false});
const [mpr, setMpr] = useState({
	EMPL_ID:0
	,COMPANY:''
	,OWNER:''
	,CONTINUE:false
	,WPT_APP_ID:0
	});



const location = useLocation();


useEffect(() => {
	
if (sessionStorage.getItem("USERNAME")==null || sessionStorage.getItem("USERNAME")=="null" || sessionStorage.getItem("USERNAME")==undefined || sessionStorage.getItem("USERNAME")==""){
	navigate("/");
	return;
	}//end if	
	
document.title =   "APS New Application";	
if (document.getElementById('cbmprdone'))
	document.getElementById('cbmprdone').disabled=true;

 },[]);



useEffect(() => {
	if (currdiv.name=='mpr'){
		if (document.getElementsByName('COMPANY')[0])
			document.getElementsByName('COMPANY')[0].focus();
		}//end if
	else if (currdiv.name=='mpe'){
		if (document.getElementsByName('IMG_REF')[0])
			document.getElementsByName('IMG_REF')[0].focus();
		}//end if
		
	
 },[currdiv]);

useEffect(() => {
	//console.log('mpe: '+JSON.stringify(mpe));
	//console.log('mpr.COMPANY: '+mpr.COMPANY);
	
	if (mpr.WPT_APP_ID>0)
		navWpform.current?.click();
	else if (mpr.CONTINUE==true)
		setCurrdiv((prev)=>{
					return {...prev, name:"mpe"}
				});
	
 },[mpr]);

useEffect(() => {
	//console.log('mpe: '+JSON.stringify(mpe));
	if (mpe.CONTINUE==true)
		setCurrdiv((prev)=>{
					return {...prev, name:"confirm"}
				});
 },[mpe]);




function getemployers(e){

const strCompany = (""+e.target.value).trim();

setMpr((prev)=>{
return {...prev, COMPANY:strCompany}
})

	//console.log('getemployers strCompany.length: '+strCompany.length);
	if (strCompany.length<3){
		($( "#COMPANY" )).autocomplete({source: []});
		document.getElementById('cbmprdone').disabled=true;
		return;
		}	


   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {MPE_OR_MPR:"companyname", crit:strCompany} )
  };		

  fetch(resturl+'rarwpform/getemployers',requestOptions)
      .then(res => res.json())
      .then(res => 	aftergetemployers(res) )
      .catch(err => console.log(err));

}//end func


function aftergetemployers(res){
document.getElementById('cbmprdone').disabled=false;
let arrCmp=[];
	
res.map((col, index) => {
			arrCmp.push(col['COMPANY']);
		});
	
	//console.log('arrCmp.length: '+arrTPT_INF.length);
	
    ($( "#COMPANY" )).autocomplete({source: arrCmp});   
  
  $('#COMPANY').autocomplete('search', '');
  document.getElementsByName('COMPANY')[0].focus();	
	
}//end func	



function getpersonbyref(){
	
	const cbnoimgref=document.getElementById('cbnoimgref').checked;
	if (cbnoimgref==true){
		setMpe((prev)=>{
		return {...prev, FIRST_AND_LAST_NAME:'',CONTINUE:true}
		});
		return;
		}//end if
	
   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {IMG_REF:mpe.IMG_REF} )
  };		

  fetch(resturl+'rarwpform/getpersonbyref',requestOptions)
      .then(res => res.json())
      .then(res => 	aftergetpersonbyref(res) )
      .catch(err => console.log(err));	
}//end func

function aftergetpersonbyref(res){
const FIRST_AND_LAST_NAME=(res.length==undefined)?'':res[0].FIRST_AND_LAST_NAME;
const CONTINUE=(res.length==undefined)?false:true;

setMpe((prev)=>{
return {...prev, FIRST_AND_LAST_NAME:FIRST_AND_LAST_NAME,CONTINUE:CONTINUE}
});
if (CONTINUE==false)
	alert('that immigration reference number was not found');
}//end func

function setMprContinue(){
const checked =document.getElementById('cbmprdone').checked;
if (checked)
	callinsertdbmpr();
else
	alert("tick the checkbox if you are done entering the employer's name");
}//end func

function handleMpeContinue(){

const checked =document.getElementById('cbnoimgref').checked;

if (mpe.IMG_REF==''){
	if (checked==true)
		setMpe((prev)=>{
			return {...prev,CONTINUE:true}	
		});
	else
		alert("tick the checkbox if you do not have immigration reference #");
	}//end if
else
	getpersonbyref();
}//end func

function callinsertdbmpr(e){
	console.log('callinsertdbmpr');
	const COMPANY=document.getElementsByName('COMPANY')[0].value;
   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {USERNAME:sessionStorage.getItem("USERNAME"), COMPANY:COMPANY} )
  };		

  fetch(resturl+'rarwpform/insertdbmpr',requestOptions)
      .then(res => res.json())
      .then(res => 	afterinsertdbmpr(res) )
      .catch(err => console.log(err));
}//end func

function afterinsertdbmpr(res){
	console.log('afterinsertdbmpr');
	const empl_id=(res.length==undefined)?null:res[0].EMPL_ID;
	
	setMpr((prev)=>{
		return{...prev, EMPL_ID:empl_id, CONTINUE:true}
	});
//aftergetemployers(res);
}//end func

function createwpapp(e){
   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {mpe:mpe, mpr:mpr, username:sessionStorage.getItem("USERNAME")} )
  };		

  fetch(resturl+'rarwpform/createwpapp',requestOptions)
      .then(res => res.json())
      .then(res => 	aftercreatewpapp(res) )
      .catch(err => console.log(err));
}//end func

function aftercreatewpapp(res){
setMpr({WPT_APP_ID:res[0].WPT_APP_ID});
}//end func

function callsetMpe(attribNam, attribVal){
	console.log('callsetMpe attribNam: '+attribNam);
setMpe((prev) => {
return {...prev, [attribNam]:attribVal}
});

	
}//end func

function clickNoimgref(e){
if (e.target.checked){
	document.getElementById('IMG_REF').value='';
	document.getElementById('IMG_REF').readOnly=true;
	document.getElementById('IMG_REF').classList.add("gnsbro");
	}
else{
	document.getElementById('IMG_REF').readOnly=false;
	document.getElementById('IMG_REF').classList.remove("gnsbro");
	}	
}//end func

function clickCbmprdone(e){
if (e.target.checked){
	document.getElementById('COMPANY').readOnly=true;
	document.getElementById('COMPANY').classList.add("gnsbro");
	setMpr((prev)=>{
		const COMPANY = document.getElementById('COMPANY').value;
		return {...prev, COMPANY:COMPANY}
	});
	}
else{
	document.getElementById('COMPANY').readOnly=false;
	document.getElementById('COMPANY').classList.remove("gnsbro");
	}

}//end func

function nofunc(e){
}//end func

	
  return (
    <>

<fieldset className='fieldsetlg' style={{width:"75%",marginTop:"30px"}} >
<legend>Turks & Caicos Work Permit Application</legend>

<table align='center' style={{marginTop:"10px", marginBottom:"10px"}} >	
<tbody>
	{currdiv.name=='mpe' &&
	<>
	<tr>
		<td align='right' >employee's Immigration reference #:</td>
		<td align='left' ><input name='IMG_REF' id='IMG_REF' defaultValue={mpe.IMG_REF} onChange={(e)=>{callsetMpe("IMG_REF",e.target.value);}} autoComplete="off" onFocus={(e)=>{e.target.select();}} placeholder="employee's Immigration ref #" /></td>
	</tr>
	{
	mpe.FIRST_AND_LAST_NAME!='' &&
	<tr>
		<td align='right' >employee's name:</td>
		<td align='left' ><input name='FIRST_AND_LAST_NAME' defaultValue={mpe.FIRST_AND_LAST_NAME} readOnly className="gnsbro" /></td>
	</tr>
	}
	<tr>
		<td align='right' >&nbsp;</td>
		<td align='left' >
			{/*mpe.IMG_REF!='' && <button className="button" onClick={(e)=>{getpersonbyref();}} >lookup employee</button>*/}
			<button className="button" onClick={(e)=>{handleMpeContinue(e);}} >continue </button>
		</td>
	</tr>
	<tr>
		<td colSpan="2" align='center' >
		<p><input type='checkbox' id='cbnoimgref' onClick={e=>{clickNoimgref(e);}}  /><label htmlFor='cbnoimgref' className="hand" >I do not have an Immigration reference number for the employee</label></p>
		</td>
	</tr>
	</>
	}
	{/*<input type='checkbox' id='cbmprdone' {mpr.COMPANY.trim().length<3 && disabled}  /><label htmlFor='cbmprdone' className="hand" >done entering employer name</label>*/}
	{currdiv.name=='mpr' &&
	<>
	<tr>
		<td align='center' colSpan="2" >
		<input type='checkbox' id='cbmprdone' onClick={(e)=>{clickCbmprdone(e);}}  /><label htmlFor='cbmprdone' className="hand" >tick here when done entering employer's name</label>
		</td>
	</tr>
	<tr>
		<td align='right' >enter employer's name:</td>
		<td align='left' ><input name='COMPANY' id='COMPANY' defaultValue={mpr.COMPANY} onChange={(e)=>{getemployers(e);}} autoComplete="off" onFocus={(e)=>{e.target.select();}} placeholder="enter employer's name" /></td>
	</tr>
	<tr>
		<td align='right' >&nbsp;</td>
		<td align='left' >
		<button id='btnmprcontinue' className="button" onClick={(e)=>{setMprContinue();}}  >continue with selected employer</button>
		</td>
	</tr>
	</>
	}
</tbody>
</table>




	
	{
	mpe.CONTINUE==true && 
			<table align='center' className='tblmpe'  >
			<tbody>
			<tr>
			<td align='right' >employer's name:</td>
			<td align='left' ><input value={mpr.COMPANY} size='38' onChange={(e)=>{nofunc(e)}} readOnly className="gnsbro" /></td>
			</tr>
			<tr>
			<td align='right' >employee's immigration ref #:</td>
			<td align='left' ><input value={mpe.FIRST_AND_LAST_NAME} size='38'  onChange={(e)=>{nofunc(e)}} readOnly className="gnsbro" /></td>
			</tr>
			<tr>
			<td align='right' ></td>
			<td align='left' ><button className="button" onClick={(e)=>{createwpapp();}} >proceed to application form</button></td>
			</tr>
			</tbody>
			</table>
	
	}	
	
</fieldset>

          <NavLink
		  ref={navWpform}
          to={"/wpform"}
		  style={{display:"none"}}
          state={{
		  WPT_APP_ID:mpr.WPT_APP_ID
		  }} 
        >
		&nbsp;click here to wpform
        </NavLink>



    </>
  );
  


};


export default RenderWpForm;