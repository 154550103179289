import React, { useState, useEffect } from "react";
import { confirm } from "react-confirm-box";
import axios from 'axios';
import {Link, NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import $ from 'jquery';

import { useNavigate } from 'react-router-dom'

//var dateFormat = require('dateformat');

const RenderWpForm = () => {

const customspremurl=sessionStorage.getItem("CUSTOMSPREMURL");
const resturl=sessionStorage.getItem("RESTURL");

//var boolFetchingSads=false;


const navigate = useNavigate();

window.addEventListener("keypress", function (event) {
 // detect event keys
 if (event.keyCode == 13) {
   //callsadfinder();
   
 }
});

const [locations, setLocations] = useState([]);
const [laborcltype, setLaborcltype] = useState([]);
const [vchroptions, setVchroptions] = useState({switchmpr:0,latefee:0,vchrtype:''});


const [renders, setRenders] = useState({count:0});
const [email, setEmail] = useState({MESSAGE:''});

const [udak, setUdak] = useState([]);
//wptappudak.AMT_WITHOUT_DOC_NO
const [tbludaklc, setTbludaklc] = useState([]);
const [tbludakwp, setTbludakwp] = useState([]);
const [wptapp, setWptapp] = useState({WPT_APP_ID:0});

const [dowait, setDowait] = useState({show:false});
const [dlwait, setDlwait] = useState({show:false});


const [TOTAL_EMPTY_FIELDS, setTOTAL_EMPTY_FIELDS] = useState({NUM:500});






const location = useLocation();


useEffect(() => {

if (sessionStorage.getItem("USERNAME")==null || sessionStorage.getItem("USERNAME")=="null" || sessionStorage.getItem("USERNAME")==undefined || sessionStorage.getItem("USERNAME")==""){
	navigate("/");
	return;
	}//end if
	
if (location.state.WPT_APP_ID!=null && location.state.WPT_APP_ID!=undefined && location.state.WPT_APP_ID!='null' && location.state.WPT_APP_ID!='undefined'){
	setWptapp({WPT_APP_ID:location.state.WPT_APP_ID});
	getwpformoptions();
	}//end if
else
	console.log("location.state.WPT_APP_ID not provided");
	//qrylabor();
 },[]);

useEffect(() => {
//console.log("useEffect"+wptapp.WPT_APP_ID);
if (wptapp.WPT_APP_ID>0){
	//console.log('wptapp.APP_TYPE_ID'+wptapp.APP_TYPE_ID)
	if (wptapp.REF_NUM==undefined){
		getwpapp();
		}//end func
	else if (wptapp.STATUS==null)
		setWptappStatus();
	}//end if
 },[wptapp]);

useEffect(() => {
//const newcount=1+renders.count;
//setRenders((prev)=>{return {...prev, count:newcount}});
setDowait({show:false});
 },[JSON.stringify(tbludaklc)]);
 
useEffect(() => {
	console.log("useEffect udak.length: "+udak.length);

if (udak.length>0)
	dosetTbludaklc();
else{
	setTbludaklc([]);
	setTbludakwp([]);
	}//end else
	

},[JSON.stringify(udak)]);

useEffect(() => {
//console.log('udak[0]: '+JSON.stringify(udak[0]));

if (udak.length>0 && tbludaklc.length>0 && tbludakwp.length>0){
	console.log('udak.NUM_LC_DOCS: '+udak[0].NUM_LC_DOCS);
	if (udak[0].NUM_LC_DOCS==0){
		$("#rdlcvchr").trigger('click');
		document.getElementById('rdlcvchr').checked=true;
		enableAmountInput('lc');
		}//end if
	else if (udak[0].NUM_WP_DOCS==0){
		document.getElementById('rdwpvchr').checked=true;
		enableAmountInput('wp');
		}//end if
	}//end if
 },[JSON.stringify(tbludaklc), JSON.stringify(tbludakwp)]);


function setWptappStatus(){

		setWptapp((prev)=>{
	return {...prev, IRD_LOGIN_ID:sessionStorage.getItem("IRD_LOGIN_ID")};
	
});
	const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {WPT_APP_ID:wptapp.WPT_APP_ID, STATUS:"CHECK"} )
  };		

  fetch(resturl+'rarwpform/setwptappstatus',requestOptions)
      .then(res => res.json())
      .then(res => 	aftersetwptappstatus(res) )
      .catch(err => console.log(err));
	  
}//end func

function aftersetwptappstatus(res){
setWptapp((prev) => {
return {...prev
, STATUS:"CHECK"
}});
/*
, CHR_ORGL_DOCS_DATE:res[0].CHR_ORGL_DOCS_DATE
, ORGL_DOCS_USER:res[0].ORGL_DOCS_USER

*/
}//end func

function getwpformoptions(){
	
	const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {WPT_APP_ID:wptapp.WPT_APP_ID} )
  };		

  fetch(resturl+'rarwpform/getwpformoptions',requestOptions)
      .then(res => res.json())
      .then(res => 	aftergetwpformoptions(res) )
      .catch(err => console.log(err));
	  
}//end func

function aftergetwpformoptions(res){
let templocations=[];
let templaborcltype=[];
	
res.map((col, index) => {
	const combo=col['COMBO'];
	const key=combo+"_"+index;
	const key2=combo+"_"+index+"_2";
	const val=col['VAL'];
	const opt=col['OPT'];
	//console.log('afterfetchctrycodes nam: '+nam);
	if (combo=='location')
		templocations.push(<option key={key} value={val}  >{opt}</option>);
	else if (combo=='laborcltype')
		templaborcltype.push(<option key={key2} value={val}  >{opt}</option>);
	
	});//end map
console.log('aftergetwpformoptions templocations.len : '+templocations.length);
setLocations(templocations);
setLaborcltype(templaborcltype);
}//end func

function getwpapp(e){

   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {WPT_APP_ID:wptapp.WPT_APP_ID} )
  };		

  fetch(resturl+'rarwpform/getwpapp',requestOptions)
      .then(res => res.json())
      .then(res => 	aftergetwpapp(res) )
      .catch(err => console.log(err));
	  

}//end func

function aftergetwpapp(res){
setWptapp(res[0]);
{/*
console.log('aftergetwpapp res[0].PERSON_ID: '+res[0].PERSON_ID);
if (res[0].PERSON_ID==null)
	navigate('/person',{state:{WPT_APP_ID:wptapp.WPT_APP_ID}});

*/}	
}//end func

async function getprepinsertdoc(e){

document.getElementById("tblsteps").scrollIntoView();
const result=await confirm('payment vouchers will be created. proceed?');
if (!result)
	return;

document.getElementById('btinsertprepdoc').style.visibility='hidden';

let requestOptions = {
  method: 'POST'
  ,headers: { 'Content-Type': 'application/json' }
  ,body: JSON.stringify( {WPT_APP_ID:wptapp.WPT_APP_ID, USERNAME:sessionStorage.getItem("USERNAME"), vchrtype:vchroptions.vchrtype } )
};		

fetch(resturl+'rarwpform/getprepinsertdoc',requestOptions)
  .then(res => res.json())
  .then(res => 	insertprepdoc(res) )
  .catch(err => console.log(err));	

  
}//end func

function aftergetcoverdocOld(res){
	console.log('aftergetcoverdoc res.length: '+res.length);


const form = new FormData();

form.append('WPT_APP_ID', wptapp.WPT_APP_ID);
form.append('REF_NUM', wptapp.REF_NUM);
form.append('USERNAME', sessionStorage.getItem("USERNAME"));


const coverdocFile=new File(res, 'cover.pdf');
form.append('file', coverdocFile);

const request_config = {
    onUploadProgress: data => {
      //Set the progress value to show the progress bard
      //setProgress({perct:Math.round((100 * data.loaded) / data.total)});
    },
};
const url='https://customsprem.gov.tc/rarwptapp/uploadsinglefile';
axios.post(url, form, request_config , cb);	

function cb(res){
	console.log('cb: '+JSON.stringify(cb));
}
	
}//end fun

function insertprepdoc(res){
	
	
console.log('insertprepdoc res: '+JSON.stringify(res));
const requestOptions = {
  method: 'POST'
  ,headers: { 'Content-Type': 'application/json' }
  ,body: JSON.stringify( res )
};		

fetch(customspremurl+'rarwptapp/insertprepdoc',requestOptions)
  .then(res => res.json())
  .then(res => 	afterinsertprepdoc(res) )
  .catch(err => console.log(err));	
	
}//end func

function afterinsertprepdoc(res){
	//console.log('afterinsertprepdoc'+JSON.stringify(res));
	
   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {docs:res, WPT_APP_ID:wptapp.WPT_APP_ID, USERNAME:sessionStorage.getItem("USERNAME")} )
  };		

  fetch(resturl+'rarwpform/updatedocno',requestOptions)
      .then(res => res.json())
      .then(res => 	afterupdatedocno(res) )
      .catch(err => console.log(err));	
	
}//end func

function afterupdatedocno(res){
	//reload the table
	aftergettblwptappudak(res);
	showsnackbar('document numbers should be displayed. an email will be sent to customer with the vouchers attached');
	{/*
afterinsertprepdoc[{"WPT_APP_ID":1,"TAX_COD":"LBRCLR","TAX_TYPE_NO":6687,"AMOUNT":100,"IRD_EMPLOYEE_NO":435,"ENTRY_USER":"CJOUTTEN","TAX_PAYER_NO":null,"TAX_CENTRE_NO":74,"RECEIPT_VOUCHER_COMMENT":"BEING PAYMENT FOR A LABOUR CLEARANCE FOR MONTELMA DIEUDONNE AS A Unknown. THIS FEE IS NON-REFUNDABLE.","UDAK_NO":9975,"UDAK_TAX_TYPE_NO":6687,"TAX_PAYER_NAME":"Sherman Gordon Lopez","RECEIVED_FROM":"Sherman Gordon Lopez","CREATED_TAX_CENTRE_NO":74,"DEPT_CREATE_VOUCHER":83,"UNDIST_FIRST_NAME":"Lopez","UNDIST_LAST_NAME":"Sherman Gordon","DOC_NO":2624502508,"TAX_TRANS_NO":2629247132,"TAX_SUB_TRANS_NO":2629250778,"TIME_ENTERED":"2024-02-15T13:02:29.000Z"}
,{"WPT_APP_ID":1,"TAX_COD":"WRKPMT","TAX_TYPE_NO":6687,"AMOUNT":600,"IRD_EMPLOYEE_NO":435,"ENTRY_USER":"CJOUTTEN","TAX_PAYER_NO":null,"TAX_CENTRE_NO":74,"RECEIPT_VOUCHER_COMMENT":"BEING BALANCE PAYMENT ON THE RENEWAL WORK PERMIT FOR MONTELMA DIEUDONNE AS Unknown REF:jYs001","UDAK_NO":9977,"UDAK_TAX_TYPE_NO":6687,"TAX_PAYER_NAME":"Sherman Gordon Lopez","RECEIVED_FROM":"Sherman Gordon Lopez","CREATED_TAX_CENTRE_NO":74,"DEPT_CREATE_VOUCHER":83,"UNDIST_FIRST_NAME":"Lopez","UNDIST_LAST_NAME":"Sherman Gordon","DOC_NO":2624502510,"TAX_TRANS_NO":2629247134,"TAX_SUB_TRANS_NO":2629250780,"TIME_ENTERED":"2024-02-15T13:02:30.000Z"}]	
	*/}
}

function gettblwptappudak(){

   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {WPT_APP_ID:wptapp.WPT_APP_ID, USERNAME:sessionStorage.getItem("USERNAME")} )
  };		

	setUdak([]);
	
  fetch(resturl+'rarwpform/gettblwptappudak',requestOptions)
      .then(res => res.json())
      .then(res => 	aftergettblwptappudak(res) )
      .catch(err => console.log(err));

}//end func

function showsnackbar(innerHTML) {
  var x = document.getElementById("snackbar");
  x.innerHTML=innerHTML;
  x.className = "show";
  setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2500);
}//end func

function handleChange(e, index){
	//console.log('handleChange udak:'+JSON.stringify(udak));
	let tempudak=udak;
	tempudak[index].AMOUNT=e.target.value;
	setUdak(tempudak);
	document.getElementById('btnsavechanges').style.visibility='visible';
	if (document.getElementById('btinsertprepdoc'))
		document.getElementById('btinsertprepdoc').style.visibility='hidden';
	
}

function aftergettblwptappudak(res){
setDowait({show:false});
if (res.length==undefined)
	return;
console.log('aftergettblwptappudak udak.length: '+udak.length);
setUdak(res);
}//end funcs

function dosetTbludaklc(){
let lchtmrows=[];
let wphtmrows=[];

console.log('udak.length : '+udak.length);

udak.map((col, index) => {
	//console.log('dosetTbludaklc col: '+JSON.stringify(col));
	const trkey='tr_'+index;
	const tdkey='td_'+index;
	const typ=col.VCHR_TYPE;
	//console.log('vchr_type:'+typ);
	//const doclink=(col.docno==null)?<></>:<a href={"https://customsprem.gov.tc/webuploads/voucher/"+col.docno+".pdf"} target="_none" >{col.docno}</a>;
	const htmrow=<><tr key={trkey} >
	<td align='center'   >{col.ACCOUNT}</td>
	<td align='center'   >{col['DOC_NO']}</td>
	<td align='center'   ><input type="text" name={typ+'AMT[]'} className="gnsbro" autoComplete='off' readOnly defaultValue={col.AMOUNT} onChange={(e)=>{handleChange(e, index);}} onFocus={(e)=>{e.target.select();}} size="9" style={{textAlign:"right"}} /></td>
	<td align='left'   >{col.ACCT_DESCP_1}</td>
	<td align='center'   >{col.MODIFY_USER}</td>
	<td align='center'   >{col.CHR_MODIFY_DATE}</td>
	</tr>
	{udak[index].AMOUNT!=null && <tr>
	<td align='center'   >&nbsp;</td>
	<td align='center'   >&nbsp;</td>
	<td align='center'   >&nbsp;</td>
	<td align='left' colSpan="1" ><textarea defaultValue={col.VCHR_COMMENT} onChange={(e)=>{handleChange(e, index);}} onFocus={(e)=>{e.target.select();}} rows="3" cols="80" ></textarea></td>
	<td align='center'   >&nbsp;</td>
	<td align='center'   >&nbsp;</td>
	</tr>}
	</>;
	if (typ=='LC')
		lchtmrows.push(htmrow);
	else
		wphtmrows.push(htmrow);
});
{/*
<td align='center' colSpan="1" ><input d defaultValue={udak[index].cmt} onChange={(e)=>{handleChange(e, index);}} onFocus={(e)=>{e.target.select();}} size="110" /></td>
*/}
setTbludaklc(lchtmrows);
setTbludakwp(wphtmrows);

}//end func

function mkvouchers(){
}

function senddeferemail(){
	
	
	
	const checked=document.getElementById('cbsendemail').checked;
	if (checked==false){
		alert('tick the checkbox if you are ready');
		return;
		}

	if (email.MESSAGE==''){
		alert('there seems to be no message to send');
		return;
		}

setDowait({show:true});

const params={
	USERNAME:sessionStorage.getItem("USERNAME")
	, MESSAGE:email.MESSAGE
	, WPT_APP_ID:wptapp.WPT_APP_ID
}

   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( params )
  };		

  fetch(resturl+'rarwpform/senddeferemail',requestOptions)
      .then(res => res.json())
      .then(res => 	aftersenddeferemail(res) )
      .catch(err => console.log(err));			
	
}//end ufnc

function aftersenddeferemail(res){
	
	setDowait({show:false});
	
	if (res.length==undefined)
		showsnackbar('something went wrong.  please try again');
	else{
		toggleModalsm();
		alert('email was sent.  all done!');
		}
		
}

function savechanges(){

setDowait({show:true});
	setUdak([]);
	const APP_TYPE_ID=document.getElementsByName('APP_TYPE_ID')[0].value;
	const username=sessionStorage.getItem("USERNAME");
	const params={udak:udak, WPT_APP_ID:wptapp.WPT_APP_ID
	, APP_TYPE_ID:APP_TYPE_ID
	, SWITCH_EMPLOYER:vchroptions.switchmpr
	, LATE_FEE:vchroptions.latefee
	, USERNAME:username};
   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( params )
  };		

  fetch(resturl+'rarwpform/updatewptappudak',requestOptions)
      .then(res => res.json())
      .then(res => 	aftersavechanges(res) )
      .catch(err => console.log(err));	
	
}//end func

function apptypeChange(apptypeid){
	console.log('apptypeChange: '+apptypeid);
	if (apptypeid=='')
		setUdak([]);
	else if (udak.length>0)
		savechanges();
	else {
		gettblwptappudak();
/*
		document.getElementById('btnsavechanges').style.visibility='visible';
		if (document.getElementById('btinsertprepdoc'))
			document.getElementById('btinsertprepdoc').style.visibility='hidden';

*/
		}//end else
}//end func

function enableAmountInput(flag){
	
setVchroptions((prev)=>{
	return {...prev, vchrtype:flag}	
});

let lcelem=document.getElementsByName('LCAMT[]');
for (var i=0; i<lcelem.length; i++){
	lcelem[i].readOnly=true;
	lcelem[i].classList.add("gnsbro");
	}//end for

let wpelem=document.getElementsByName('WPAMT[]');
for (var i=0; i<wpelem.length; i++){
	wpelem[i].readOnly=true;
	wpelem[i].classList.add("gnsbro");
	}//end for
	
flag=flag.toUpperCase();
let elem=document.getElementsByName(flag+'AMT[]');
//var i=0; len=elem.length;
for (var i=0; i<elem.length; i++){
	elem[i].readOnly=false;
	elem[i].classList.remove("gnsbro");
	}//end for


}//end func

function aftersavechanges(res){
	aftergettblwptappudak(res);
	setDowait({show:false});
	//setDowait({show:false});
	//gettblwptappudak();
	
}//end func


function setemailmessage(){
const msg=document.getElementsByName('emailmsg')[0].value;
setEmail((prev)=>{
	return {...prev, MESSAGE:msg}

});
}//end func

async function openScans (e) {
e.preventDefault();
//const fileName=;
const coverfilepath=wptapp.REF_NUM+"/scans.pdf";

    const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {filepath:coverfilepath} )
  };

	setDlwait({show:true});

  fetch(resturl+'rarwpform/getwptappfile',requestOptions)
      .then(res => afteropenScans(res, wptapp.REF_NUM+"_scans.pdf"))
      .catch(err => console.log(err));


}//end func

async function afteropenScans(res, fileName){
console.log('afteropenScans');

const blob = await res.blob()
const data = window.URL.createObjectURL(new Blob([blob]))

const link = document.createElement("a");
link.href = data;
link.setAttribute("download", fileName);
document.body.appendChild(link);
link.click();
//link.parentNode!.removeChild(link);	
link.parentNode?.removeChild(link);	
setDlwait({show:false});
}//end func

function toggleModalsm() {
const modalsm = document.querySelector(".modalsm");
const trigger = document.querySelector(".trigger");
const closeButton = document.querySelector(".close-button");
	
console.log('modalsm is null: '+(modalsm==null));
modalsm.classList.toggle("show-modalsm");

if (document.querySelector(".trigger"))
	document.querySelector(".trigger").addEventListener("click", toggleModalsm);

if (document.querySelector(".close-button"))
	document.querySelector(".close-button").addEventListener("click", toggleModalsm);

window.addEventListener("click", windowOnClick);
}//end func

async function clearwptappudak(e){
e.preventDefault();
document.getElementById("tblsteps").scrollIntoView();
const result=await confirm('reset the table and start over?');	
if (!result)
	return;


const requestOptions = {
  method: 'POST'
  ,headers: { 'Content-Type': 'application/json' }
  ,body: JSON.stringify( {WPT_APP_ID:wptapp.WPT_APP_ID, USERNAME:sessionStorage.getItem("USERNAME")} )
};

setDowait({show:true});
setUdak([]);
fetch(resturl+'rarwpform/clearwptappudak',requestOptions)
  .then(res => afterclearwptappudak(res))
  .catch(err => console.log(err));

}//end func

function afterclearwptappudak(res){
	setDowait({show:false});
	setWptapp((prev)=>{
		return {...prev, APP_TYPE_ID:''}
	});
	document.getElementsByName('APP_TYPE_ID')[0].value='';
//setLaborcltype();
}//end func


function windowOnClick(event) {
const modalsm = document.querySelector(".modalsm");
const trigger = document.querySelector(".trigger");
const closeButton = document.querySelector(".close-button");	
	
    if (event.target === modalsm) {
    	toggleModalsm();
    }
}//end func


function vchroptionsChange(vchroption, e){
	if (vchroption=="latefee")
		setVchroptions((prev)=>{return {...prev,latefee:e.target.checked?1:0}});
	else if (vchroption=="switchmpr")
		setVchroptions((prev)=>{return {...prev,switchmpr:e.target.checked?1:0}});
	
/*
document.getElementById('btnsavechanges').style.visibility='visible';
if (document.getElementById('btinsertprepdoc'))
	document.getElementById('btinsertprepdoc').style.visibility='hidden';

*/
	
}//end func


const IRD_LOGIN_ID=sessionStorage.getItem("IRD_LOGIN_ID");
const refnum=wptapp.REF_NUM;
const refurl=sessionStorage.getItem("UPLOADURL")+refnum+"/foto.png";
//const mpefoto=resturl+"rarwpform/getwptappfoto?path="+wptapp.REF_NUM+"/foto.png";
//console.log('mpefoto path: '+mpefoto);
//refurl+"foto.png";
//const scanslink=refurl+"scans.pdf";
let orgl_docs=(wptapp.ORGL_DOCS_DATE==null)?'':wptapp.ORGL_DOCS_USER+' '+wptapp.CHR_ORGL_DOCS_DATE;
	
  return (
    <>
	<p align='center' style={{marginBottom:"0px"}}>
	creating payment vouchers for Work Permit application with Ref # 
	<a href="#" title='go to application form fields' onClick={(e)=>{e.preventDefault(); navigate('/wpform', {state:{WPT_APP_ID:wptapp.WPT_APP_ID}});}} >{refnum}</a> 
	
	</p>
	{/*wptapp.PERSON_ID == null && <>
	<p  align='center' style={{color:"brown"}} > before creating payment vouchers, the foreign worker needs to be matched or created in Permit Management System</p>
	<p  align='center' ><a href='#' onClick={(e)=>{e.preventDefault();navigate('/person',{state:{WPT_APP_ID:wptapp.WPT_APP_ID}});}} >click here to proceed</a></p>
	</>
	*/}
	{true &&  
	<table align='center' id='tblsteps'  >
	<tbody>
	<tr  key="tr_STEP1" >
	<td align='left' colSpan="3" >
	<ul>
		<li>step 1: 
		
		{dlwait.show && <span><img src='img/Loading_2.gif' border='0' height='36' /> . . . please wait</span>}
		{
		!dlwait.show && 
		<a href="#" onClick={(e)=>{openScans(e);}} className="nodecor" ><img src='img/pdf.gif' border='0' /> click here to see scanned documents</a> 
		}
		
		
		</li>
		<li>step 2: choose the application type then enter the dollar amounts and click save or <a href='#' className="nodecor" onClick={(e)=>{e.preventDefault(); toggleModalsm();}} ><img src='img/refuse.png' border='0' height='26' /> click here to defer</a></li>
		<li>step 3: after the correct amounts have been saved, click the create vouchers button</li>
	</ul>
	</td>
	</tr>

	{/*
	<tr  key="tr_ORGL_DOCS" >
	<td align='right' ><input type='checkbox' id='cborgl' onClick={(e)=>{originalseen();}} /><label for ='cborgl' className='hand' >original docments seen</label>: </td>
	<td align='left' ><input  readOnly className='gnsbro' value={wptapp.ORGL_DOCS_USER?wptapp.ORGL_DOCS_USER+' '+wptapp.CHR_ORGL_DOCS_DATE:''} /></td>
	</tr>
	<tr  key="tr_IRD_LOGIN_ID" >
	<td align='right' >Voucher Creation User: </td>
	<td align='left' >{IRD_LOGIN_ID}</td>
	</tr>
	
	*/}	
	<tr  key="tr_CREATE_USER" >
	<td align='right' >Application upload person: </td>
	<td align='left' >{wptapp.WEB_USER_NAME+' '+wptapp.WEB_USER_PHONE}</td>
	</tr>
	<tr  key="tr_MPR_NAME" >
	<td align='right' >Employer: </td>
	<td align='left' >{wptapp.MPR_NAME}</td>
	<td align='center' rowSpan="6" >
		{wptapp.REF_NUM!=undefined && <>
		<img src={resturl+"rarwpform/getwptappfoto?path="+wptapp.REF_NUM+"/foto.png"} border='0' height="150" />
		<br/>
		Employee: {wptapp.MPE_FIRST_NAME+" "+wptapp.MPE_LAST_NAME}
		</>
		}
		</td>
	</tr>
	<tr key="tr_OCCUPATION" >
	<td align='right' >Job Title: </td>
	<td align='left' >{wptapp.MPE_OCCUPATION}</td>
	</tr>
	<tr key="tr_WAGES" >
	<td align='right' >Salary: </td>
	<td align='left' >{"$ "+wptapp.MPE_SALARY_AMT+" "+wptapp.MPE_SALARY_PRD}</td>
	</tr>
	<tr key="tr_WRK_CONTRACT" >
	<td align='right' >Contract Dates: </td>
	<td align='left' >{wptapp.CHR_WRK_CONTRACT_START+" to "+wptapp.CHR_WRK_CONTRACT_END}</td>
	</tr>
	<tr key="tr_vchroptions" >
	<td align='right' >Options: </td>
	<td align='left' >
		<input type="checkbox" id='cblatefee' onChange={(e)=>{vchroptionsChange("latefee", e);}} title='late fee' /><label htmlFor='cblatefee' className='hand' >late fee</label>
		&nbsp;&nbsp;
		<input type='checkbox' id='cbswmpr' onChange={(e)=>{vchroptionsChange("switchmpr", e);}} title='switch of employer' /><label htmlFor='cbswmpr' className='hand' >switch employer</label>
		</td>
	</tr>
	<tr key="key_tr_APP_TYPE_ID" >
	<td align='right' >Application Type:</td>
	<td align='left' ><select key="input_APP_TYPE_ID" name='APP_TYPE_ID' defaultValue={wptapp.APP_TYPE_ID} onChange={(e)=>{apptypeChange(e.target.value);}}  ><option defaultValue='' ></option>{laborcltype}</select></td>
	</tr>
	{/*
	<tr key="key_tr_LOCATION_ID" >
	<td align='right' >Location:</td>
	<td align='left' ><select key="input_LOCATION_ID" name='LOCATION_ID' defaultValue={wptapp.LOCATION_ID}   ><option defaultValue='' ></option>{locations}</select></td>
	</tr>
	
	*/}	
	</tbody>
	</table>
	}
	
	{tbludaklc.length>0 && <><p className="p_wpvouchers" >
	<input type='radio' name='rdvchrtype' id='rdlcvchr' onClick={(e)=>{enableAmountInput('lc');}}  /><label htmlFor='rdlcvchr' className="hand" >Enter Receipt Voucher Amounts</label>
	</p><table align='center' className='customers' style={{width:"65%"}} >
	<thead>
	<tr>
		<th>acc code</th>
		<th>doc. no.</th>
		<th>amount to be paid</th>
		<th>account description</th>
		<th>updated by</th>
		<th>updated</th>
	</tr>
	</thead>
	<tbody>
		{tbludaklc}
	</tbody>
	</table></>}
	
	
	{tbludakwp.length>0 && <><p className="p_wpvouchers" >
	<input type='radio' name='rdvchrtype' id='rdwpvchr' onClick={(e)=>{enableAmountInput('wp');}} /><label htmlFor='rdwpvchr' className="hand" >Enter Balance Receipt Voucher Amounts</label>
	</p><table align='center' className='customers' style={{width:"65%"}} >
	<thead>
	<tr>
		<th>acc code</th>
		<th>doc. no.</th>
		<th>amount to be paid</th>
		<th>account description</th>
		<th>updated by</th>
		<th>updated</th>
	</tr>
	</thead>
	<tbody>
		{tbludakwp}
	</tbody>
	</table></>}	

<p align='center' >  
		
		{dowait.show && <span><img src='img/Loading_2.gif' border='0' height='36' /> . . . please wait</span>}
		{
		!dowait.show && 
		<input type="button" id='btnsavechanges' className="button" value="save changes" onClick={(e)=>{ savechanges(); }}  />
		}
		{
			/*
		&nbsp;&nbsp;&nbsp;
		<input size='4' readOnly className='gnsbro' title='amount without voucher no.' defaultValue={udak[0]?.amttodo} />
			*/
			
		}		
		&nbsp;&nbsp;&nbsp;
		{udak[0]?.AMT_WITHOUT_DOC_NO>0 &&
		<>
		<a href='#' onClick={(e)=>{clearwptappudak(e);}}  ><img src='img/reset.png' border='0' /> clear the table</a>
		&nbsp;&nbsp;&nbsp;&nbsp;
		<input type="button" id='btinsertprepdoc' className="button" value="create payment vouchers" onClick={(e)=>{ getprepinsertdoc(e);  }}  />
		</>
		}
		
</p>  
{/*onKeyUp={(e)=>{setEmail({MESSAGE:''});}}*/}
	<div className="modalsm">
		<div className="modalsm-content">
			<span className="close-button">&times;</span>
			<div align='center' id='modalsmtext' >
			<p align='center' >Type the defer message below.<br/>  It will be emailed to the person who submitted the application</p>
			<textarea rows="7" cols="60" name='emailmsg' onKeyUp={(e)=>{setEmail({MESSAGE:''});}} ></textarea>
			<div align='center' >
			<p>
			<input type='checkbox' id='cbsendemail'  onClick={(e)=>{setemailmessage()}} /><label htmlFor='cbsendemail' className="hand" >The message is ready to be sent.</label>
			</p>
			{dowait.show==true && <><img src='img/Loading_2.gif' border='0' height='36' /> . . . please wait</>}
			{
			dowait.show==false && 
			<button className="button" style={{width:"180px"}} onClick={(e)=>{senddeferemail()}} >send the message</button>
			}	
			</div>
	
			</div>
		</div>
	</div>


<div id="snackbar">&nbsp;</div>
    </>
  );
  


};


export default RenderWpForm;