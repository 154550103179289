import React, { useState, useEffect } from "react";
//import { confirm } from "react-confirm-box";
import axios from 'axios';
import {Link, NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import $ from 'jquery';

import { useNavigate } from 'react-router-dom'

//var dateFormat = require('dateformat');


const RenderWpForm = () => {

const customspremurl=sessionStorage.getItem("CUSTOMSPREMURL");
const resturl=sessionStorage.getItem("RESTURL");	

//var boolFetchingSads=false;

const navigate = useNavigate();


const [dowait, setDowait] = useState({show:false});
const [myprofile, setMyprofile] = useState({IRD_LOGIN_ID:''});


const location = useLocation();


useEffect(() => {
	
if (sessionStorage.getItem("USERNAME")==null || sessionStorage.getItem("USERNAME")=="null" || sessionStorage.getItem("USERNAME")==undefined || sessionStorage.getItem("USERNAME")==""){
	navigate("/");
	return;
	}//end if	
	
document.title =   "APS My Profile";	
getirdloginid();

 },[]);

function afterhandleCOMPANY(res){
setDowait({show:false});
showsnackbar('the changes were saved');
}//end func
	
function handleCOMPANY(){
	
	const EMPL_COMPANY=document.getElementsByName('EMPL_COMPANY')[0].value;
	
   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {EMAIL:sessionStorage.getItem("USERNAME"), EMPL_COMPANY:EMPL_COMPANY} )
  };	
	
	setDowait({show:true});
	
  fetch(resturl+'rarwpform/userprofilecompany',requestOptions)
      .then(res => res.json())
      .then(res => 	afterhandleCOMPANY(res) )
      .catch(err => console.log(err));

}//end func

function showsnackbar(innerHTML) {
  var x = document.getElementById("snackbar");
  x.innerHTML=innerHTML;
  x.className = "show";
  setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2500);
}//end func

function getirdloginid(res){
	
   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {USERNAME:sessionStorage.getItem("USERNAME")} )
  };	
	
  fetch(resturl+'rarwpform/getirdloginid',requestOptions)
      .then(res => res.json())
      .then(res => 	aftergetirdloginid(res) )
      .catch(err => console.log(err));

	//console.log('aftergetwpapp: '+res[0].OCCUPATION);
}//end func

function aftergetirdloginid(res){
//const IRD_LOGIN_ID=(res.length>0)?res[0].IRD_LOGIN_ID:'';

if (res.length>0){
	
	if (res[0].TBL=='WPT_USERNAME')
		setMyprofile((prev)=>{
		return {...prev, IRD_LOGIN_ID:res[0].IRD_LOGIN_ID}
		});
	else if (res[0].TBL=='WEB_USER')
		setMyprofile((prev)=>{
		return {...prev, EMPL_COMPANY:res[0].EMPL_COMPANY}
		});
		
	}//end if

	

}//end func

function nofunc(){
}
  return (
    <>
	<h2 style={{marginTop:"100px"}} align='center' >Profile Information is below</h2>
	
	<table align='center'>
	<tbody>
	<tr>
	<td align='right' >Username: </td>
	<td align='left' >{sessionStorage.getItem("USERNAME")}</td>
	</tr>
	<tr>
	<td align='right' >Email: </td>
	<td align='left' >{sessionStorage.getItem("EMAIL")}</td>
	</tr>
	<tr>
	<td align='right' >Roles: </td>
	<td align='left' >{sessionStorage.getItem("NUM_ROLES")}</td>
	</tr>	
	{
	myprofile.IRD_LOGIN_ID!='' &&
	<tr>
	<td align='right' >Voucher Creation Username: </td>
	<td align='left' >{myprofile.IRD_LOGIN_ID}</td>
	</tr>
		
	}
	<tr>
	<td align='right' >Company: </td>
	<td align='left' ><input name='EMPL_COMPANY' autoComplete="off" defaultValue={myprofile.EMPL_COMPANY} onChange={(e)=>{document.getElementById('btnsave').style.visibility='visible';}} /></td>
	</tr>
	<tr>
	<td align='right' >&nbsp;</td>
	<td align='left' >
	{dowait.show && <div><img src='img/Loading_2.gif' border='0' height='36' /> . . . please wait</div>}
	{
	!dowait.show && 
	<button className='button' id='btnsave'  onClick={(e)=>{handleCOMPANY();}} style={{visibility:"hidden"}} >save changes</button>
	}	
	
	</td>
	</tr>
		{sessionStorage.getItem("USERNAME")=="ajones" && <>
		<tr>
		<td align='right' >Rest URL: </td>
		<td align='left' >{sessionStorage.getItem("RESTURL")}</td>
		</tr>
		</>
		}
	</tbody>
	</table>
	
		{/*buttonstate.clicked && <div><img src='img/Loading_2.gif' border='0' height='36' /> . . . please wait</div>}
		{
		!buttonstate.clicked && 
		<input type="button" id='btnsubmitapp' className="button" value="application summary review and submit" onClick={(e)=>{ e.preventDefault(); navWpsummary.current?.click(); }}  />
		
		*/}


<div id="snackbar">&nbsp;</div>
    </>
  );
  


};


export default RenderWpForm;