import React, { useState, useEffect } from "react";
//import { confirm } from "react-confirm-box";
import axios from 'axios';
import {Link, NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import $ from 'jquery';

import { useNavigate } from 'react-router-dom'
//var dateFormat = require('dateformat');



const RenderWpForm = () => {

const resturl=sessionStorage.getItem("RESTURL");	
//var boolFetchingSads=false;

const navigate = useNavigate();

window.addEventListener("keypress", function (event) {
 // detect event keys
 if (event.keyCode == 13) {
   //callsadfinder();
   
 }
});


const navWpform = React.useRef();
const [tbluserlogins, setTbluserlogins] = useState([]);
const [linkwpform, setLinkwpform] = useState({WPT_APP_ID:0});
const [searchcrit, setSearchcrit] = useState({txt:''});



const location = useLocation();


useEffect(() => {

if (tbluserlogins.length<1){
	gettbluserlogins();
	}//end if
	//qrylabor();
 },[]);

useEffect(() => {
	document.getElementsByName('searchcrit')[0].focus();
	
 },[JSON.stringify(tbluserlogins)]);

useEffect(() => {
	if (linkwpform.WPT_APP_ID>0)
		navWpform.current?.click();
	
 },[linkwpform]);

useEffect(() => {
		gettbluserlogins();
 },[searchcrit]);

function searchcritChange(e){
	setSearchcrit({txt:e.target.value});
}//end if

function gettbluserlogins(){

   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {USERNAME:sessionStorage.getItem("USERNAME"), crit:searchcrit.txt.trim()} )
  };		

  fetch(resturl+'rarwpform/tbluserlogins',requestOptions)
      .then(res => res.json())
      .then(res => 	aftergettbluserlogins(res) )
      .catch(err => console.log(err));

}//end func



function aftergettbluserlogins(res){
if (res.length==undefined)
	return;
	
console.log('aftergettbluserlogins, res.length: '+res.length);

let htmrows=[];
res.map((col, index) => {
	const appid=col['STAMP'];
	const trkey='tr_'+appid;
	const rdkey='td_'+appid;
	//const refnumLink=<a key={rdkey} id={rdkey} className='hand' onClick={(e)=>{handleWpappChange(e, appid)}} ><img src='img/folderyel.png' height='18' border='0' />&nbsp;{refnum}</a>;
	const htmrow=<tr key={trkey} >
	<td align='center' >{col['STAMP']}</td>
	<td align='left' >{col['EMAIL']}</td>
	<td align='center' >{col['VERIFIED']}</td>
	<td align='center' >{col['EXPIRED']}</td>
	<td align='center' >{col['IP']}</td>
	</tr>;
	htmrows.push(htmrow);
	



});

setTbluserlogins(htmrows);
}//end func

function handleWpappChange(e, appid){
	e.preventDefault();
	setLinkwpform({WPT_APP_ID:appid});

}//end func
	
  return (
    <>
	
<div align='center' style={{marginTop:"10px", marginBottom:"10px"}}>
search criteria:&nbsp;<input name='searchcrit' defaultValue={searchcrit.text} onChange={(e)=>{searchcritChange(e);}} onFocus={(e)=>{e.target.select();}} placeholder='username or email address' />
</div>
	
	<table align='center' className='customers' style={{width:"75%"}} >
	<thead>
	<tr>
		<th>time</th>
		<th>email</th>
		<th>verified</th>
		<th>expired</th>
		<th>ip</th>
	</tr>
	</thead>
	<tbody>
		{tbluserlogins}
	</tbody>
	</table>

          <NavLink
		  ref={navWpform}
          to={"/wpform"}
		  style={{display:"none"}}
          state={{
		  WPT_APP_ID:linkwpform.WPT_APP_ID
		  }} 
        >
		&nbsp;click here to wpform
        </NavLink>



    </>
  );
  


};


export default RenderWpForm;