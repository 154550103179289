import React, { useState, useEffect } from "react";
//import { confirm } from "react-confirm-box";
import axios from 'axios';
import {Link, NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import $ from 'jquery';

import { useNavigate } from 'react-router-dom'
//var dateFormat = require('dateformat');




const RenderWpForm = () => {

const navSubmitwpform = React.useRef();
const resturl=sessionStorage.getItem("RESTURL");

const [dowait, setDowait] = useState({show: false});
const [axiofl, setAxiofl] = useState({selectedFile: null});

const [progress, setProgress] = useState({
perct:null
});
const [wptapp, setWptapp] = useState({
WPT_APP_ID:0
, REF_NUM:''
, MPR_NAME:''
, MPE_NAME:''
});
const [renders, setRenders] = useState({count:0});


//var boolFetchingSads=false;

const navigate = useNavigate();

window.addEventListener("keypress", function (event) {
 // detect event keys
 if (event.keyCode == 13) {
   //callsadfinder();
   
 }
});







const location = useLocation();

useEffect(() => {

if (location.state.WPT_APP_ID!=null && location.state.WPT_APP_ID!=undefined && location.state.WPT_APP_ID!='null' && location.state.WPT_APP_ID!='undefined'){
	setWptapp((prev) => {
	return {...prev
	, WPT_APP_ID:location.state.WPT_APP_ID
	}
	});
		//, REF_NUM:location.state.REF_NUM
	}//end if
	
 },[]);

useEffect(() => {
console.log('wptapp.WPT_APP_ID: '+wptapp.WPT_APP_ID);	
if (wptapp.WPT_APP_ID>0 && wptapp.REF_NUM=='')
	getwpapp();
 },[wptapp]);

function getwpapp(){
	
   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {WPT_APP_ID:wptapp.WPT_APP_ID} )
  };		

  fetch(resturl+'rarwpform/getwpapp',requestOptions)
      .then(res => res.json())
      .then(res => 	aftergetwpapp(res) )
      .catch(err => console.log(err));	
}//end func

function aftergetwpapp(res){
const MPE_NAME=res[0].MPE_FIRST_NAME+' '+res[0].MPE_LAST_NAME;
console.log('res[0].WPT_APP_ID: '+res[0].WPT_APP_ID);
setWptapp((prev) => {
return {...prev
, WPT_APP_ID:res[0].WPT_APP_ID
, REF_NUM:res[0].REF_NUM
, MPE_NAME:MPE_NAME
, MPR_NAME:res[0].MPR_NAME
}
});
	
}//end func

function onFileChange(event) {

	// Update the state
	if (document.getElementById('btnuploadxl'))
		document.getElementById('btnuploadxl').style.visibility='visible';
	
	setAxiofl({ selectedFile: event.target.files[0] });

}//end function

async function doFileUpload () {
 // On file upload (click the upload button)
 /*
        // Create an object of formData
        const formData = new FormData();
 
        // Update the formData object
		formData.append('REG_NBR', sad.IDE_REG_SER+' '+sad.IDE_REG_NBR);
		formData.append('file', axiofl.selectedFile, axiofl.selectedFile.name);
 
        // Details of the uploaded file
        console.log(axiofl.selectedFile);
 
        // Request made to the backend api
        // Send formData object
		try{
        await axios.post(resturl+'rarsad/uploadattdoc', formData);
		} catch (error) {
  console.error(error.response.data);     // NOTE - use "error.response.data` (not "error")
  'Authorization': `Bearer ${access_token}`,
 */
 
 const FormData = require('form-data'); // npm install --save form-data

const form = new FormData();
const filename='excelFile.zip';
const fotoFile=new File([axiofl.selectedFile], filename);

form.append('WPT_APP_ID', wptapp.WPT_APP_ID);
form.append('REF_NUM', wptapp.REF_NUM);
form.append('USERNAME', sessionStorage.getItem("USERNAME"));
form.append('filename', filename);
form.append('file', fotoFile, fotoFile.name);
//form.append('filename', axiofl.selectedFile.name);


setDowait({show:true});
//importingwptapp=true;
const request_config = {
    onUploadProgress: data => {
      //Set the progress value to show the progress bard
      setProgress({perct:Math.round((100 * data.loaded) / data.total)});
    },
};
await axios.post(resturl+'rarwpform/uploadxl', form, request_config)
.then(res => afterDoFileUpload(res.data))

}//end function

function afterDoFileUpload(data){
	console.log('afterDoFileUpload');
//importingwptapp=false;
//setDowait({show:false})
navSubmitwpform.current?.click();
}//end function
	
  return (
    <>
<p align='center' style={{color:"brown"}}>
You can fill out the fields in an excel file on your computer instead of on the portal:
</p>
<div align="center" >
<ul style={{width:"50%",textAlign:"left"}}>
	<li><a className="nodecor" href='download\Work_Permit_Application_Template.xlsx' ><img src='img/excel.png' border='0' height='24' /> click here to download the template excel file to your computer</a></li>
	<li>Fill out the fields in the excel file on your computer; save changes periodically</li>
	<li>Return to the portal and upload the Excel file by clicking the button below</li>
</ul>
</div>
<p align='center' style={{color:"brown"}}>
Do not use Excel online.  Use the desktop verion of Microsoft Excel.
</p>

<table align='center' className="tbluploadxl"  cellPadding='0' cellSpacing='0' >
<tbody>

<tr>
	<td align='right' >Application Id #:</td>
	<td align='left' ><input value={wptapp.WPT_APP_ID} readOnly className="gnsbro" size="38" /></td>
</tr>
<tr>
	<td align='right' >Application Ref #:</td>
	<td align='left' ><input defaultValue={wptapp.REF_NUM} readOnly className="gnsbro" size="38" /></td>
</tr>

<tr>
	<td align='right' >Employer Name:</td>
	<td align='left' ><input defaultValue={wptapp.MPR_NAME} readOnly className="gnsbro" size="38" /></td>
</tr>

<tr>
	<td align='right' >Employee Name:</td>
	<td align='left' ><input defaultValue={wptapp.MPE_NAME.replace(/null/g, "")} readOnly className="gnsbro" size="38" /></td>
</tr>


<tr>
	<td align='right' >&nbsp;</td>

</tr>

<tr>
	<td align='right' >&nbsp;</td>
	<td align='left' style={{paddingLeft:"18px"}} >
		after filling the fields, select your excel file below
	</td>
</tr>

<tr>
	<td align='right' >

        <NavLink  to={"/wpform"} style={{}}
          state={{WPT_APP_ID:wptapp.WPT_APP_ID}}  >return to application form</NavLink>

        <NavLink ref={navSubmitwpform} to={"/submitwpform"} style={{display:"none"}}
          state={{WPT_APP_ID:wptapp.WPT_APP_ID}}  >submitwpform</NavLink>

		
	</td>
	<td align='left' style={{paddingLeft:"18px"}} >
		<input type="file" className='button' onChange={onFileChange}   />
		&nbsp;&nbsp;
		
		{dowait.show==true && <><img src='img/Loading_2.gif' border='0' height='36' /> . . . please wait</>}
		{
		dowait.show==false && 
		<button onClick={doFileUpload} className='button' id='btnuploadxl' >upload file</button>
		}		
			
	</td>
</tr>

</tbody>
</table>

    </>
  );
  


};


export default RenderWpForm;