import React, { useState, useEffect } from "react";
//import { confirm } from "react-confirm-box";
import axios from 'axios';
import {Link, NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import $ from 'jquery';

import { useNavigate } from 'react-router-dom'
//var dateFormat = require('dateformat');

	

const RenderWpForm = () => {

const resturl=sessionStorage.getItem("RESTURL");
//var boolFetchingSads=false;

const navigate = useNavigate();

window.addEventListener("keypress", function (event) {
 // detect event keys
 if (event.keyCode == 13) {
   //callsadfinder();
   
 }
});


const [modal, setModal] = useState({open:false});
const navWpform = React.useRef();
const [tblusers, setTblusers] = useState([]);
const [wptusername, setWptusername] = useState({});
const [dowait, setDowait] = useState({on:false});

const [searchcrit, setSearchcrit] = useState({name:'', txt:''});
const [custype, setCustype] = useState([
<option value='' ></option>
,<option value='Corporate' >Corporate Customers</option>
,<option value='General' >General Customers</option>
]);



const location = useLocation();


useEffect(() => {

if (sessionStorage.getItem("USERNAME")==null || sessionStorage.getItem("USERNAME")=="null" || sessionStorage.getItem("USERNAME")==undefined || sessionStorage.getItem("USERNAME")==""){
	navigate("/");
	return;
	}//end if

if (tblusers.length<1){
	gettblusers();
	}//end if
	//qrylabor();
 },[]);

useEffect(() => {
	document.getElementsByName('searchcrit')[0].focus();
	
 },[JSON.stringify(tblusers)]);

useEffect(() => {

	console.log('wptusername.EMAIL: '+wptusername.EMAIL);//JSON.stringify(webuser)
	if (wptusername.EMAIL!=undefined){
		if (modal.open==false)
			toggleModalsm();
			
		}//end if
	
 },[wptusername]);

useEffect(() => {
		gettblusers();
 },[searchcrit]);

function searchcritChange(e){
	setSearchcrit({name:e.target.name, txt:e.target.value});
}//end if

function gettblusers(){

const crit=searchcrit.name=='custype'?'':searchcrit.txt.trim();
const custype=searchcrit.name=='custype'?searchcrit.txt.trim():'';

   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {USERNAME:sessionStorage.getItem("USERNAME"), crit:crit, custype:custype} )
  };		
setTblusers([]);
  fetch(resturl+'rarwpform/tblusers',requestOptions)
      .then(res => res.json())
      .then(res => 	aftergettblusers(res) )
      .catch(err => console.log(err));

}//end func

function getwptuser(e, USERNAME){

e.preventDefault();

   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {USERNAME:USERNAME} )
  };		

  fetch(resturl+'rarwpform/getwptuser',requestOptions)
      .then(res => res.json())
      .then(res => 	aftergetwptuser(res) )
      .catch(err => console.log(err));

}//end func


function updatewptusername(){

const params={
USERNAME : wptusername.USERNAME
, FULLNAME : wptusername.FULLNAME
, EMAIL : wptusername.EMAIL
, IRD_LOGIN_ID : wptusername.IRD_LOGIN_ID
, CORP_CUSTOMERS : wptusername.CORP_CUSTOMERS
, GNRL_CUSTOMERS : wptusername.GNRL_CUSTOMERS
	
}

   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( params )
  };		

  fetch(resturl+'rarwpform/updatewptusername',requestOptions)
      .then(res => res.json())
      .then(res => 	afterupdatewptusername(res) )
      .catch(err => console.log(err));

}//end func

function afterupdatewptusername(res){
	if (res.length==undefined)
		showsnackbar('something went wrong, please try again');
	else
		showsnackbar('changes were saved successfully');
}//end func

function showsnackbar(innerHTML) {
  var x = document.getElementById("snackbar");
  x.innerHTML=innerHTML;
  x.className = "show";
  setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2500);
}//end func

function aftergetwptuser(res){
if (res.length==undefined)
	return;

let datas={};

for(var attributename in res[0]){
		const data=res[0][attributename];
		datas[attributename] = (data==null)?'':data;
	}//end for	
	
setWptusername(datas);

}//end func

function toggleModalsm() {
if (!document.querySelector(".modalsm"))
	return false;
	
const modalsm = document.querySelector(".modalsm");
	
console.log('modalsm is null: '+(modalsm==null));
modalsm.classList.toggle("show-modalsm");
const isopen=modalsm.classList.contains("show-modalsm");
setModal((prev)=>{return {...prev, open:isopen};})

if (document.querySelector(".close-button")){
	const closeButton = document.querySelector(".close-button");
	if(!closeButton.classList.contains('attached-listener')){
		closeButton.classList.add('attached-listener');
		closeButton.addEventListener("click", toggleModalsm);
		window.addEventListener("click", windowOnClick);
		}//end if
	}//end if
return false;
}//end func



function windowOnClick(event) {
const modalsm = document.querySelector(".modalsm");

if (event.target === modalsm) 
	toggleModalsm();
    
}//end func


function aftergettblusers(res){
if (res.length==undefined)
	return;
	
console.log('aftergettblusers, res.length: '+res.length);

let htmrows=[];
res.map((col, index) => {
	const USERNAME=col['USERNAME'];
	const trkey='tr_'+index;
	const rdkey='td_'+index;
	
	const htmrow=<tr key={trkey} >
	<td align='right' >{1+index}</td>
	<td align='center' >{col['USERNAME']}</td>
	<td align='left' ><a href="#" className="nodecor" onClick={(e)=>{getwptuser(e, USERNAME);}}><img src="img/folderyel.png"  height='18'  border="0" /> {col['FULLNAME']}</a></td>
	<td align='center' >{col['EMAIL']}</td>
	<td align='center' >{col['IRD_LOGIN_ID']}</td>
	<td align='center' >{col['CORP_CUSTOMERS1']}</td>
	<td align='center' >{col['GNRL_CUSTOMERS1']}</td>
	</tr>;
	htmrows.push(htmrow);
	



});

setTblusers(htmrows);
}//end func




function wptusernameChange(e){
//const val=e.target.value;
console.log('e.target.value: '+e.target.value)
const {name, value}=e.target;

setWptusername((prev) => {
return {...prev, [name]:value}
});	
}//end func


	
  return (
    <>
	
<div align='center' style={{marginTop:"10px", marginBottom:"10px"}}>
search criteria:&nbsp;<input name='searchcrit' defaultValue={searchcrit.text} onChange={(e)=>{searchcritChange(e);}} onFocus={(e)=>{e.target.select();}} placeholder='username or email address' />
<br/>customer type filter: <select name='custype' onChange={(e)=>{searchcritChange(e);}} >{custype}</select>
</div>
	
	<table align='center' className='customers' style={{width:"75%"}} >
	<thead>
	<tr>
		<th>#</th>
		<th>username</th>
		<th>full name</th>
		<th>email</th>
		<th>vouchers user</th>
		<th>corp<br/>customers</th>
		<th>genrl<br/>customers</th>
	</tr>
	</thead>
	<tbody>
		{tblusers}
	</tbody>
	</table>



<div className="modalsm">
    <div className="modalsm-content">
        <span className="close-button"  >&times;</span>
        <div align='center' id='modalsmtext' >
			<table align='center'>
			<tbody>

			<tr>
			<td align='right' >Email: </td>
			<td align='left' ><input name='EMAIL' defaultValue={wptusername.EMAIL} onChange={(e)=>{wptusernameChange(e);}}  /></td>
			</tr>
			<tr>
			<td align='right' >Full Name: </td>
			<td align='left' ><input name='FULLNAME' defaultValue={wptusername.FULLNAME}  onChange={(e)=>{wptusernameChange(e);}} autoComplete='off' placeholder='full name' /></td>
			</tr>
			<tr>
			<td align='right' >Voucher User: </td>
			<td align='left' ><input name='IRD_LOGIN_ID' defaultValue={wptusername.IRD_LOGIN_ID}  onChange={(e)=>{wptusernameChange(e);}} autoComplete='off' placeholder='Voucher User' /></td>
			</tr>
			<tr>
			<td align='right' >Corporate Customers: </td>
			<td align='left' ><input name='CORP_CUSTOMERS' defaultValue={wptusername.CORP_CUSTOMERS}  onChange={(e)=>{wptusernameChange(e);}} autoComplete='off'  /></td>
			</tr>
			<tr>
			<td align='right' >General Customers: </td>
			<td align='left' ><input name='GNRL_CUSTOMERS' defaultValue={wptusername.GNRL_CUSTOMERS}  onChange={(e)=>{wptusernameChange(e);}} autoComplete='off' /></td>
			</tr>

			<tr>
			<td align='right' >&nbsp;</td>
			</tr>
			<tr>
			<td align='right' >&nbsp;</td>
			<td align='left' >
			{dowait.on && <div><img src='img/Loading_2.gif' border='0' height='36' /> . . . please wait</div>}
			{!dowait.on && <button className="button" id='btnupdate' onClick={(e)=>{updatewptusername(e);}} >update this user</button>}
			</td>
			</tr>		
			</tbody>
			</table>
			
		</div>
    </div>
</div>
<div id="snackbar">&nbsp;</div>


    </>
  );
  


};


export default RenderWpForm;