import React, { useState, useEffect } from "react";
//import { confirm } from "react-confirm-box";
import axios from 'axios';
import {Link, NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import $ from 'jquery';

import { useNavigate } from 'react-router-dom'

//var dateFormat = require('dateformat');



const RenderWpForm = () => {

const resturl=sessionStorage.getItem("RESTURL");

//var boolFetchingSads=false;

const navigate = useNavigate();
const navWpsummary = React.useRef();

window.addEventListener("keypress", function (event) {
 // detect event keys
 if (event.keyCode == 13) {
   //callsadfinder();
   
 }
});


const [renders, setRenders] = useState({count:0});
const [tblwpfverify, setTblwpfverify] = useState([]);
const [wptapp, setWptapp] = useState({WPT_APP_ID:0});

const [dowait, setDowait] = useState({on:false});

const [TOTAL_EMPTY_FIELDS, setTOTAL_EMPTY_FIELDS] = useState({NUM:500});



const location = useLocation();


useEffect(() => {

if (sessionStorage.getItem("USERNAME")==null || sessionStorage.getItem("USERNAME")=="null" || sessionStorage.getItem("USERNAME")==undefined || sessionStorage.getItem("USERNAME")==""){
	navigate("/");
	return;
	}//end if

document.title =   "APS Submit Application";	

if (location.state.WPT_APP_ID!=null && location.state.WPT_APP_ID!=undefined && location.state.WPT_APP_ID!='null' && location.state.WPT_APP_ID!='undefined'){
	setWptapp({WPT_APP_ID:location.state.WPT_APP_ID});
	}//end if
	//qrylabor();
 },[]);

useEffect(() => {

if (wptapp.WPT_APP_ID>0 && tblwpfverify.length<1){
	gettblwpfverify();
	//getwptappforexport();
	getwpapp();
	}//end if
	//qrylabor();
 },[wptapp]);


function getwpapp(e){

   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {WPT_APP_ID:wptapp.WPT_APP_ID} )
  };		

  fetch(resturl+'rarwpform/getwpapp',requestOptions)
      .then(res => res.json())
      .then(res => 	aftergetwpapp(res) )
      .catch(err => console.log(err));

}//end func

function aftergetwpapp(res){
if (res.length==undefined){
	if (res.error!=undefined)
		showsnackbar(res.error);
	return;
	}//end if
	

setWptapp(res[0]);

}//end func






function gettblwpfverify(){

   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {WPT_APP_ID:wptapp.WPT_APP_ID} )
  };		

  fetch(resturl+'rarwpform/gettblwpfverify',requestOptions)
      .then(res => res.json())
      .then(res => 	aftergettblwpfverify(res) )
      .catch(err => console.log(err));

}//end func

function clickContinue(){
	
	
if (TOTAL_EMPTY_FIELDS.NUM>0){
	showsnackbar(TOTAL_EMPTY_FIELDS.NUM+' mandatory fields remain to be populated');
	return;
	}//end if	
else	
	navWpsummary.current?.click();
}//end func

function showsnackbar(innerHTML) {
  var x = document.getElementById("snackbar");
  x.innerHTML=innerHTML;
  x.className = "show";
  setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2500);
}//end func

function aftergettblwpfverify(res){
if (res.length==undefined)
	return;
	
console.log('aftergettblwpfverify, res.length: '+res.length);

let htmrows=[];
res.map((col, index) => {
	if (index==1)
		setTOTAL_EMPTY_FIELDS({NUM:col['TOTAL_EMPTY_FIELDS']});
	
	const SCTN=col['SCTN'];
	const SHORT_NAME=col['SHORT_NAME'];
	const NUM_FLD_RQD=col['NUM_FLD_RQD'];
	const NUM_DATA=col['NUM_DATA'];
	
	const MPT_FLD=col['MPT_FLD'];
	const trkey='tr_'+index;
	const tdkey='td_'+index;
	const rdkey='td_'+index;
	const ok=(NUM_FLD_RQD>NUM_DATA)?<img src='img/alert.png' height='18' border='0' />:<img src='img/greentick.png' height='18' border='0' />
	
	const htmrow=<tr key={trkey} >
	<td align='left' key={tdkey} className="hand"  onClick={(e)=>{navigate('/wpform',{state:{WPT_APP_ID:wptapp.WPT_APP_ID,SCTN:SCTN}});}} >{1+index}:&nbsp;{SHORT_NAME}</td>
	<td align='center' key={'c3'+tdkey}  >{NUM_FLD_RQD}</td>
	<td align='center' key={'c4'+tdkey}  >{NUM_DATA}</td>
	<td align='center' key={'c5'+tdkey}  >{ok}</td>
	<td align='left' key={'c6'+tdkey}  >{MPT_FLD}</td>
	</tr>;
	htmrows.push(htmrow);
});

setTblwpfverify(htmrows);
}//end func


	
  return (
    <>
	<p align='center' >Please verify below that all mandatory input fields are populated<br/>before submitting your Work Petmit Applicaiton to Customer Service Center</p>
	<table align='center'>
	<tbody>
	<tr>
	<td align='right' >Employer</td>
	<td align='left' >{wptapp.MPR_NAME}</td>
	</tr>
	<tr>
	<td align='right' >Employee</td>
	<td align='left' >{wptapp.MPE_FIRST_NAME+' '+wptapp.MPE_LAST_NAME}</td>
	</tr>
	
	</tbody>
	</table>
	
	<table align='center' className='customers' style={{width:"65%"}} >
	<thead>
	<tr>
		<th>section:<br/>click the section to<br/>return to application</th>
		<th># of mandatory<br/>input fields</th>
		<th># of populated<br/>input fields</th>
		<th>verify<br/>Ok</th>
		<th>name of unpopulated input field</th>
	</tr>
	</thead>
	<tbody>
		{tblwpfverify}
	</tbody>
	</table>

<p align='center' >  

        <NavLink ref={navWpsummary} to={"/wpsummary"} style={{display:"none"}}
          state={{WPT_APP_ID:wptapp.WPT_APP_ID}}  >wpsummary</NavLink>
		
        <NavLink to={"/wpform"} state={{WPT_APP_ID:wptapp.WPT_APP_ID}} >return to application form</NavLink>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
		
		{dowait.on && <div><img src='img/Loading_2.gif' border='0' height='36' /> . . . please wait</div>}
		{
		!dowait.on && 
		<input type="button" id='btnsubmitapp' className="button" value="application summary review and submit" onClick={(e)=>{ e.preventDefault(); clickContinue(); }}  />
		/*e.preventDefault(); wpsummary.js preprunireport(e); */
		}
</p>  

<div id="snackbar">&nbsp;</div>
    </>
  );
  


};


export default RenderWpForm;