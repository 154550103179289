import React, { useState, useEffect } from "react";
//import { confirm } from "react-confirm-box";
import axios from 'axios';
import {Link, NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import $ from 'jquery';

import { useNavigate } from 'react-router-dom'

//var dateFormat = require('dateformat');



const RenderWpForm = () => {

const resturl=sessionStorage.getItem("RESTURL");

//var boolFetchingSads=false;

const navigate = useNavigate();


const [progress, setProgress] = useState({
perct:null
});
const [selectedFiles, setSelectedFiles] = useState([]);
const [dowait, setDowait] = useState({on:false});
const [legalstatus, setLegalstatus] = useState([
<option value=''></option>
,<option value='TCI Belonger Status'>TCI Belonger Status</option>
,<option value='Permanent Resident'>Permanent Resident</option>
,<option value='Work Permit'>Work Permit</option>
,<option value='Other'>Other</option>
]);
const [webuser, setWebuser] = useState({
EMAIL:''
,PHONE:''
,FULLNAME:''
,LEGAL_STATUS:''
,WEB_USER_FOLDER:''
,MPE_REF:''
,STAMP:''
});


const location = useLocation();


useEffect(() => {
getwebuser();
//document.getElementsByName('FULLNAME')[0].focus();
 },[]);

useEffect(() => {

//document.getElementsByName('EMAIL')[0].focus();

 },[webuser]);


function showsnackbar(innerHTML) {
  var x = document.getElementById("snackbar");
  x.innerHTML=innerHTML;
  x.className = "show";
  setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2500);
}//end func

function updatewebuser(e){
	

	if (webuser.FULLNAME=='' || webuser.PHONE=='' || webuser.LEGAL_STATUS=='' || selectedFiles.length==0){
		showsnackbar('please provide name, phone, and legal status and upload a valid TCI Id');
		return;
		}

document.getElementById('btnupdate').style.visibility='hidden';
	
   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {webuser:webuser, USERNAME:sessionStorage.getItem("USERNAME")} )
  };	
	
  fetch(resturl+'rarwpform/updatewebuser',requestOptions)
      .then(res => res.json())
      .then(res => 	{aftergetwebuser(res); uploadfiles(res[0].WEB_USER_FOLDER) } )
      .catch(err => console.log(err));

	//console.log('aftergetwpapp: '+res[0].OCCUPATION);
}//end func


function getwebuser(res){
	
   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {USERNAME:sessionStorage.getItem("USERNAME")} )
  };	
	
  fetch(resturl+'rarwpform/getwebuser',requestOptions)
      .then(res => res.json())
      .then(res => 	aftergetwebuser(res) )
      .catch(err => console.log(err));

	//console.log('aftergetwpapp: '+res[0].OCCUPATION);
}//end func



function handleChange(e){
//const val=e.target.value;
console.log('e.target.value: '+e.target.value)
const {name, value}=e.target;

setWebuser((prev) => {
return {...prev, [name]:value}
});	
}//end func

async function uploadfiles(WEB_USER_FOLDER){
	console.log('uploadfiles: '+selectedFiles.length+', WEB_USER_FOLDER: '+WEB_USER_FOLDER);
	if (selectedFiles.length==0)
		return;
 const FormData = require('form-data'); // npm install --save form-data

const form = new FormData();

form.append('USERNAME', sessionStorage.getItem("USERNAME"));
form.append('REF_NUM', WEB_USER_FOLDER);

//let formfiles={foto:false,sign:false,scans:false};

const currfile=selectedFiles[0].file;
let scansFile=new File([currfile], 'reginfo.pdf');;
const filenames={scans:currfile.name};

form.append('REG_INFO', filenames.scans);

if (scansFile!=null)	{form.append('filesupload', scansFile);}

const request_config = {
    onUploadProgress: data => {
      //Set the progress value to show the progress bard
      setProgress({perct:Math.round((100 * data.loaded) / data.total)});
    },
};
await axios.post(resturl+'rarwpform/uploadreginfofile', form, request_config)
.then(res => afteruploadfiles(res.data));

	
}//end func

function afteruploadfiles(data){
alert('Thank you!  please visit Customer Service Center to verify the regisration');
}//end func

function aftergetwebuser(res){

if (res.length==undefined)
	setWebuser((prev)=>{
	return {...prev, EMAIL:sessionStorage.getItem("USERNAME")};
	}); 
else{
	setWebuser(res[0]);
	console.log('LEGAL_STATUS'+webuser.LEGAL_STATUS);
	document.getElementsByName('LEGAL_STATUS')[0].value=res[0].LEGAL_STATUS;
	}

}//end func

function selectedFilesChange(event){
let temp=selectedFiles;
temp[0]={file:event.target.files[0]};
setSelectedFiles(temp);
}//end func

//upload valid TCI Id:in pdf format
//Work Permits Portal
  return (
    <>
	<p style={{marginTop:"60px"}} align='center' >
	The user registration form below must be submitted before you can submit Work Permit applications on this portal.
	<br/>You are required to upload a valid passport or identificaiton card of yours in pdf format
	<br/>After you submit the registration please visit Customer Service Center in person for verification
	</p>
		
	<table align='center'>
	<tbody>
	<tr>
	<td align='right' >Email: </td>
	<td align='left' ><input name='EMAIL' defaultValue={webuser.EMAIL}   readOnly className="gnsbro" onChange={(e)=>{handleChange(e);}}  /></td>
	</tr>
	<tr>
	<td align='right' >Full Name: </td>
	<td align='left' ><input name='FULLNAME' defaultValue={webuser.FULLNAME}  type="text" onChange={(e)=>{handleChange(e);}}  /></td>
	</tr>
	<tr>
	<td align='right' >Phone: </td>
	<td align='left' ><input name='PHONE' defaultValue={webuser.PHONE}  type="text" onChange={(e)=>{handleChange(e);}}  /></td>
	</tr>
	<tr>
	<td align='right' >Legal Status in TCI: </td>
	<td align='left' ><select name='LEGAL_STATUS' defaultValue={webuser.LEGAL_STATUS} title={webuser.LEGAL_STATUS} onChange={(e)=>{handleChange(e);}} >{legalstatus}</select></td>
	</tr>
	<tr>
	<td align='right' >Passport or Id card</td>
	<td align='left' ><input  type="file" onChange={(e)=>{selectedFilesChange(e);}}  /></td>
	</tr>
	<tr>
	<td align='right' >Recent Work Permit Card Ref. No.</td>
	<td align='left' ><input name='MPE_REF' defaultValue={webuser.MPE_REF}  type="text" onChange={(e)=>{handleChange(e);}} placeholder='skip this if you do not know'  /></td>
	</tr>
	<tr>
	<td align='right' >&nbsp;</td>
	</tr>
		{webuser.STAMP=='' &&
		<tr>
		<td align='right' >&nbsp;</td>
		<td align='left' ><button className="button" id='btnupdate' onClick={(e)=>{updatewebuser(e);}} >submit registration form</button></td>
		</tr>		
		}
	</tbody>
	</table>
	
		{/*buttonstate.clicked && <div><img src='img/Loading_2.gif' border='0' height='36' /> . . . please wait</div>}
		{
		!buttonstate.clicked && 
		<input type="button" id='btnsubmitapp' className="button" value="application summary review and submit" onClick={(e)=>{ e.preventDefault(); navWpsummary.current?.click(); }}  />
		
		*/}


<div id="snackbar">&nbsp;</div>
    </>
  );
  


};


export default RenderWpForm;