import React, { useState, useEffect } from "react";
//import { confirm } from "react-confirm-box";
import axios from 'axios';
import {Link, NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import $ from 'jquery';
//import {AlertProvider} from 'react-native-async-alert';
//import {useShowAlert} from 'react-native-async-alert';
import { useNavigate } from 'react-router-dom'

//var dateFormat = require('dateformat');



const RenderWpForm = () => {


const resturl=sessionStorage.getItem("RESTURL");
const navWpapps = React.useRef();

const navigate = useNavigate();

window.addEventListener("keypress", function (event) {
 // detect event keys
 if (event.keyCode == 13) {
   //callsadfinder();
   
 }
});


const [tblwpfverify, setTblwpfverify] = useState([]);
const [renders, setRenders] = useState({count:0});
const [wptapp, setWptapp] = useState({WPT_APP_ID:0});
const [dowait, setDowait] = useState({show: false});

const [TOTAL_EMPTY_FIELDS, setTOTAL_EMPTY_FIELDS] = useState({NUM:500});




const location = useLocation();


useEffect(() => {

if (sessionStorage.getItem("USERNAME")==null || sessionStorage.getItem("USERNAME")=="null" || sessionStorage.getItem("USERNAME")==undefined || sessionStorage.getItem("USERNAME")==""){
	navigate("/");
	return;
	}//end if

document.title =   "APS Summary";	

if (location.state.WPT_APP_ID!=null && location.state.WPT_APP_ID!=undefined && location.state.WPT_APP_ID!='null' && location.state.WPT_APP_ID!='undefined'){
	setWptapp({WPT_APP_ID:location.state.WPT_APP_ID});
	}//end if
else
	console.log("location.state.WPT_APP_ID not provided");
	//qrylabor();
 },[]);

useEffect(() => {
//console.log("useEffect"+wptapp.WPT_APP_ID);
if (wptapp.WPT_APP_ID>0){
	console.log('wptapp.APP_TYPE_ID'+wptapp.APP_TYPE_ID)
	if (wptapp.REF_NUM==undefined)
		getwpapp();
	else if (tblwpfverify.length<1)
		gettblwpfverify();
	}//end if
 },[wptapp]);





function getwpapp(e){

   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {WPT_APP_ID:wptapp.WPT_APP_ID} )
  };		

  fetch(resturl+'rarwpform/getwpapp',requestOptions)
      .then(res => res.json())
      .then(res => 	aftergetwpapp(res) )
      .catch(err => console.log(err));

}//end func

function aftergetwpapp(res){
	setWptapp(res[0]);
	console.log('aftergetwpapp: '+res[0].OCCUPATION);
}//end func

function gettblwpfverify(){

   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {WPT_APP_ID:wptapp.WPT_APP_ID} )
  };		

  fetch(resturl+'rarwpform/gettblwpfverify',requestOptions)
      .then(res => res.json())
      .then(res => 	aftergettblwpfverify(res) )
      .catch(err => console.log(err));

}//end func

function aftergettblwpfverify(res){
if (res.length==undefined)
	return;
	
console.log('aftergettblwpfverify, res.length: '+res.length);

let htmrows=[];
res.map((col, index) => {
	if (index==1)
		setTOTAL_EMPTY_FIELDS({NUM:col['TOTAL_EMPTY_FIELDS']});
	
	const SCTN=col['SCTN'];
	const SHORT_NAME=col['SHORT_NAME'];
	const NUM_FLD_RQD=col['NUM_FLD_RQD'];
	const NUM_DATA=col['NUM_DATA'];
	
	const MPT_FLD=col['MPT_FLD'];
	const trkey='tr_'+index;
	const tdkey='td_'+index;
	const rdkey='td_'+index;
	const ok=(NUM_FLD_RQD>NUM_DATA)?<img src='img/alert.png' height='18' border='0' />:<img src='img/greentick.png' height='18' border='0' />
	
	const htmrow=<tr key={trkey} >
	<td align='left' key={tdkey}  >{1+index}:&nbsp;{SHORT_NAME}</td>
	<td align='center' key={'c3'+tdkey}  >{NUM_FLD_RQD}</td>
	<td align='center' key={'c4'+tdkey}  >{NUM_DATA}</td>
	<td align='center' key={'c5'+tdkey}  >{ok}</td>
	<td align='left' key={'c6'+tdkey}  >{MPT_FLD}</td>
	</tr>;
	htmrows.push(htmrow);
});

setTblwpfverify(htmrows);
}//end func

function preprunireport(e){
	
	if (TOTAL_EMPTY_FIELDS.NUM>0){
		showsnackbar(TOTAL_EMPTY_FIELDS.NUM+' mandatory fields remain to be populated');
		return;
		}//end if
		setDowait({show:true});
	
//	e.preventDefault();

   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {PNAM:"WPT_APP_ID", PVAL:wptapp.WPT_APP_ID} )
  };		

  fetch(resturl+'rarwpform/preprunireport',requestOptions)
      .then(res => res.json())
      .then(res => 	afterpreprunireport(res) )
      .catch(err => console.log(err));

//return false;

}//end func

function afterpreprunireport(res){
	
	setDowait({show:false});
	toggleModalsm();//alert('applcation has been submitted')
	
	
//	console.log('res.myhref: '+res.myhref);
//window.open(res.myhref);

}//end func

function toggleModalsm() {
const modalsm = document.querySelector(".modalsm");
const trigger = document.querySelector(".trigger");
const closeButton = document.querySelector(".close-button");
	
console.log('modalsm is null: '+(modalsm==null));
modalsm.classList.toggle("show-modalsm");

if (document.querySelector(".trigger"))
	document.querySelector(".trigger").addEventListener("click", toggleModalsm);

if (document.querySelector(".close-button"))
	document.querySelector(".close-button").addEventListener("click", toggleModalsm);

window.addEventListener("click", windowOnClick);
}//end func

function windowOnClick(event) {
const modalsm = document.querySelector(".modalsm");
const trigger = document.querySelector(".trigger");
const closeButton = document.querySelector(".close-button");	
	
    if (event.target === modalsm) {
    	toggleModalsm();
    }
}//end func


async function openScans (e) {
e.preventDefault();
//const fileName=;
const coverfilepath=wptapp.REF_NUM+"/scans.pdf";

    const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {filepath:coverfilepath} )
  };


  fetch(resturl+'rarwpform/getwptappfile',requestOptions)
      .then(res => afteropenScans(res, wptapp.REF_NUM+"_scans.pdf"))
      .catch(err => console.log(err));


}//end func

async function afteropenScans(res, fileName){
console.log('afteropenScans');
const blob = await res.blob()
const data = window.URL.createObjectURL(new Blob([blob]))

const link = document.createElement("a");
link.href = data;
link.setAttribute("download", fileName);
document.body.appendChild(link);
link.click();
//link.parentNode!.removeChild(link);	
link.parentNode?.removeChild(link);	
}//end func

function showsnackbar(innerHTML) {
  var x = document.getElementById("snackbar");
  x.innerHTML=innerHTML;
  x.className = "show";
  setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2500);
}//end func


const refnum=wptapp.REF_NUM;
const refurl=sessionStorage.getItem("UPLOADURL")+refnum+"/";
console.log('refurl: '+refurl);


	
  return (
    <>
	<p align='center' style={{marginBottom:"0px"}}>
	Work Permit application {refnum} summary review and submit to Customer Service Center
	</p>
	{/* <span style={{width:"200px",backgroundColor:"green"}}>appe</span> */}
	<table align='center' >
	<tbody>
	<tr  key="tr_STEP1" >
	<td align='left' colSpan="3" >
	<ul>
		<li>step 1: <a href="#" onClick={(e)=>{openScans(e);}} target="_none" className="nodecor" >click here</a> to see scanned documents</li>
		<li>step 2: verify whether or not the information below is correct</li>
		<li>step 3: if the information is correct then click the submit button</li>
	</ul>
	</td>
	</tr>
	<tr  key="tr_MPE_CTRY" >
	<td align='right' >Country of Birth: </td>
	<td align='left' >{wptapp.MPE_BIRTH_COUNTRY}</td>
	</tr>
	<tr  key="tr_MPE_DOB" >
	<td align='right' >Date of Birth: </td>
	<td align='left' >{wptapp.CHR_MPE_DOB}</td>
	</tr>
	<tr  key="tr_MPE_PASSPORT_CTY" >
	<td align='right' >Passport Country of Issue: </td>
	<td align='left' >{wptapp.MPE_PASSPORT_CTY}</td>
	</tr>
	
	<tr  key="tr_MPE_PPT" >
	<td align='right' >Passport Number: </td>
	<td align='left' >{wptapp.MPE_PASSPORT_NBR}</td>
	</tr>

	<tr  key="tr_AGENCY" >
	<td align='right' >Agency: </td>
	<td align='left' >{wptapp.AGENCY}</td>
	<td align='center' rowSpan="6" >
		{wptapp.REF_NUM!=undefined &&
		<img src={resturl+"rarwpform/getwptappfoto?path="+wptapp.REF_NUM+"/foto.png"} border='0' height="150" />
		}	
	</td>
	</tr>
	<tr  key="tr_MPR_NAME" >
	<td align='right' >Employer: </td>
	<td align='left' >{wptapp.MPR_NAME}</td>
	</tr>
	<tr key="tr_MPE_FIRST_NAME" >
	<td align='right' >Employee: </td>
	<td align='left' >{wptapp.MPE_FIRST_NAME+" "+wptapp.MPE_LAST_NAME}</td>
	</tr>
	<tr key="tr_OCCUPATION" >
	<td align='right' >Job Title: </td>
	<td align='left' >{wptapp.MPE_OCCUPATION}</td>
	</tr>
	<tr key="tr_WAGES" >
	<td align='right' >Salary: </td>
	<td align='left' >{"$ "+wptapp.MPE_SALARY_AMT+" "+wptapp.MPE_SALARY_PRD}</td>
	</tr>
	<tr key="tr_WRK_CONTRACT" >
	<td align='right' >Contract Dates: </td>
	<td align='left' >{wptapp.CHR_WRK_CONTRACT_START+" to "+wptapp.CHR_WRK_CONTRACT_END}</td>
	</tr>
	<tr key="tr_MED_CERT" >
	<td align='right' >Migrant Health Certificate Dates: </td>
	<td align='left' >{wptapp.CHR_MED_CERT_ISSUE_DATE+" to "+wptapp.CHR_MED_CERT_EXPIRE_DATE}</td>
	</tr>
	{/*
	<tr key="key_tr_LOCATION_ID" >
	<td align='right' >Location:</td>
	<td align='left' ><select key="input_LOCATION_ID" name='LOCATION_ID' defaultValue={wptapp.LOCATION_ID}   ><option defaultValue='' ></option>{locations}</select></td>
	</tr>
	
	*/}	
	</tbody>
	</table>
	
	
	
	

<p align='center' >  
        <NavLink
          to={"/wpform"}
          state={{
		  WPT_APP_ID:wptapp.WPT_APP_ID
		  }} 
		
        >return to application form</NavLink>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
		
		{dowait.show && <span><img src='img/Loading_2.gif' border='0' height='36' /> . . . please wait</span>}
		{
		!dowait.show && 
		<input type="button" id='btnsubmitapp' className="button" value="submit application to Customer Service Center" onClick={(e)=>{ preprunireport(e); }}  />
		}
</p>  

	<div id="snackbar">&nbsp;</div>

	<div className="modalsm">
		<div className="modalsm-content">
			<span className="close-button">&times;</span>
			<div align='center' id='modalsmtext' >
			<img src='img/greentick.png' border='0' height='48' /> your application is submitted!
			<p>you should receive email after it is processed</p>			
			<p><button className="button" style={{width:"120px"}} onClick={(e)=>{navigate('/wpapps',{state:{WPT_APP_ID:wptapp.WPT_APP_ID}});}} >Ok</button></p>
			
				{/*
			<NavLink ref={navWpapps} to={"/wpapps"} 
			  state={{WPT_APP_ID:wptapp.WPT_APP_ID}}  >Ok</NavLink>
				
				*/}
			</div>
		</div>
	</div>

    </>
  );
  


};


export default RenderWpForm;