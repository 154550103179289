import React, { useState, useEffect } from "react";
//import { confirm } from "react-confirm-box";
import axios from 'axios';
import {Link, NavLink, useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import $ from 'jquery';


//var dateFormat = require('dateformat');



let exportingwptapp=false;

const RenderWpForm = () => {

const resturl=sessionStorage.getItem("RESTURL");


//var boolFetchingSads=false;
const navWpform = React.useRef();
const navigate = useNavigate();

window.addEventListener("keypress", function (event) {
 // detect event keys
 if (event.keyCode == 13) {
   //callsadfinder();
   
 }
});



const [locations, setLocations] = useState([]);
const [laborcltype, setLaborcltype] = useState([]);
const [userroles, setUserroles] = useState([]);

const [tblwpfexport, setTblwpfexport] = useState([]);
const [wptapp, setWptapp] = useState({WPT_APP_ID:0, REF_NUM:''});
const [person, setPerson] = useState({PERSON_ID:0});
const [employer, setEmployer] = useState({EMPL_ID:0});
const [renders, setRenders] = useState({count:0});




const location = useLocation();


useEffect(() => {

if (location.state.WPT_APP_ID!=null && location.state.WPT_APP_ID!=undefined && location.state.WPT_APP_ID!='null' && location.state.WPT_APP_ID!='undefined'){
	setWptapp({
	WPT_APP_ID:location.state.WPT_APP_ID		
	, REF_NUM:location.state.REF_NUM		
	});
	getuserroles();
	}//end if
	
 },[]);

useEffect(() => {

console.log('wptapp.APP_TYPE_ID: '+wptapp.APP_TYPE_ID);
console.log('wptapp.LOCATION_ID: '+wptapp.LOCATION_ID);
 },[renders]);

useEffect(() => {
/*
const newcount=1+renders.count;
setRenders((prev) => {
return {...prev, count:newcount}
});

*/
console.log('wptapp.APP_TYPE_ID: '+wptapp.APP_TYPE_ID);
console.log('wptapp.LOCATION_ID: '+wptapp.LOCATION_ID);


if (exportingwptapp==true && !wptapp.LBR_CLR_REF)//continue call exportwpapp after updating empl_id or person_id
	exportwpapp(null);
else if (wptapp.WPT_APP_ID>0 && tblwpfexport.length<1){
	getwpformoptions();
	}//end if

/*
else if (wptapp.LBR_CLR_REF!=null)
	update_lbr_clr_ref();

*/

if (locations.length>0 && laborcltype.length>0)
	wptappToTblwpfexport();

 },[wptapp]);



 
useEffect(() => {
//console.log('locations: '+JSON.stringify(locations));
if (locations.length>0 && laborcltype.length>0){
	getwptappforexport();
	}//end if
	
 },[JSON.stringify(locations, laborcltype)]);

useEffect(() => {
//console.log('Person: '+JSON.stringify(person));
 },[JSON.stringify(person)]);


function handleChange(e){
const {name, value}=e.target;

setWptapp((prev) => {
return {...prev, [name]:value}
});	

}//end func

async function getwpformoptions(){
	
   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {USERNAME:sessionStorage.getItem("USERNAME")} )
  };		
	try {
		// let [items, contactlist, itemgroup] = await Promise.all([
		//, fetch(resturl+'rarwpform/gettblwpapp', requestOptions).then(res => res.json()).then(res => 	aftergettblwpapps(res) ).catch(err => console.log(err))
	   await Promise.all([
	   
		 fetch(resturl+'rarwpform/getwpformoptions', requestOptions).then(res => res.json()).then(res => 	aftergetwpformoptions(res) ).catch(err => console.log(err))
	  ]);


	}
	catch(err) {
	  console.log(err);
	};
		
	
}//end func


function aftergetwpformoptions(res){
console.log('aftergetwpformoptions: ');
//	console.log('aftergetwpformoptions: '+JSON.stringify(res));
	
let templocations=[];
let templaborcltype=[];

	
res.map((col, index) => {
	const combo=col['COMBO'];
	const key=combo+"_"+index;
	const key2=combo+"_"+index+"_2";
	const val=col['VAL'];
	const opt=col['OPT'];
	//console.log('afterfetchctrycodes nam: '+nam);
	if (combo=='location')
		templocations.push(<option key={key} value={val}  >{opt}</option>);
	else if (combo=='laborcltype')
		templaborcltype.push(<option key={key} value={val}  >{opt}</option>);
	
	});//end map
console.log('aftergetwpformoptions templocations.len : '+templocations.length);
setLocations(templocations);
setLaborcltype(templaborcltype);
/*
setLocations((prev) => {
return {...prev, [name]:value}
});

*/


}//end func



function getuserroles(){
   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {USERNAME:sessionStorage.getItem("USERNAME")} )
  };		

  fetch('https://customsprem.gov.tc/rarwpformexport/getuserroles',requestOptions)
      .then(res => res.json())
      .then(res => 	aftergetuserroles(res) )
      .catch(err => console.log(err));	
}//end func

function aftergetuserroles(res){
	let tempuserroles=[];
res.map((col, index) => {
	const combo=col['COMBO'];
	const key=combo+"_"+index;
	const key2=combo+"_"+index+"_2";
	const opt=col['ROLE_NAME'];
	const val=col['ROLE_NAME'];
	//console.log('afterfetchctrycodes nam: '+nam);
	const selected=(opt=='Labour Clearance Data Entry')?'selected':'';
	tempuserroles.push(<option key={key} value={val} selected={selected} >{opt}</option>);
	
	});//end map
console.log('aftergetuserroles tempuserroles.len : '+tempuserroles.length);
setUserroles(tempuserroles);	
}//end func

function exportmprmpe(e, mprmpe){
//	e.preventDefault();
const param=(mprmpe=='mpr')?employer:person;
const urlsufx=(mprmpe=='mpr')?'exportemployer':'exportperson';

console.log(urlsufx+': ' +JSON.stringify( param ));

   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( param )
  };		

  fetch('https://customsprem.gov.tc/rarwpformexport/'+urlsufx,requestOptions)
      .then(res => res.json())
      .then(res => 	afterexportmprmpe(res, mprmpe) )
      .catch(err => console.log(err));

//return false;

}//end func

function afterexportmprmpe(res, mprmpe){
if (res.length==undefined){
	if (res.error!=undefined)
		showsnackbar(res.error);
	console.log('afterexportmprmpe will not continue to updateportalmprmpeid due to error');
	exportingwptapp=false;
	return;
	}//end if
const mprmpeid=res[0][''];// the stored procedure returns a value with no "(No column name)"
console.log('id after export '+mprmpe+' : '+mprmpeid);
/*
use the mprmpeid returned from Permit Management system, call updateportalmprmpeid
to update mpr or mpe using PORTAL_EMPL_ID or PORTAL_PERSON_ID
*/	
const requestOptions = {
  method: 'POST'
  ,headers: { 'Content-Type': 'application/json' }
  ,body: JSON.stringify( {
	 portalmprmpeid:(mprmpe=='mpr')?wptapp.PORTAL_EMPL_ID:wptapp.PORTAL_PERSON_ID
	,mprmpe:mprmpe
	,mprmpeid:mprmpeid 
	,WPT_APP_ID:wptapp.WPT_APP_ID
	,IMG_REF:res[0].IMG_REF
  } )
};		

setPerson((prev)=>{
return {...prev, IMG_REF:res[0].IMG_REF}
});


fetch(resturl+'rarwpform/updateportalmprmpeid',requestOptions)
  .then(res => res.json())
  .then(res => 	afterupdateportalmprmpeid(res, mprmpe, mprmpeid) )
  .catch(err => console.log(err));	
	
}//end func

function afterupdateportalmprmpeid(res, mprmpe, mprmpeid){
//exportingwptapp is set to true to flag useEffect[wptapp] to call exportwpapp again
exportingwptapp=true;
if (mprmpe=='mpr')
	setWptapp((prev) => {
	return {...prev, EMPL_ID:mprmpeid}
	});	
else if (mprmpe=='mpe')
	setWptapp((prev) => {
	return {...prev, PERSON_ID:mprmpeid}
	});	
//showsnackbar('afterupdateportalmprmpe');
}//end func

function exportwpapp(e){
//	e.preventDefault();
const APP_TYPE_ID=document.getElementsByName('APP_TYPE_ID')[0].value;
const LOCATION_ID=document.getElementsByName('LOCATION_ID')[0].value;
const ROLE_NAME=document.getElementsByName('ROLE_NAME')[0].value;
 
if (APP_TYPE_ID=='' || LOCATION_ID==''){
	showsnackbar('please select application type and location');
	return;
	}//end if

if (ROLE_NAME!='Labour Clearance Data Entry'){
	showsnackbar('the user role Labour Clearance Data Entry is required!');
	return;
	}//end if



if (wptapp.LBR_CLR_REF){
	//showsnackbar('Already exported to Permit Management Sytem with Immigration Ref. '+wptapp.LBR_CLR_REF);
	return;
	}//end if
console.log('exportwpapp wptapp.EMPL_ID: '+wptapp.EMPL_ID);
console.log('exportwpapp wptapp.PERSON_ID: '+wptapp.PERSON_ID);

exportingwptapp=false;

if (1*wptapp.EMPL_ID==0){
	exportmprmpe(e, 'mpr');
	return;
	}
	
if (1*wptapp.PERSON_ID==0){
	exportmprmpe(e, 'mpe');
	return;
	}
	

   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( wptapp )
  };		
  fetch('https://customsprem.gov.tc/rarwpformexport/exportwpapp',requestOptions)
      .then(res => res.json())
      .then(res => 	afterexportwpapp(res) )
      .catch(err => console.log(err));

//return false;

}//end func

function afterexportwpapp(res){

if (res.length==undefined){
	if (res.error==undefined)
		showsnackbar('something went wrong');
	else
		showsnackbar(res.error);
	
	return;
	}//end if

const LBR_CLR_ID=res[0][''];//the stored procedure returns a value with no "(No column name)"

const requestOptions = {
  method: 'POST'
  ,headers: { 'Content-Type': 'application/json' }
  ,body: JSON.stringify( {WPT_APP_ID:wptapp.WPT_APP_ID, LBR_CLR_ID:LBR_CLR_ID, LBR_CLR_REF:person.IMG_REF} )
};		

fetch(resturl+'rarwpform/update_lbr_clr_id',requestOptions)
  .then(res => res.json())
  .then(res => 	after_update_lbr_clr_id() )
  .catch(err => console.log(err));

/*

setWptapp((prev) => {
return {...prev
,LBR_CLR_ID:res[0]['']
}
});
*/

}//end func


function after_update_lbr_clr_id(){
	
setWptapp((prev)=>{
return {...prev, LBR_CLR_REF:person.IMG_REF}
});
	
}//end func
function getmpeforexport(){

   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {WPT_APP_ID:wptapp.WPT_APP_ID, USERNAME:sessionStorage.getItem("USERNAME")} )
  };		

  fetch(resturl+'rarwpform/getmpeforexport',requestOptions)
      .then(res => res.json())
      .then(res => 	aftergetmpeforexport(res) )
      .catch(err => console.log(err));

}//end func


function getmprforexport(){

   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {WPT_APP_ID:wptapp.WPT_APP_ID, USERNAME:sessionStorage.getItem("USERNAME")} )
  };		

  fetch(resturl+'rarwpform/getmprforexport',requestOptions)
      .then(res => res.json())
      .then(res => 	aftergetmprforexport(res) )
      .catch(err => console.log(err));

}//end func

function aftergetmprforexport(res){
//res[0]['CREATED_BY']=sessionStorage.getItem("USERNAME");
if (res.length==undefined)
	showsnackbar(res.error);
else{
	console.log('mpr.COMPANY:'+res[0].COMPANY);
	setEmployer(res[0]);
	}
	
}//end func

function aftergetmpeforexport(res){
//res[0]['CREATED_BY']=sessionStorage.getItem("USERNAME");
if (res.length==undefined)
	showsnackbar(res.error);
else{
	console.log('mpe.FIRST_NAME:'+res[0].FIRST_NAME);
	setPerson(res[0]);
	}
}//end func

function getwptappforexport(){

   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {WPT_APP_ID:wptapp.WPT_APP_ID, USERNAME:sessionStorage.getItem("USERNAME")} )
  };		

  fetch(resturl+'rarwpform/getwptappforexport',requestOptions)
      .then(res => res.json())
      .then(res => 	aftergetwptappforexport(res) )
      .catch(err => console.log(err));

}//end func

function showsnackbar(innerHTML) {
  var x = document.getElementById("snackbar");
  x.innerHTML=innerHTML;
  x.className = "show";
  setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2500);
}//end func

function aftergetwptappforexport(res){

if (res.length==undefined){
	if (res.error!=undefined)
		showsnackbar(res.error);
	return;
	}//end if
	
if (res[0].EMPL_ID==0)
	getmprforexport();
if (res[0].PERSON_ID==0)
	getmpeforexport();

//res[0]['CREATED_BY']=sessionStorage.getItem("USERNAME");
setWptapp(res[0]);
/*
setWptapp((prev) => {
return {...prev, FULL_NAME:res[0].FULL_NAME}
});

*/
	
//console.log('aftergetwptappforexport, res.length: '+res.length);
//const rs=res[0];


}//end func

function wptappToTblwpfexport(){
	console.log('wptappToTblwpfexport');
const arrRow=[
{lbl:"Occupation Id & Name", col:"OCCUPATION"}
,{lbl:"Contract Start Date", col:"CON_STRART_DATE"}
,{lbl:"Contract End Date", col:"CON_END_DATE"}
,{lbl:"Medical Start Date", col:"MED_CERT_ISSUE_DATE"}
,{lbl:"Medical End Date", col:"MED_CERT_EXPIRE_DATE"}
,{lbl:"Wages ($)", col:"SALARY_WAGES"}
,{lbl:"Wages Denomination", col:"WAGE_DENOM"}

];
let htm=[];
	htm.push(<>
	<tr key="key_ROLE_NAME" >
	<td align='right' >Permit Management Roles:</td>
	<td align='left' colSpan="2" >
	<select key="input_ROLE_NAME" name='ROLE_NAME'  className="gnsbro" ><option value='' ></option>{userroles}</select>
	</td>
	</tr>
	<tr key="key_WPT_APP_ID" >
	<td align='right' >Application Id & Ref No.:</td>
	<td align='left' colSpan="2" >
	<input key="input_WPT_APP_ID" defaultValue={wptapp.WPT_APP_ID} readOnly className="gnsbro" size="5" />&nbsp;
	<input key="input_REF_NUM" defaultValue={wptapp.REF_NUM} readOnly className="gnsbro" size="25" />
	</td>
	</tr>
	<tr key="key_EMPL_ID" >
	<td align='right' >Employer Id & Name:</td>
	<td align='left' colSpan="2" >
	<input key="input_EMPL_ID" defaultValue={wptapp.EMPL_ID} readOnly className="gnsbro" size="5" />&nbsp;
	<input key="input_COMPANY" defaultValue={wptapp.COMPANY} readOnly className="gnsbro" size="25" />
	</td>
	</tr>
	<tr key="key_PERSON_ID" >
	<td align='right' >Employee Id & Name:</td>
	<td align='left' colSpan="2" >
	<input key="input_PERSON_ID" defaultValue={wptapp.PERSON_ID} readOnly className="gnsbro" size="5" />&nbsp;
	<input key="input_FULL_NAME" defaultValue={wptapp.FULL_NAME} readOnly className="gnsbro" size="25" />
	</td>
	</tr>
	</>);
	


for (var i=0; i<arrRow.length; i++){
	const currRow=arrRow[i];
	const col=currRow.col;
	const index=i;
	const size=(i<4)?"36":"15";
	const colSpan=(i<4)?"2":"1";
	const trKey="key_"+index;
	const inputKey="input_"+index;
	const photo=(i==4)?<><td rowSpan="5" align="left" ><img src={"upload/"+wptapp.REF_NUM+"/foto.png"} height="150" border="0" /></td></>:<></>;
	{/*
	const sign=(i==9)?<><td rowSpan="5" align="left" ><img src={"upload/"+wptapp.REF_NUM+"/sign.png"} height="72" border="0" /></td></>:<></>;
	*/}	
	
	
	htm.push(<>
	<tr key={trKey} >
	<td align='right' >{currRow.lbl}:</td>
	<td align='left' colSpan={colSpan} ><input key={inputKey} defaultValue={wptapp[col]} readOnly className="gnsbro" size={size} /></td>
	{photo}
	</tr>
	</>	);
	}//end for

	htm.push(<>
		<tr key="key_tr_APP_TYPE_ID" >
	<td align='right' >Application Type:</td>
	<td align='left' ><select key="input_APP_TYPE_ID" name='APP_TYPE_ID' defaultValue={wptapp.APP_TYPE_ID}  onChange={(e)=>{handleChange(e);}} ><option defaultValue='' ></option>{laborcltype}</select></td>
	</tr>
	<tr key="key_tr_LOCATION_ID" >
	<td align='right' >Location:</td>
	<td align='left' ><select key="input_LOCATION_ID" name='LOCATION_ID' defaultValue={wptapp.LOCATION_ID}  onChange={(e)=>{handleChange(e);}} ><option defaultValue='' ></option>{locations}</select></td>
	</tr>
	{wptapp.LBR_CLR_REF && 
	<tr key="key_tr_LBR_CLR_REF" >
	<td align='right' >Person & Immigration Ref. #:</td>
	<td align='left' colSpan="2" >
		<input key="input_IMG_REF" defaultValue={person.IMG_REF} readOnly className="gnsbro"  size="5"  />&nbsp;
		<input key="input_LBR_CLR_REF" defaultValue={wptapp.LBR_CLR_REF} readOnly className="gnsbro"  size="5"  />&nbsp;
		<img src='img/greentick.png' border='0' />
	</td>
	</tr>}
	</>	);

	
setTblwpfexport(htm);	
	
}//end func
	
  return (
    <>
	<p align='center' >
	Please verify the fields below before exporting to IPM (Immigration Permit Management)
	<br/>After export to IPM please attach the person's foto and signature images
	</p>
		<table align='center' key="tbl1" >
		<tbody>
		{tblwpfexport}
		</tbody>
		</table>


<p align='center' >  
        <NavLink
			ref={navWpform}
          to={"/wpform"}
          state={{
		  WPT_APP_ID:wptapp.WPT_APP_ID
		  }} 
		
        >return to application form</NavLink>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
		{exportingwptapp && <div><img src='img/Loading_2.gif' border='0' height='36' /> . . . please wait</div>}
		{
		!exportingwptapp && !wptapp.LBR_CLR_REF &&
		<input type="button" id='btnexportapp' className="button" value="export application to Immigration Permit Management" onClick={(e)=>{ e.preventDefault(); exportwpapp(e); }}  />
		}
		
		{/* exportwpapp */}
</p>  
<div id="snackbar">&nbsp;</div>

    </>
  );
  


};


export default RenderWpForm;