import React, { Component, useState, useEffect  } from "react";
import { useNavigate, useLocation } from 'react-router-dom'



{/*import "../css/login.css";*/}



function Appsmenu(props) {

useEffect(() => {
	
if (sessionStorage.getItem("USERNAME")==null || sessionStorage.getItem("USERNAME")=="null" || sessionStorage.getItem("USERNAME")==undefined || sessionStorage.getItem("USERNAME")==""){
	navigate("/");
	return;
	}//end if	
	
document.title =   "APS Dashboard";	
if(sessionStorage.getItem("USERNAME")==undefined || sessionStorage.getItem("USERNAME")==null || sessionStorage.getItem("USERNAME")=="")	
		navigate("/login");
	
},[]);



const navigate = useNavigate();	

const location = useLocation();
console.log('location.pathname: '+location.pathname);
console.log('window.location: '+window.location);


function openapp(e, apppath){
e.preventDefault(); 
//console.log('openapp resturl: '+sessionStorage.getItem("RESTURL") );
document.getElementsByTagName('body')[0].classList.remove("loginbody");	
navigate('/'+apppath);

/*
, {
      state: {
        resturl: sessionStorage.getItem("RESTURL"),
		}
      }
*/

}//end func





const username=(sessionStorage.getItem("USERNAME")==null)?'':sessionStorage.getItem("USERNAME");
const IRD_LOGIN_ID=(sessionStorage.getItem("IRD_LOGIN_ID")==null)?"":sessionStorage.getItem("IRD_LOGIN_ID");


    return (
		<>
		
	  		
       <div align="center"  className="numcols3-1" >
                <div className="rcorners">
                    <a href="#" onClick={(e)=>{openapp(e, 'employers')}}  >
                    <table border="0" width="100%"  >
					<tbody>
                    <tr valign="top">
                    <td align="left" rowSpan="2" width="72"  >
                    <img src="img/searchdb.png" height="105" border="0" title="" />
                    </td>
                    <td align="left" className="boxtitle" >New Work Permit Application</td>
                    </tr>
                    <tr>
                    <td align="left" className="boxtext" >New Work Permit Application</td>
                    </tr>
					</tbody>
                    </table>
                    </a>
                </div>
        </div>
        <div align="center"  className="numcols3-2" >
                <div className="rcorners">
                    <a href="#" onClick={(e)=>{openapp(e, 'wpapps')}}  >
                    <table border="0" width="100%"  >
					<tbody>
                    <tr valign="top">
                    <td align="left" rowSpan="2" width="72"  >
                    <img src="img/searchdb.png" height="105" border="0" title="" />
                    </td>
                    <td align="left" className="boxtitle" >Work Permit Applications</td>
                    </tr>
                    <tr>
                    <td align="left" className="boxtext" >Work Permit Applications</td>
                    </tr>
					</tbody>
                    </table>
                    </a>
                </div>
		</div>

       <div align="center"  className="numcols3-3" >
                <div className="rcorners">
                    <a href="csc" target="_none" onClick={(e)=>{}}  >
                    <table border="0" width="100%"  >
					<tbody>
                    <tr valign="top">
                    <td align="left" rowSpan="2" width="72"  >
                    <img src="img/searchdb.png" height="105" border="0" title="" />
                    </td>
                    <td align="left" className="boxtitle" >Ready for Collection</td>
                    </tr>
                    <tr>
                    <td align="left" className="boxtext" >Search Products Ready for Collection</td>
                    </tr>
					</tbody>
                    </table>
                    </a>
                </div>
        </div>

        <div align="center"  className="numcols3-1" >
                <div className="rcorners">
                    <a href="#" onClick={(e)=>{openapp(e, 'myprofile')}}  >
                    <table border="0" width="100%"  >
					<tbody>
                    <tr valign="top">
                    <td align="left" rowSpan="2" width="72"  >
                    <img src="img/searchdb.png" height="105" border="0" title="" />
                    </td>
                    <td align="left" className="boxtitle" >My Profile</td>
                    </tr>
                    <tr>
                    <td align="left" className="boxtext" >My Profile</td>
                    </tr>
					</tbody>
                    </table>
                    </a>
                </div>
		</div>
		
		
		{IRD_LOGIN_ID!="" && 
        <div align="center"  className="numcols3-2" >
                <div className="rcorners">
                    <a href="#" onClick={(e)=>{openapp(e, 'registrations')}}  >
                    <table border="0" width="100%"  >
					<tbody>
                    <tr valign="top">
                    <td align="left" rowSpan="2" width="72"  >
                    <img src="img/searchdb.png" height="105" border="0" title="" />
                    </td>
                    <td align="left" className="boxtitle" >Portal Users registrations</td>
                    </tr>
                    <tr>
                    <td align="left" className="boxtext" >Portal Users registrations</td>
                    </tr>
					</tbody>
                    </table>
                    </a>
                </div>

		</div>				
		}
		{username=='ajones' && <>
        <div align="center"  className="numcols3-3" >
                <div className="rcorners">
                    <a href="#" onClick={(e)=>{openapp(e, 'wpusers')}}  >
                    <table border="0" width="100%"  >
					<tbody>
                    <tr valign="top">
                    <td align="left" rowSpan="2" width="72"  >
                    <img src="img/searchdb.png" height="105" border="0" title="" />
                    </td>
                    <td align="left" className="boxtitle" >System Users</td>
                    </tr>
                    <tr>
                    <td align="left" className="boxtext" >System Users</td>
                    </tr>
					</tbody>
                    </table>
                    </a>
                </div>

		</div>		
		
    
       <div align="center"  className="numcols3-1" >
                <div className="rcorners">
                    <a href="#" onClick={(e)=>{openapp(e, 'wpuserlogins')}}  >
                    <table border="0" width="100%"  >
					<tbody>
                    <tr valign="top">
                    <td align="left" rowSpan="2" width="72"  >
                    <img src="img/searchdb.png" height="105" border="0" title="" />
                    </td>
                    <td align="left" className="boxtitle" >System Login Logs</td>
                    </tr>
                    <tr>
                    <td align="left" className="boxtext" >System Login Logs</td>
                    </tr>
					</tbody>
                    </table>
                    </a>
                </div>
        </div>


		
		</>}
	
	<div id='snackbar' ></div>
	</>
);

}//end class

{
/*
onClick="document.getElementById('id01').style.display='none'"
onClick="document.getElementById('id01').style.display='none'"
*/
}

export default Appsmenu; 

