import React, { Component, useState, useEffect  } from "react";
import { useNavigate, useLocation } from 'react-router-dom'
import queryString from 'query-string';




{/*import "../css/login.css";*/}

let customspremurl='https://customsprem.gov.tc/';
let resturl='https://customsprem.gov.tc/';
//let resturl='https://customsapps.gov.tc/';
//let resturl='https://passengerreports.gov.tc/';

const queryStringParams = queryString.parse(window.location.search);
if (queryStringParams.drv!=undefined){
	resturl='http://127.0.0.1:3001/';
	console.log('resturl: '+resturl);
	//console.log('queryStringParams: '+resturlJSON.stringify(queryStringParams));
	}//end if
	

//let emailConfirmed=false;

function Login (props) {
//ajones
//abc
const [dowait, setDowait] = useState({on:false});
const [cred, setCred] = useState({
username: ""
, pwd: ""
, emailcount:null
, otpsent:false
, isemail:false
, ip:""
});	

const [webuser, setWebuser] = useState({
EMAIL:''
,PHONE:''
,FULLNAME:''
,ID_TYPE:''
,PASSP_OR_ID_NBR:''
,MPE_REF:''
,STAMP:''
,regSubmitted:false
});

const [idtype, setIdtype] = useState([
<option key='lsta1' value=''></option>
,<option key='lsta2' value='TCI Belonger Card'>TCI Belonger Card</option>
,<option key='lsta3' value='Passport'>Passport</option>
,<option key='lsta4' value='Driver License'>Driver License</option>
,<option key='lsta5' value='NIB or NHIB Card'>NIB or NHIB Card</option>
,<option key='lsta6' value='Other Id'>Other Id</option>
]);


useEffect(() => {
	
	logout(sessionStorage.getItem("USERNAME"));
	getip();

/*
navigate("./apstest");
	 document.title =   "BFAPS Login";

*/

},[]);

useEffect(() => {
	
	openCity("login");
	/*console.log('cred.ip: '+cred.ip);*/
},[cred.ip]);

useEffect(() => {
	if (cred.emailcount!=null)
		getotp();
},[cred.emailcount]);

useEffect(() => {
	
	console.log("cred.isemail: "+cred.isemail);
	console.log("cred.otpsent: "+cred.otpsent);
	
	if (cred.isemail==true && cred.otpsent==false){
		document.getElementsByName('pwd')[0].readOnly=true;
		document.getElementsByName('pwd')[0].classList.add("gnsbro");
		document.getElementsByName('pwd')[0].value='';
		}
	else{
		document.getElementsByName('pwd')[0].readOnly=false;
		document.getElementsByName('pwd')[0].classList.remove("gnsbro");
		}
	
	/*console.log('cred.ip: '+cred.ip);*/
},[cred.otpsent, cred.isemail]);


const navigate = useNavigate();	




function logout(USERNAME){
	
const requested=logoutrequested();
//console.log('logoutrequested: '+requested);
if (logoutrequested==false)	
	return;

sessionStorage.clear();
sessionStorage.setItem("AD_USER", null);

if (USERNAME==null)
	return;

const requestOptions = {
	method: 'POST'
  ,headers: { 'Content-Type': 'application/json' }
  ,body: JSON.stringify( {USERNAME:USERNAME} )
  };

fetch(customspremurl+'rarmssql/logout',requestOptions)
  .then(res => res.json())
  .then(res => afterlogout() )
  .catch(err => console.log(err));	
}//end func

function afterlogout(){
//sessionStorage.setItem("RESTURL", resturl) ;
//console.log('afterlogout resturl: '+sessionStorage.getItem("RESTURL") );
}//end func

function logoutrequested(){
//console.log('location.state: '+JSON.stringify(location.state))	;
for(var attributename in location.state){
	if (attributename=='logout')
		if (location.state.logout==true){
			location.state.logout=false;			
			return true;
			}
	}//end for	
return false;	
}//end func

function updatewebuser(e){
	
if (webuser.regSubmitted==true){
	alert('your registration has been submitted already.  please visit Customer Service Centre with your passport or Id card');
	navigate('/login');
	return;
	}//end if

	if (webuser.FULLNAME=='' || webuser.PHONE=='' || webuser.ID_TYPE=='' || webuser.PASSP_OR_ID_NBR==''){
		showsnackbar('please provide name, phone, and legal status and valid TCI Id');
		return;
		}

   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {webuser:webuser, USERNAME:sessionStorage.getItem("USERNAME")} )
  };	
	setDowait({on:true});
  fetch(resturl+'rarwplogin/updatewebuser',requestOptions)
      .then(res => res.json())
      .then(res => 	{aftergetwebuser(res);  } )
      .catch(err => console.log(err));

	//console.log('aftergetwpapp: '+res[0].OCCUPATION);
}//end func

function aftergetwebuser(res){
setDowait({on:false});
if (res.length==undefined)
	showsnackbar("something went wrong.  please try again");
else{
	setWebuser((prev)=>{return {...prev, regSubmitted:true}})
	if (document.getElementById('btnupdate'))
		document.getElementById('btnupdate').style.visibility='hidden';
	
	showsnackbar("your registration is submitted.  please bring your valid Passport or Id card to Customer Service centre for verification")
	}

}//end func

function getSessionvars(navigateuserreg){
const requestOptions = {
  method: 'GET'
};

fetch(resturl+'rarwplogin/getsessionvars',requestOptions)
  .then(res => res.json())
  .then(res => aftergetSessionvars(res, navigateuserreg) )
  .catch(err => console.log(err));	
}//end func

function aftergetSessionvars(res, navigateuserreg){
//console.log('aftergetSessionvars: '+JSON.stringify(res));
//console.log('aftergetSessionvars res.length: '+res.length);
res.map((col, index) => {
	sessionStorage.setItem(col['SESSION_VAR'], col['VAL']);
});		
//console.log('RESTURL: '+sessionStorage.getItem("RESTURL"));
//navigate('/userreg');
if (navigateuserreg==true)
	alert("your registration has not been verified in the system.  Please visit Customer Service Centre with your Id or passport");
else
	navigate('/dashboard');
//console.log(sessionStorage.getItem("UPLOADURL"));
}//end func

function getip(){
const requestOptions = {
  method: 'GET'
};


fetch('https://api.ipify.org?format=json',requestOptions)
  .then(res => res.json())
  .then(res => aftergetip(res) )
  .catch(err => console.log(err));	
}//end func

function aftergetip(res){
setCred((prev)=>{ return {...prev,ip:res.ip} }) ;
}//end func

function loginad(){

const username=	document.getElementsByName('username')[0].value.trim();
const pwd=	document.getElementsByName('pwd')[0].value.trim();


if (username=='' || pwd=='')
	return;
	
const jsonObj={
	username:username+'@tcig.tc'
	,ip:cred.ip
	,password:pwd
	};
	const requestOptions = {
		  method: 'POST'
		  ,headers: { 'Content-Type': 'application/json' }
		  ,body: JSON.stringify( jsonObj )
	  };
setDowait({on:true});
	  fetch(customspremurl+'rarauthadng/auth',requestOptions)
		  .then(res => res.json())
		  .then(res => afterauthad(res, username))
		  .catch(err => console.log(err));	
}//end func

function afterinsertloginlog(res, USERNAME){
	console.log("afterinsertloginlog: "+USERNAME);
	setDowait({on:false});
const VERIFY_DATE_IS_NULL=strNullCheck(sessionStorage.getItem("VERIFY_DATE"))==null;

//console.log('VERIFY_DATE: '+sessionStorage.getItem("VERIFY_DATE"));
console.log('VERIFY_DATE_IS_NULL: '+VERIFY_DATE_IS_NULL);
let reqwebuserregister=null;
if (sessionStorage.getItem("AD_USER")!=null || VERIFY_DATE_IS_NULL==false){
	sessionStorage.setItem("USERNAME", res[0].USERNAME );
	sessionStorage.setItem("EMAIL", res[0].EMAIL);
	sessionStorage.setItem("NUM_ROLES", res[0].NUM_ROLES);
	//sessionStorage.setItem("VERIFY_DATE", res[0].VERIFY_DATE);
	reqwebuserregister=false; //getSessionvars(false);
	}
else if (VERIFY_DATE_IS_NULL==true){
	sessionStorage.setItem("USERNAME", USERNAME );
	reqwebuserregister=true;
	}//end if

getSessionvars(reqwebuserregister);
	
}//end func

function afterauthad(res, username){
	setDowait({on:false});
	console.log('afterauthad '+JSON.stringify(res))
	if (res.auth==undefined){
		showsnackbar('login failed');
		return;
		}//end if
	
	sessionStorage.setItem("AD_USER", null);
	if (res.auth==true){
		sessionStorage.setItem("AD_USER", username);
		getirdloginid(username);
		}//end if
	else{
		showsnackbar('login failed');
		return;
		}//end if
}//end func


function getirdloginid(username){
		
   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {USERNAME:username} )
  };	
	setDowait({on:true});
  fetch(resturl+'rarwpform/getirdloginid',requestOptions)
      .then(res => res.json())
      .then(res => 	aftergetirdloginid(res) )
      .catch(err => console.log(err));

	//console.log('aftergetwpapp: '+res[0].OCCUPATION);
}//end func

function aftergetirdloginid(res){
	setDowait({on:false});
if (res.length!=undefined)	
	sessionStorage.setItem("IRD_LOGIN_ID", res[0].IRD_LOGIN_ID);
insertloginlog();	
}//end func


function checkemailcount(){
		const requestOptions = {
		  method: 'POST'
		  ,headers: { 'Content-Type': 'application/json' }
		  ,body: JSON.stringify( {EMAIL:document.getElementsByName('username')[0].value} )
	  };
setDowait({on:true});

	  fetch(resturl+'rarwplogin/checkemailcount',requestOptions)
		  .then(res => res.json())
		  .then(res => aftercheckemailcount(res))
		  .catch(err => console.log(err));
		  	
}//end func

function aftercheckemailcount(res){
	
	setDowait({on:false});
	
	if (res.length!='undefined'){
		const emlCount=1*res[0].EMAIL_COUNT;
		if (emlCount==0){
			if (res[0].ALLOW_REGISTRATION==1)
				openCity("userreg");
			else
				showsnackbar("you cannot login at this time");
			}
		else
			setCred((prev)=>{ return {...prev,emailcount:res[0].EMAIL_COUNT} }) ;
		}//end if
		
			
}//end func

async function getotp(){

if (cred.isemail==true && cred.emailcount==null){
	//must verify the email address in DB before send OTP
	checkemailcount();
	return;
	}//end if
	

const jsonObj={
	email:document.getElementsByName('username')[0].value
	,ip:cred.ip
	,otp:document.getElementsByName('pwd')[0].value
	};

console.log('getotp jsonObj'+JSON.stringify(jsonObj));

		const requestOptions = {
		  method: 'POST'
		  ,headers: { 'Content-Type': 'application/json' }
		  ,body: JSON.stringify( jsonObj )
	  };
setDowait({on:true});

	  fetch(resturl+'rarwplogin/getotp',requestOptions)
		  .then(res => res.json())
		  .then(res => aftergetotp(res))
		  .catch(err => console.log(err));

}//end func


function insertloginlog(){

const USERNAME=document.getElementsByName('username')[0].value;

const jsonObj={
	USERNAME:USERNAME
	,IP:cred.ip
	};


		const requestOptions = {
		  method: 'POST'
		  ,headers: { 'Content-Type': 'application/json' }
		  ,body: JSON.stringify( jsonObj )
	  };
setDowait({on:true});

	  fetch(customspremurl+'rarmssql/insertloginlog',requestOptions)
		  .then(res => res.json())
		  .then(res => afterinsertloginlog(res, USERNAME))
		  .catch(err => console.log(err));

}//end func

function showBtnLoginOld(){
const len=document.getElementsByName('pwd')[0].value.length;
console.log('showBtnLogin len: '+len);
//document.getElementById('btnlogin').style.visibility=(len>4)?'visible':'hidden';
}	

function strNullCheck(strTemp){
// strTemp may be the string "NULL"
if (strTemp==undefined)
	return null;
else if (strTemp=='undefined')
	return null;
else if (strTemp==null)
	return null;
else if (strTemp.toUpperCase()=="NULL")
	return null;
else if (strTemp.toUpperCase()=="'NULL'")
	return null;
else
	return strTemp
}//end func

function aftergetotp(res){
	setDowait({on:false});
	//console.log('aftergetotp emailConfirmed: '+emailConfirmed);
	//console.log('aftergetotp res: '+JSON.stringify( res ));
/*
	if (emailConfirmed==false){
		//console.log('aftergetotp jsonObj'+JSON.stringify(res[0]));
		emailConfirmed=true;
		document.getElementById('loginwaiticon').style.visibility='hidden';
		document.getElementsByName('pwd')[0].readOnly=false;
		document.getElementsByName('pwd')[0].style.backgroundColor='white';
		document.getElementsByName('pwd')[0].focus();
		}

*/
//only check MATCH_OTP after otpsent
if (res.length==undefined){
	showsnackbar('no result on aftergetotp');
	return;
	}
	

console.log('res[0].MATCH_OTP: '+res[0].MATCH_OTP);
console.log('res[0].VERIFY_DATE: '+res[0].VERIFY_DATE);

if (cred.otpsent==true)
	if (res[0].MATCH_OTP==1){
		sessionStorage.setItem("VERIFY_DATE", res[0].VERIFY_DATE);
		insertloginlog();
		}//end if
	else{
		showsnackbar('login failed');
		//document.getElementById('btnlogin').style.visibility='visible';
		//document.getElementById('loginwaiticon').style.visibility='hidden';
		}//end else
	else if (res[0].VERIFY_DATE==null)
		alert("your registration has not been verified in the system.  Please visit Customer Service office with your Id or passport");
	else {
		setCred((prev)=>{ return {...prev,otpsent:true} }) ;
		showsnackbar('please check your email inbox for one-time-password');
		}
		
			
		
	
}//end func









function openCity(cityName) {

//	currCity=cityName;

//console.log('openCity cityName: '+cityName);

  var i, tabcontent, tablinks;
  tabcontent = document.getElementsByClassName("tabcontent");
  //console.log('tabcontent.length: '+tabcontent.length);
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  tablinks = document.getElementsByClassName("tablinks");
  for (i = 0; i < tablinks.length; i++) {
	tablinks[i].className = tablinks[i].className.replace(" active", "");
	

	}//end for
  document.getElementById(cityName).style.display = "block";
  if (cityName=="userreg"){
	  const email=document.getElementsByName('username')[0].value;
	  setWebuser((prev)=>{return {...prev, EMAIL:email}});
	  //document.getElementsByName('EMAIL')[0].value=;
	  document.getElementsByName('FULLNAME')[0].focus();
	}
  else if (cityName=="login")
	  document.getElementsByName('username')[0].focus();
 
  
}//end function



function showsnackbar(innerHTML) {
  var x = document.getElementById("snackbar");
  x.innerHTML=innerHTML;
  x.className = "show";
  setTimeout(function(){ x.className = x.className.replace("show", ""); }, 5000);
}//end func



function pwdChange(e){
//console.log('pwdChange empty:'+empty);
const empty=e.target.value.trim()=="";	
//console.log('pwdChange empty:'+empty);

if (document.getElementById('btnloginotp'))
	document.getElementById('btnloginotp').style.visibility=(empty==true)?"hidden":"visible";
if (document.getElementById('btnloginad'))
	document.getElementById('btnloginad').style.visibility=(empty==true)?"hidden":"visible";
}//end func

function emailChange(e){
const isemail=e.target.value.includes('@')?true:false;
setCred((prev)=>{return {...prev,isemail:isemail}});
}//end func

function webuserChange(e){
//const val=e.target.value;
console.log('e.target.value: '+e.target.value)
const {name, value}=e.target;

setWebuser((prev) => {
return {...prev, [name]:value}
});	
}//end func

const handleFocus = (e) => {
	e.target.select();
}

const location = useLocation();
//console.log('location.pathname: '+location.pathname);
//console.log('window.location: '+window.location);

document.getElementsByTagName('body')[0].classList.add("loginbody");



    return (
		<>


	<table border="0" align='center' width='100%' style={{background:"#f2aa0f", MarginTop:"0px"}} >
	<tbody>
	<tr>
	<td align='center' style={{paddingTop:"9px",paddingBottom:"9px"}} >
		&nbsp;{/*<img src='img/csclogo.png' border='0' height='160' />*/}
	</td>
	<td align='center'>
	{/*
		<span className='bannertextlarge'>Customer Service Center</span>
		<div className='bannertextmedium'>Work Permit Applications Portal</div>
	*/}
		<span className='bannertextlarge'>
			{/*Application Processing System*/}
			Corporate Employment Services
		</span>
		<div className='bannertextsmall'>Ministry of Immigration and Border Services<br/>Turks and Caicos Islands Government</div>
	</td>
	<td align='center'  style={{paddingTop:"9px",paddingBottom:"9px"}}  >
		&nbsp;{/*<img src='img/minlogo.png' border='0' height='160' />*/}
	</td>
	</tr>
	</tbody>
	</table>

  <div id="login" className="tabcontent"  >
  
	  <table align='center' border='0' style={{marginTop:"18px",width:"280px"}} border='0' >
		  <tbody>
		  <tr>
			<td align='center' ><img src='img/coatofarms.png' border='0' /></td>
		  </tr>
		  
		  <tr>
		  <td><input type="text" id="username" name='username' defaultValue={cred.username} onChange={(e)=>{emailChange(e)}} placeholder="username or email address" autoComplete='on'  onFocus={handleFocus} /></td>
		  </tr>
		  <tr>
		  <td><input type="password" placeholder="password" id="pwd" name='pwd' onChange={(e)=>{pwdChange(e)}} defaultValue={cred.pwd}  autoComplete='off'  onFocus={handleFocus} /></td>
		  </tr>
		  <tr>
		  <td>
		  
			{dowait.on && <div><img src='img/Loading_2.gif' border='0' height='36' /> . . . please wait</div>}
			

		  {!dowait.on && cred.isemail && !cred.otpsent && <input type="button" value="request one-time-password" className="button w100pct" id="btngetotp" onClick={ (e) => { getotp() } } /> }
		  {!dowait.on && cred.isemail && cred.otpsent && <input type="button" value="login with one-time-password" className="button w100pct" id="btnloginotp" onClick={ (e) => { getotp() } } /> }
		  {!dowait.on && !cred.isemail && <input type="button" value="login" className="button w100pct" id="btnloginad" onClick={ (e) => { loginad() } } /> }
						
				<table border="0" align="center"  id="loginwaiticon"  >
				<tbody>
				<tr>
				<td align="right" >
				<img src="img/ajax_loader.gif" border="0" height="50"    />
				</td>
				<td align="left" >
				. . . please wait
				</td>
				</tr>
				</tbody>
				</table>
		  </td>
		  </tr>
		  </tbody>
		  </table>
	</div>	  

	<div id="userreg" className="tabcontent"  >
		<p style={{marginTop:"60px"}} align='center' >
		The user registration form below must be submitted before you can login.
		<br/>You are required to enter a valid passport or TCI identificaiton card number of yours
		<br/>please bring your valid passport or TCI identificaiton card to Customer Service Center for verification
		</p>
		{/* readOnly className="gnsbro" */}			
		<table align='center'>
		<tbody>
		<tr>
		<td align='right' >Email: </td>
		<td align='left' ><input name='EMAIL' defaultValue={webuser.EMAIL} onChange={(e)=>{webuserChange(e);}}  /></td>
		</tr>
		<tr>
		<td align='right' >Full Name: </td>
		<td align='left' ><input name='FULLNAME' defaultValue={webuser.FULLNAME}  onChange={(e)=>{webuserChange(e);}} autoComplete='off' placeholder='full name' /></td>
		</tr>
		<tr>
		<td align='right' >Phone: </td>
		<td align='left' ><input name='PHONE' defaultValue={webuser.PHONE} onChange={(e)=>{webuserChange(e);}} autoComplete='off' placeholder='phone'   /></td>
		</tr>
		<tr>
		<td align='right' >Identification: </td>
		<td align='left' ><select name='ID_TYPE' defaultValue={webuser.ID_TYPE} title={webuser.ID_TYPE} onChange={(e)=>{webuserChange(e);}} >{idtype}</select></td>
		</tr>
		<tr>
		<td align='right' >Identification #: </td>
		<td align='left' ><input  name="PASSP_OR_ID_NBR" defaultValue={webuser.PASSP_OR_ID_NBR} onChange={(e)=>{webuserChange(e);}}  placeholder='Passport # or Id card #'  autoComplete='off' /></td>
		</tr>
		{/*
		<tr>
		<td align='right' >Recent Work Permit Card Ref. No.</td>
		<td align='left' ><input name='MPE_REF' defaultValue={webuser.MPE_REF}  type="text" onChange={(e)=>{webuserChange(e);}} placeholder='skip this if you do not know'  /></td>
		</tr>
		
		*/}

		<tr>
		<td align='right' >&nbsp;</td>
		</tr>
			{webuser.STAMP=='' &&
			<tr>
			<td align='right' >&nbsp;</td>
			<td align='left' >
			{dowait.on && <div><img src='img/Loading_2.gif' border='0' height='36' /> . . . please wait</div>}
			{!dowait.on && <button className="button" id='btnupdate' onClick={(e)=>{updatewebuser(e);}} >submit registration form</button>}
			</td>
			</tr>		
			}
		</tbody>
		</table>
	</div>	  

  

	<div id='snackbar' ></div>
	</>
);

}//end class

{
/*
onClick="document.getElementById('id01').style.display='none'"
onClick="document.getElementById('id01').style.display='none'"
*/
}

export default Login; 

