import React, { useState, useEffect } from "react";
//import { confirm } from "react-confirm-box";
import axios from 'axios';
import {Link, NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import $ from 'jquery';

import { useNavigate } from 'react-router-dom'
//var dateFormat = require('dateformat');



const RenderWpForm = () => {
const navigate = useNavigate();
	
const resturl=sessionStorage.getItem("RESTURL");

//var boolFetchingSads=false;

const navSubmitwpform = React.useRef();
const navExportwpform = React.useRef();
const navImportwpform = React.useRef();


window.addEventListener("keypress", function (event) {
 // detect event keys
 if (event.keyCode == 13) {
   //callsadfinder();
   
 }
});


const [reRender, setReRender] = useState({count:0});
const [wptapp, setWptapp] = useState({WPT_APP_ID:0
, REF_NUM:''
, MODIFY_DATE:''
, SUBMITTED_TO_CSC:''
});
const [dowait, setDowait] = useState({show: false});
const [wpformdata, setWpformdata] = useState([]);


const [selectedFiles, setSelectedFiles] = useState([]);
const [sectionfields, setSectionfields] = useState([]);
const [tblwpapps, setTblwpapps] = useState([]);
const [sectionnames, setSectionnames] = useState([{letter:'A',name:'INFORMATION ABOUT THE PERSON TO BE EMPLOYED'}]);


const [progress, setProgress] = useState({
perct:null
});


const [axiofl, setAxiofl] = useState({
selectedFile: null
});

const [section, setSection] = useState({letter:'A',name:'',index:0});

const [formControls, setFormControls] = useState([]);

//const [currFormControls, setCurrFormControls] = useState([]);

const [wpformsections, setWpformsections] = useState([]);
const [dbfields, setDbfields] = useState([]);


const [wpformoptions, setWpformoptions] = useState([]);


//let numComboOpts=0;







const location = useLocation();


useEffect(() => {
/*
2024-01-16
The sequence getwpformsections then getwpformoptions had to change to 
getwpformoptions then getwpformsections
so that when getwpformsections calls onClick={handleSectionChange}
the handleSectionChange function can see the wpformoptions

problem where the call to handleSectionChange is in aftergetwpsections, the function that builds
the table of sections to click
At the time aftergetwpsections is called, wpformoptions is not yet set, so it was coming back empty
*/

document.title =   "APS Application Form";	

//if (sessionStorage.getItem("VERIFY_DATE")==null && (sessionStorage.getItem("NUM_ROLES")==0||sessionStorage.getItem("NUM_ROLES")==null))
	//navigate('/login');
if (sessionStorage.getItem("USERNAME")==null || sessionStorage.getItem("USERNAME")=="null" || sessionStorage.getItem("USERNAME")==undefined || sessionStorage.getItem("USERNAME")==""){
	navigate("/");
	return;
	}//end if
	
if (wpformoptions.length<1){
	getwpformoptions();
	
	}//end if
	
 },[]);
/*
useEffect(() => {
	if (selectedFiles[0]!=null){
		let newFile=new File([selectedFiles[0]], 'test.pdf');
		let fileSize = newFile.size;//17898 is 18K
		console.log('fileSize: '+fileSize);
		}
	},[JSON.stringify(selectedFiles)]);
*/


useEffect(() => {

if (wpformoptions.length>0 && dbfields.length>0){
	let tempFormData={startup:true};	
	dbfields.map((col, index) => {
		const fld=col['FLD'];
		//if (col['SCTN']==section.letter)
			tempFormData[fld]='';//initialize all fields to empty
	});		

	//console.log('tempFormData: '+JSON.stringify(tempFormData));
	setWpformdata(tempFormData);
	}//end if
	
},[JSON.stringify(dbfields), JSON.stringify(wpformoptions)] );


useEffect(() => {
//console.log('wpformdata: '+JSON.stringify(wpformdata) );
if (dbfields.length>0){

console.log('wpformdata.MPR_NAME1: '+wpformdata.MPR_NAME1 );
console.log('wpformdata.MPE_FOTO: '+wpformdata.MPE_FOTO );


console.log('dbfields.length: '+dbfields.length);
console.log('wpformdata.startup: '+wpformdata.startup);

	//if (formControls.length==0 && dbfields.length>0 && wpformdata.startup==true){
	if (dbfields.length>0){
		//let sectionletter=(section.letter=='')?'A':section.letter;
		//let index=(section.letter=='')?'A':section.index;
		if (location.state.SCTN==undefined || sectionnames.length==1) 
			handleSectionChange(section.letter, section.index);
		else {
			console.log('sectionnames.length: '+sectionnames.length);
			const sctn=location.state.SCTN;
			location.state.SCTN=undefined;
			const indx = getSectionIndex(sctn);
			handleSectionChange(sctn, indx);
			}
			
			
			
		//usedbfields(sectionletter);// will call setFormControls
		}//end if


	}//end if

},[wpformdata]); 


useEffect(() => {

//console.log('useEffect formControls.length: '+formControls.length);	
if (formControls.length>0){
	//formControls must load before getting wpsections because the wpsections has reference to handleChange
	if (wpformsections.length==0)
		getwpsections();
	else{
		//setReRender((prev)=>{ return{...prev, count:reRender.count+1}});
		//this just to call the spread operator ... to cause rerender
		//document.getElementsByName('MPE_GENDER')[0].value='Female';
		//document.getElementsByName('MPE_TITLE')[0].value='Ms.';
		setWpformdata((prev)=>{ return{...prev}});
		}//end else

	}//end of
},[JSON.stringify(formControls)]); 


useEffect(() => {
if (wpformsections.length>0){
	let appid=0;

	if (location.state.WPT_APP_ID!=null && location.state.WPT_APP_ID!=undefined && location.state.WPT_APP_ID!='null' && location.state.WPT_APP_ID!='undefined')
		appid=location.state.WPT_APP_ID;

/*
	else if (sessionStorage.getItem("WPT_APP_ID")!=null && sessionStorage.getItem("WPT_APP_ID")!=undefined && sessionStorage.getItem("WPT_APP_ID")!='null' && sessionStorage.getItem("WPT_APP_ID")!='undefined')
		appid=sessionStorage.getItem("WPT_APP_ID");

*/
		  
	  
	if (appid!=null && appid>0)
		handleWpappChange(null, appid);
	}//end of
},[JSON.stringify(wpformsections)]); 

	








 
//emptyselectedsad=selectedsad;
//sadfinder.regDat1



  const handleFocus = (e) => {
  //e.target.blur();
  if (e.target.readOnly)
	e.target.style.background='gainsboro';
/*
else 
	e.target.select();
*/
	e.target.select();

	}//end func





function getwpsections(){

   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {} )
  };		

  fetch(resturl+'rarwpform/getwpsections',requestOptions)
      .then(res => res.json())
      .then(res => 	aftergetwpsections(res) )
      .catch(err => console.log(err));

}//end func


async function getwpformoptions(){
	
   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {USERNAME:sessionStorage.getItem("USERNAME")} )
  };		
	try {
		// let [items, contactlist, itemgroup] = await Promise.all([
		//, fetch(resturl+'rarwpform/gettblwpapp', requestOptions).then(res => res.json()).then(res => 	aftergettblwpapps(res) ).catch(err => console.log(err))
	   await Promise.all([
	   
		 fetch(resturl+'rarwpform/getwpformoptions', requestOptions).then(res => res.json()).then(res => 	aftergetwpformoptions(res) ).catch(err => console.log(err))
		 , fetch(resturl+'rarwpform/getdbfields', requestOptions).then(res => res.json()).then(res => 	aftergetdbfields(res) ).catch(err => console.log(err))
	  ]);

	  {/*
	  ReactDOM.render(
		<Test items={items} contactlist={contactlist} itemgroup={itemgroup} />,
		  document.getElementById('overview');
	  );		  
	  */}

	}
	catch(err) {
	  console.log(err);
	};
		
	
}//end func

function handleSectionChange(pLetter, pIndex){
/*
2024-01-16

The sequence getwpformsections then getwpformoptions had to change to 
getwpformoptions then getwpformsections
so that when getwpformsections calls onClick={handleSectionChange}
the handleSectionChange function can see the wpformoptions

problem where the call to handleSectionChange is in aftergetwpsections, the function that builds
the table of sections to click
At the time aftergetwpsections is called, wpformoptions is not yet set, so it was coming back empty

*/

/*
console.log('handleSectionChanges sectionLetter: '+sectionLetter);
console.log('handleSectionChange wpformoptions.length: '+wpformoptions.length);
console.log('handleSectionChange formControls.length: '+formControls.length);

*/
console.log('handleSectionChange sectionnames.length, pLetter, index: '+sectionnames.length+', '+pLetter+', '+pIndex);




const name=sectionnames[pIndex].name;
setSection((prev) => {
return {...prev, letter:pLetter, index:pIndex, name:name}
});

if (document.getElementById('rd_'+pLetter))
	document.getElementById('rd_'+pLetter).checked=true;

usedbfields(pLetter);


}//end funcs










function aftergetwpformoptions(res){
console.log('aftergetwpformoptions: ');
//	console.log('aftergetwpformoptions: '+JSON.stringify(res));
	
let tempwpformoptions=[];

	
res.map((col, index) => {
	const combo=col['COMBO'];
	const key=combo+"_"+index;
	const key2=combo+"_"+index+"_2";
	const opt=col['OPT'];
	//the value of combo is integer in 2 cases: MPE_OCCUPATIONID and AGENCY_ID
	const val=(combo=='agencyid' || combo=='occupationid')? col['VAL']:opt;
	//console.log('afterfetchctrycodes nam: '+nam);
	
	tempwpformoptions.push({name:combo, options:<option key={key} value={val}  >{opt}</option>});
	
	});//end map
console.log('aftergetwpformoptions tempwpformoptions.len : '+tempwpformoptions.length);
setWpformoptions(tempwpformoptions);



}//end func

function handleWpappChange(e, WPT_APP_ID){
console.log('handleWpappChange id: '+WPT_APP_ID);


   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {WPT_APP_ID:WPT_APP_ID} )
  };		

  fetch(resturl+'rarwpform/getwpapp',requestOptions)
      .then(res => res.json())
      .then(res => 	aftergetwpapp(res) )
      .catch(err => console.log(err));

}//end func

function gettblwpapps(){

   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {USERNAME:sessionStorage.getItem("USERNAME")} )
  };		

  fetch(resturl+'rarwpform/gettblwpapps',requestOptions)
      .then(res => res.json())
      .then(res => 	aftergettblwpapps(res) )
      .catch(err => console.log(err));

}//end func

function aftergetwpapp(res){
//sessionStorage.setItem("WPT_APP_ID", res[0].WPT_APP_ID);
setWptapp((prev)=>{return {...prev
	, WPT_APP_ID:res[0].WPT_APP_ID
	, REF_NUM:res[0].REF_NUM
	, MODIFY_DATE:res[0].CHR_MODIFY_DATE
	, SUBMITTED_TO_CSC:res[0].CHR_SUBMITTED_TO_CSC
}});

let datas={};
datas['startup']=false;
datas['REF_NUM']=res[0].REF_NUM;

for(var attributename in res[0]){
		const data=res[0][attributename];
		datas[attributename] = (data==null)?'':data;
	}//end for	
	
setWpformdata(datas);

	
}//end func

function aftergettblwpapps(res){
if (res.length==undefined)
	return;
	
console.log('aftergettblwpapps, res.length: '+res.length);

let htmrows=[];
res.map((col, index) => {
	const appid=col['WPT_APP_ID'];
	const refnum=col['REF_NUM'];
	const MPR_NAME1=col['MPR_NAME1'];
	const mpe_name=col['MPE_NAME'];
	const dated=col['CREATE_DATE'];
	const date_time=col['CREATE_DATE_TIME'];
	const trkey='tr_'+appid;
	const tdkey='td_'+appid;
	const rdkey='td_'+appid;
	const rdappid=<><input type='radio' name='rdappid' key={rdkey} id={rdkey} value={appid} onClick={(e)=>{handleWpappChange(e, appid)}} /><label htmlFor={rdkey} className='hand' >{refnum}</label></>;
	const htmrow=<tr key={trkey} >
	<td style={{"paddingTop":"12px"}} key={tdkey}  >{rdappid}</td>
	<td   key={'c2'+tdkey}  >{MPR_NAME1}</td>
	<td   key={'c3'+tdkey}  >{mpe_name}</td>
	<td   key={'c4'+tdkey} title={date_time} >{dated}</td>
	</tr>;
	htmrows.push(htmrow);
});

setTblwpapps(htmrows);
}//end func

function aftergetwpsections(res){
if (res.length==undefined)
	return;
	
console.log('aftergetwpsections, res.length: '+res.length);

let htmrows=[];
let tempSectionnames=sectionnames;
//let tempSectionnames=[];
let rowcount=1;
res.map((col, index) => {
	const letter=col['SCTN_LETTER'];
	const shortname=col['SHORT_NAME'];
	const name=col['SCTN_NAME'];
	
	const trkey='tr_'+letter;
	const tdkey='td_'+letter;
	const rdkey='rd_'+letter;
	const pIndex=rowcount-1;//calling requires constant value for index
	const rdsection=<><input type='radio' name='rdsection' key={rdkey} id={rdkey} onClick={(e)=>{handleSectionChange(letter, pIndex);}} /><label htmlFor={rdkey} className='hand'  >{shortname}</label></>;
	htmrows.push(<tr key={trkey} ><td  style={{"paddingTop":"12px"}} key={tdkey}  >{rdsection}</td></tr>);
	if (rowcount>1)
		tempSectionnames.push({letter:letter,name:name});
	rowcount++;
});

setWpformsections(htmrows);
setSectionnames(tempSectionnames);

}//end func

function getSectionIndex(pLetter){

console.log('getSectionIndex pLetter: '+pLetter);
console.log('getSectionIndex sectionnames.length: '+sectionnames.length);
for (var i=0; i<sectionnames.length; i++){
	if (sectionnames[i].letter==pLetter){
		return i;
		}//end if
	}//end for
}//end func





function aftergetdbfields(res){

setDbfields(res);

}//end func

function clickComboplus(e, fld){
e.preventDefault();	
const elem=document.getElementById(fld);
	const userOpt=prompt('Please enter your intended option:');
	if (userOpt){
		console.log('elem.selectedIndex: '+elem.selectedIndex);
		var opt = document.createElement('option');
		opt.value = userOpt;
		opt.innerHTML = userOpt;
		const len=elem.length;
		console.log('len: '+len);
		elem.appendChild(opt);
		elem.selectedIndex=len;
		}//end if
	else
		elem.selectedIndex=0;
	
}//end if


function usedbfields(sectionLetter){
//usedbfields will resetFormControls
console.log('usedbfields sectionLetter: '+sectionLetter);

let tempFormControls=[];
let tempSectionfields=[];

if (dbfields==null || dbfields==undefined || dbfields=='null' || dbfields=='undefined' || dbfields.error){
	console.log('will exit prepareFormControls for dbfields==null || dbfields==undefined .');
	return;
	}//end if
	
if (dbfields.length<1){
	console.log('will exit prepareFormControls for dbfields.length<1 ?');
	return;
	}//end if	
//else
	//console.log('prepareFormControls dbfields.length: '+dbfields.length);

let fileCount=0;
	
dbfields.map((col, index) => {
	const sctn=col['SCTN'];
	const fld=col['FLD'];
	const datafield=col['DATAFIELD'];
	let typ=col['TYP'];
	const rqd=col['RQD'];
	let comboplus='';
	if (typ=='combo+'){
		typ='combo';
		comboplus=<><a href='#' onClick={(e)=>{clickComboplus(e, fld);}} ><img src='img/add.png' height="18" border='0' /></a></>;
		}//end if
	tempSectionfields.push({section:sctn, field:fld, datafield:datafield, typ:typ, rqd:rqd});
	
	if (sctn==sectionLetter){
		const currfileCount=fileCount;
//.replace('\r', '<br/>').replace('\n', '<br/>')
	const key="_key_"+index;
	
	const opt=col['OPT'];
	const elemId=col['FLD'];
	//const redstar = (datafield==1 && rqd==1)?<span style={{color:'red'}}>*&nbsp;</span>:<></>;
	const lbl=col['SLBL'];
	//(datafield==1 && rqd==1)?:<><span style={{color:'red'}}>*&nbsp;</span>{col['LBL']}</>:<>col['LBL']</>;
	//if (typ=='combo') {console.log('opt: '+opt);}
	
	let inputsize=20;
	if (opt!=null)
		inputsize=1*opt;
	
	let opts=[];
	

//const checkboxes=getcheckboxes(opt);s

	for (var i=0; i<wpformoptions.length; i++)
		if (wpformoptions[i].name==opt)
			opts.push(wpformoptions[i].options);

	//console.log('afterfetchctrycodess nam: '+nam);
	if (typ=='textarea')
		tempFormControls.push(<div align='left' className="formcontrols" style={{"paddingTop":"0px"}} key={"div"+key} ><label key={"lbl"+key} htmlFor={elemId} dangerouslySetInnerHTML={{__html: lbl}} ></label><br/><textarea key={"textarea"+key} name={fld} id={elemId} onChange={(e)=>{handleChange(e, typ, rqd);}} autoComplete='off' onFocus={(e)=>{e.target.select();}} cols="50" rows="4" defaultValue={wpformdata[fld]} ></textarea></div>);
	else if (typ=='combo')
		tempFormControls.push(<div align='left' className="formcontrols" key={"div"+key} ><label key={"lbl"+key} htmlFor={elemId} dangerouslySetInnerHTML={{__html: lbl}} ></label><br/><select key={"combo"+key} name={fld}  id={elemId} title={wpformdata[fld]}   value={wpformdata[fld]}  onChange={(e)=>{handleChange(e, typ, rqd);}} style={{maxWidth:"400px"}} ><option value='' key={'opt'+key} ></option>{opts}</select>&nbsp;&nbsp;{comboplus}</div>);
	else if (typ=='sectionhead')
		tempFormControls.push(<div align='left' className="formcontrols" key={"div"+key} ><p className="wpsectionhead" >{lbl}</p></div>);
	else if (typ=='msg')
		tempFormControls.push(<div align='center' className="formcontrols" key={"div"+key} ><p className="wpmsg" dangerouslySetInnerHTML={{__html: lbl}} ></p></div>);
	else if (typ=='date')
		tempFormControls.push(<div align='left' className="formcontrols" key={"div"+key} ><label key={"lbl"+key} htmlFor={elemId} dangerouslySetInnerHTML={{__html: lbl}} ></label><br/><input key={"input"+key} name={fld} id={elemId} defaultValue={wpformdata[fld]}  onChange={(e)=>{handleChange(e, typ, rqd);}} type='date' onFocus={(e)=>{e.target.select();}} /></div>);
	else if (typ=='file'){
		let filename='';
		if (fileCount==0){filename="foto.png"}
		if (fileCount==1){filename="scans.pdf"}

		{/*
		if (fileCount==1){filename="sign.png"}
		if (fileCount==2){filename="scans.pdf"}
		*/}
		tempFormControls.push(<div align='left' className="formcontrols" key={"div"+key} style={{marginBottom:"8px"}} ><label key={"lbl"+key} htmlFor={elemId} dangerouslySetInnerHTML={{__html: lbl}} ></label><br/><input key={"input"+key} name={fld} id={elemId} defaultValue={wpformdata[fld]}  onChange={(e)=>{selectedFilesChange(e, currfileCount, filename);}} type='file' onFocus={(e)=>{e.target.select();}} /><br/><a href={refurl+filename} target="_none" className="nodecor" >{wpformdata[fld]}</a></div>);
		fileCount++;
		}
	else if (typ=='checkbox')
		tempFormControls.push(<div align='left' className="formcontrols" key={"div"+key} ><label key={"lbl"+key} htmlFor={elemId} >{lbl}</label><br/>{opts}</div>);
	else{
		//default (typ=='text')
		tempFormControls.push(<div align='left' className="formcontrols" key={"div"+key} ><label key={"lbl"+key} htmlFor={elemId} dangerouslySetInnerHTML={{__html: lbl}} ></label><br/><input key={"text_input"+key} name={fld} id={elemId} defaultValue={wpformdata[fld]} size={inputsize}  onChange={(e)=>{handleChange(e, typ, rqd);}} type='text' autoComplete='off' onFocus={(e)=>{e.target.select();}} /></div>);
		}
		
	}//end if
	
	});//end map



console.log('wpformoptions.length: '+wpformoptions.length);
/*
tempFormControls=[];
tempFormControls.push(<input key={"text_input_"+fld1} name={fld1} id={fld1} defaultValue={wpformdata[fld1]}   onChange={(e)=>{handleChange(e, 'text');}} type='text' autoComplete='off' onFocus={(e)=>{e.target.select();}} />);
tempFormControls.push(<input key={"text_input_"+fld2} name={fld2} id={fld2} defaultValue={wpformdata[fld2]}   onChange={(e)=>{handleChange(e, 'text');}} type='text' autoComplete='off' onFocus={(e)=>{e.target.select();}} />);
setWpfields((prev) => {
return {...prev, [name]:value}
});

*/

setFormControls(tempFormControls);

if (sectionfields.length==0)
	setSectionfields(tempSectionfields)	;
	
}//end func

function updatedbfieldsOld(){
let temp=dbfields;
dbfields.map((col, index) => {
	const fld=col['FLD'];
	dbfields.FORM_DATA=wpformdata[fld];
});
setDbfields(temp);	
}//end func

function selectedFilesChange(event, index, filename){
//console.log('selectedFilesChange event.target.files.length: '+event.target.files.length);
//console.log('selectedFilesChange index: '+index);

const i=selectedFiles.length;
console.log('selectedFiles.length: '+i);
let temp=selectedFiles;
temp[i]={file:event.target.files[0], name:filename};
//temp[index]=event.target.files[0]
setSelectedFiles(temp);
if (document.getElementById('btnsavechanges'))
	document.getElementById('btnsavechanges').style.visibility='visible';

if (document.getElementById('btnsubmitapp'))
	document.getElementById('btnsubmitapp').style.visibility='hidden';

}//end func

async function uploadfiles(){
	console.log('uploadfiles: '+selectedFiles.length);
	
//setAxiofl({ selectedFile: event.target.files });	
	//event.target.files
/*
ensure the below line in node js has the correct array size of 3 
router.post('/uploadfiles', upload.array('filesupload',3), (req, res, next) => {
*/	
 
 const FormData = require('form-data'); // npm install --save form-data

const form = new FormData();

form.append('WPT_APP_ID', wptapp.WPT_APP_ID);
form.append('REF_NUM', wptapp.REF_NUM);
form.append('USERNAME', sessionStorage.getItem("USERNAME"));
//let formfiles={foto:false,sign:false,scans:false};
let filenames={foto:'',sign:'',scans:''};
let fotoFile=null, signFile=null, scansFile=null;
let numfilesupload=0;
for (var i=0; i<selectedFiles.length; i++){
	const currfile=selectedFiles[i].file;
	const name=selectedFiles[i].name;
	if (name=='foto.png'){
		fotoFile=new File([currfile], 'foto.png');
		filenames.foto=currfile.name;
		numfilesupload++;
		//formfiles.foto=true;
		}//end if
	else if (name=='sign.png'){
		signFile=new File([currfile], 'sign.png');
		filenames.sign=currfile.name;
		numfilesupload++;
		//formfiles.sign=true;
		}//end if
	else if (name=='scans.pdf'){
		scansFile=new File([currfile], 'scans.pdf');
		filenames.scans=currfile.name;
		numfilesupload++;
		//formfiles.scans=true;
		}//end if
	
	}//end for

//form.append('filenames', filenames);
form.append('MPE_FOTO', filenames.foto);
form.append('MPE_SIGN', filenames.sign);
form.append('MPE_SCANS', filenames.scans);

if (fotoFile!=null)	{form.append('filesupload', fotoFile);}
if (signFile!=null)	{form.append('filesupload', signFile);}
if (scansFile!=null)	{form.append('filesupload', scansFile);}

//const url=resturl+'rarwpform/upload'+numfilesupload+'file';
//console.log('uploadfile url: '+url)
const request_config = {
    onUploadProgress: data => {
      //Set the progress value to show the progress bard
      setProgress({perct:Math.round((100 * data.loaded) / data.total)});
    },
};
await axios.post(resturl+'rarwpform/upload'+numfilesupload+'file', form, request_config)
.then(res => afteruploadfiles(res.data, filenames.foto, filenames.scans));

{/*
	function cb(res){
		console.log('cb: '+JSON.stringify(cb));
	}

*/}

	
}//end func

function afteruploadfiles(data, fotofilename, scansfilename){
	console.log('afteruploadfiles: '+JSON.stringify(data));
	if (document.getElementById('btnsubmitapp'))
		document.getElementById('btnsubmitapp').style.visibility='visible';
	
	//showsnackbar('record update complete');
	setDowait({show:false});
	
	if (fotofilename!='' && data.fotoOk==false)
		alert("The photographs of the foreign worker did not meet the requirement; width and height should be close to 600 pixels");

	if (scansfilename!='' && data.pdfPageCount<3)
		alert("The scanned documents in pdf format did not meet the requirement; it should be 3 pages or more");
	
}//end func

function updatewpapp(e){
document.getElementById('btnsavechanges').style.visibility='hidden';


console.log('WPT_APP_ID: '+wptapp.WPT_APP_ID);
if (wptapp.SUBMITTED_TO_CSC!=null){
	alert('changes cannot be saved because this application was submitted already!');
	return;
	}//end if
if (section.letter=='J'){
	uploadfiles();
	return;
	}//end if

let params=wpformdata;
params.USERNAME=sessionStorage.getItem("USERNAME");//'admin'
params.WPT_APP_ID= wptapp.WPT_APP_ID;
params.SCTN= section.letter;
params.sectionfields= sectionfields;

    const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( params )
  };

	setDowait({show:true});
	
  fetch(resturl+'rarwpform/updatewpapp',requestOptions)
      .then(res => res.json())
      .then(res => 	afterupdatewpapp(res) )
      .catch(err => console.log(err));
}//end func

function afterupdatewpapp(res){
	
navSubmitwpform.current?.click();

/*
	if (document.getElementById('btnsubmitapp'))
		document.getElementById('btnsubmitapp').style.visibility='visible';
	
	setDowait({show:false});

*/
	
}//end func

/*
	let test='';
	if (res.length)
		if (res.length>0)
			test=JSON.stringify(res);
	console.log('afterupdatewpapp: '+test);
EXT_PLC
EXT_TPT_INF
EXT_TPT_NAM
EXT_TPT_IDE
EXT_TPT_NAT_COD
EXT_TPT_NAT_NAM
*/

function handleChange(e, typ, rqd){
//console.log('handleChange nam: '+e.target.name);
//console.log('handleChange typ: '+typ);

const val=e.target.value;
{/*
if (typ=='combo' && val=='Other'){
	const userOpt=prompt('Please enter your intended option:');
	if (userOpt){
		var opt = document.createElement('option');
		opt.value = userOpt;
		opt.innerHTML = userOpt;
		const len=e.target.length;
		e.target.appendChild(opt);
		e.target.selectedIndex=len;
		}//end if
	else
		e.target.selectedIndex=0;
	return;
	}//end if
*/}


const {name, value}=e.target;
//const value=(rawvalue==null)?'':rawvalue.trim();
/*
to do: implement validations:
(i) names are alpha only
(ii) money is numbers only
(iii) remove all not alpha-numeric other than -_', also check lengths of names and emails
*/
document.getElementById('btnsavechanges').style.visibility='visible';
if (document.getElementById('btnsubmitapp'))
	document.getElementById('btnsubmitapp').style.visibility='hidden';


	if (rqd==1 && document.getElementById('RQD_'+name)){
		console.log('RQD_'+name+' value: '+value);
		document.getElementById('RQD_'+name).style.color=(value=='')?'red':'blue';
		}
		

	setWpformdata((prev) => {
	return {...prev, startup:false, [name]:value}
	});

//document.getElementsByName(name)[0].value=value;

}//end func



function toggleModalsm() {
const modalsm = document.querySelector(".modalsm");
const trigger = document.querySelector(".trigger");
const closeButton = document.querySelector(".close-button");
	
console.log('modalsm is null: '+(modalsm==null));
modalsm.classList.toggle("show-modalsm");

if (document.querySelector(".trigger"))
	document.querySelector(".trigger").addEventListener("click", toggleModalsm);

if (document.querySelector(".close-button"))
	document.querySelector(".close-button").addEventListener("click", toggleModalsm);

window.addEventListener("click", windowOnClick);
}//end func


function windowOnClick(event) {
const modalsm = document.querySelector(".modalsm");
const trigger = document.querySelector(".trigger");
const closeButton = document.querySelector(".close-button");	
	
    if (event.target === modalsm) {
    	toggleModalsm();
    }
}//end func

function verifyapp(event){

}//end func


function showsnackbar(innerHTML) {
  var x = document.getElementById("snackbar");
  x.innerHTML=innerHTML;
  x.className = "show";
  setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2500);
}//end func



function onFileChange(event) {

	// Update the state
	setAxiofl({ selectedFile: event.target.files[0] });

}//end function


async function openCover (e) {
e.preventDefault();
//const fileName=;
const coverfilepath=wptapp.REF_NUM+"/coverpage.pdf";

    const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {filepath:coverfilepath} )
  };


  fetch(resturl+'rarwpform/getwptappfile',requestOptions)
      .then(res => afteropenCover(res, wptapp.REF_NUM+"_coverpage.pdf"))
      .catch(err => console.log(err));

}//end func

async function afteropenCover(res, fileName){
console.log('afteropenCover');
const blob = await res.blob()
const data = window.URL.createObjectURL(new Blob([blob]))

const link = document.createElement("a");
link.href = data;
link.setAttribute("download", fileName);
document.body.appendChild(link);
link.click();
//link.parentNode!.removeChild(link);	
link.parentNode?.removeChild(link);	
}//end func

async function onFileUpload () {
 // On file upload (click the upload button)
 /*
        // Create an object of formData
        const formData = new FormData();
 
        // Update the formData object
		formData.append('REG_NBR', sad.IDE_REG_SER+' '+sad.IDE_REG_NBR);
		formData.append('file', axiofl.selectedFile, axiofl.selectedFile.name);
 
        // Details of the uploaded file
        console.log(axiofl.selectedFile);
 
        // Request made to the backend api
        // Send formData object
		try{
        await axios.post(resturl+'rarsad/uploadattdoc', formData);
		} catch (error) {
  console.error(error.response.data);     // NOTE - use "error.response.data` (not "error")
  'Authorization': `Bearer ${access_token}`,
 */
 const url=resturl+'rarwpform/uploadxl';
 const FormData = require('form-data'); // npm install --save form-data

const form = new FormData();

form.append('WPT_APP_ID', wptapp.WPT_APP_ID);
form.append('filename', 'Work_Permit_Application_Form_'+wptapp.REF_NUM+'.zip');
form.append('file', axiofl.selectedFile, axiofl.selectedFile.name);
//form.append('filename', axiofl.selectedFile.name);




const request_config = {
    onUploadProgress: data => {
      //Set the progress value to show the progress bard
      setProgress({perct:Math.round((100 * data.loaded) / data.total)});
    },
};
axios.post(url, form, request_config);
}//end function

const attr="val";
//const coverlink="http://localhost:3000/upload/"+wptapp.REF_NUM+"/coverpage.pdf";
const refurl=sessionStorage.getItem("UPLOADURL")+wptapp.REF_NUM+"/";

const username=(sessionStorage.getItem("USERNAME")=='null')?null:sessionStorage.getItem("USERNAME");

  return (
    <>
	
<div key="divctnr" className="container" style={{"backgroundColor":"#F0F0F0", "marginBottom":"36px"}} >
  <div key="divsn1"  className="section snwidth1" >
  
    
  <fieldset className="fieldsetwpformside" >
 <legend style={{marginBottom:"9px"}} >Click Section Names</legend>
 
  <table className='tblsectionnames'  align='center' >
  <tbody>
	  {wpformsections}
  </tbody>
  </table>
  </fieldset>
  {/*section 1*/}
  </div>

  <div key="divsn2" className="section snwidth2"  >
{/*sections :*/}
<table align='left' className="tblsectiontitle"  cellPadding='0' cellSpacing='0' >
<tbody>
<tr>
	<td align='left' >
	{/*
		excel file: <input type="file" onChange={onFileChange}  style={{"width":"80px", "height":"30px"}} />
		&nbsp;&nbsp;
		<button onClick={onFileUpload} >upload file</button>	
	*/}	

	</td>
</tr>

<tr>
	<td align='left' >Section {section.letter}: {section.name}</td>
</tr>
</tbody>
</table>

<p style={{height:"6px"}}>&nbsp;</p>
{/*
<select name='SCTN' value={section.letter} onChange={(e)=>{handleSectionChange(e.target.value);}} key='SCTN' >
<option value='' > * * * sections * * *  </option>
{sectionnames}
</select>
<br/>
{formControls}
*/}

	{formControls}
	<br/>
	<table className="tblbuttons"  >
	<tbody>
	<tr>
	<td align='center' >
	<a href='#' className="nodecor" onClick={(e)=>{e.preventDefault();window.scrollTo(0, 0);}} ><img src='img/uparrow.png' title='scroll to page top' border='0' /> page top</a>
	</td>

	<td align='left' >
	{dowait.show && <div><img src='img/Loading_2.gif' border='0' height='36' /> . . . please wait</div>}
	{
	!dowait.show && 
	<input type='button' className='button' value='save changes' id='btnsavechanges' onClick={(e)=>{updatewpapp(e)}}  />
	}	
	</td>
	
	{wptapp.SUBMITTED_TO_CSC==null && <td align='left' >
	<input type='button' className='button' value='ready to submit application' id='btnsubmitapp' onClick={(e)=>{navSubmitwpform.current?.click();}}   />
	</td>
	}
	
	{/*
	{wptapp.SUBMITTED_TO_CSC!=null && username!=null && <td align='left' >
	<input type='button' className='button' value='ready to export application' id='btnexportapp' onClick={(e)=>{navExportwpform.current?.click();}}   />
	</td>
	}
	
	*/}	
	
	</tr>
	</tbody>
	</table>

  </div>

  <div key="divsn3" className="section snwidth3" >
  
  <fieldset className="fieldsetwpformside" >
 <legend style={{marginBottom:"9px"}} >Application {wptapp.WPT_APP_ID} Info</legend>
	<table  align='center' >
 <tbody>
	<tr>
	<td align='left' colSpan="2"  >Temp. Ref #</td>
	</tr>
	<tr>
	<td align='left' colSpan="2"  style={{paddingLeft:"18px"}} >
	{wptapp.REF_NUM}
	</td>
	</tr>


	<tr>
	<td align='left' colSpan="2" >Last Update</td>
	</tr>
	<tr>
	<td align='left' colSpan="2"  style={{paddingLeft:"18px"}} >
	{wptapp.MODIFY_DATE}
	</td>
	</tr>
	{wptapp.SUBMITTED_TO_CSC!=null && 
		<>
		<tr>
		<td align='left' colSpan="2" >
		{/*<a href="#" onClick={(e)=>{return openCover(e);}} target="_none" style={{textDecoration:"none"}} >Submitted</a>*/}
		Submitted
		</td>
		</tr>
		<tr>
		<td align='left' colSpan="2"  style={{paddingLeft:"18px"}} >
		{wptapp.SUBMITTED_TO_CSC}
		</td>
		</tr>
		</>
		}
	
	{wptapp.SUBMITTED_TO_CSC==null && 
		<tr>
		<td align='center' colSpan="2" >
		<a href='#' id='btnwpuploadxl' className="nodecor" onClick={(e)=>{e.preventDefault(); navImportwpform.current?.click();}} ><img src='img/excel.png' height='24' border='0' /> upload excel file</a> 
		{/*<input type='button' className='button' value='upload excel file' id='btnwpuploadxl' onClick={(e)=>{navImportwpform.current?.click();}}   />*/}
		</td>
		</tr>
		}
		
	<tr>
	<td>
	<a href='#' className="nodecor" onClick={(e)=>{e.preventDefault();window.scrollTo(0, document.body.scrollHeight);}} ><img src='img/downarrow.png' title='scroll to page bottom' border='0' /> page bottom</a>
	</td>
	</tr>
	</tbody>
	
	
	
	
	</table>
  </fieldset>
  </div>
</div>	

        <NavLink ref={navSubmitwpform} to={"/submitwpform"} style={{display:"none"}}
          state={{WPT_APP_ID:wptapp.WPT_APP_ID}}  >submitwpform</NavLink>

        <NavLink ref={navExportwpform} to={"/exportwpform"} style={{display:"none"}}
          state={{WPT_APP_ID:wptapp.WPT_APP_ID, REF_NUM:wptapp.REF_NUM}}  >exportwpform</NavLink>

        <NavLink ref={navImportwpform} to={"/importwpform"} style={{display:"none"}}
          state={{WPT_APP_ID:wptapp.WPT_APP_ID, REF_NUM:wptapp.REF_NUM}}  >importwpform</NavLink>



<div className="modalsm">
    <div className="modalsm-content">
        <span className="close-button">&times;</span>
        <div align='center' id='modalsmtext' >
		<img src='img/ajax_loader.gif' border='0' height='48' /> . . . please wait
		</div>
    </div>
</div>
<div id="snackbar">&nbsp;</div>

    </>
  );
  


};


export default RenderWpForm;