import React, { useState, useEffect } from "react";
//import { confirm } from "react-confirm-box";
import axios from 'axios';
import {Link, NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import $ from 'jquery';

import { useNavigate } from 'react-router-dom'
//var dateFormat = require('dateformat');

	

const RenderWpForm = () => {



/*
had an error with sessionStorage.getItem("RESTURL") returning null 
when below declaration was before this const RenderWpForm
*/
const resturl=sessionStorage.getItem("RESTURL");
//var boolFetchingSads=false;


//getresturl();

const navigate = useNavigate();

window.addEventListener("keypress", function (event) {
 // detect event keys
 if (event.keyCode == 13) {
   //callsadfinder();
   
 }
});


const navWpform = React.useRef();
const navWpvouchers = React.useRef();

const [wptapp, setWptapp] = useState({WPT_APP_ID:''});
const [tblinvoices, setTblinvoices] = useState([]);
const [linkwpform, setLinkwpform] = useState({WPT_APP_ID:0});
const [linkvouchers, setLinkvouchers] = useState({WPT_APP_ID:0});
const [searchcrit, setSearchcrit] = useState({txt:''});
const startupMins=5;//here on test page this will be 10 seconds
const [reload, setReload] = useState({mins:startupMins});



const location = useLocation();
let reloadTimer=null;
//var currmins=10;
const timerEvent = () => {
	
	const currmins=1*(document.getElementsByName("inputminsremain")[0].value)-1;
	if (currmins>=0)
		setReload({mins:currmins});
	else{
		
		setReload({mins:startupMins});
	}
		

/*
	setReload((prev)=>{
		
		console.log("currmins: "+currmins); 
		return {...prev, mins:currmins}
		});

*/

	};

useEffect(() => {
//console.log('useEffect resturl: '+sessionStorage.getItem("RESTURL"));

//return () => clearInterval(reloadTimer);
if (tblinvoices.length<1){
	gettblinvoices();
	}//end if
	
	
 },[]);



useEffect(() => {

if (reload.mins==startupMins)
	gettblinvoices();
	
 },[reload.mins]);

useEffect(() => {


	console.log("reloadTimer: "+reloadTimer);
	if (reloadTimer==null)
		reloadTimer = setInterval(timerEvent, 1000*60);//*60
	else{

		}//end else

    // Stops the old timer when umount the component.
    return function stopTimer() {
      clearInterval(reloadTimer);
    }
		
		
	//console.log("searchcrit: "+document.getElementsByName('searchcrit')[0].value);
	document.getElementsByName('searchcrit')[0].focus();

	
 },[JSON.stringify(tblinvoices)]);


 {
 useEffect(() => {
	if (linkwpform.WPT_APP_ID>0)
		navWpform.current?.click();
 },[linkwpform]);

 }

useEffect(() => {
	if (linkvouchers.WPT_APP_ID>0)
		navWpvouchers.current?.click();
 },[linkvouchers]);

useEffect(() => {
		gettblinvoices();
 },[searchcrit]);

function searchcritChange(e){
	setSearchcrit({txt:e.target.value});
}//end if


function getresturl(){
console.log('getresturl( location.state: '+JSON.stringify(location.state))	;
for(var attributename in location.state){
	if (attributename=='resturl')
		return location.state.resturl;
	}//end for	
return null;	
}//end func



function showsnackbar(innerHTML) {
  var x = document.getElementById("snackbar");
  x.innerHTML=innerHTML;
  x.className = "show";
  setTimeout(function(){ x.className = x.className.replace("show", ""); }, 2500);
}//end func



function gettblinvoices(){
//const resturl=sessionStorage.getItem("RESTURL");
//console.log('resturl: '+resturl);
   const requestOptions = {
      method: 'POST'
	  ,headers: { 'Content-Type': 'application/json' }
	  ,body: JSON.stringify( {EMAIL:sessionStorage.getItem("USERNAME")} )
  };		

  fetch(resturl+'rarwpform/gettblinvoices',requestOptions)
      .then(res => res.json())
      .then(res => 	aftergettblinvoices(res) )
      .catch(err => console.log(err));

}//end func


function viewwpform(e, WPT_APP_ID){
console.log('viewwpform WPT_APP_ID: '+WPT_APP_ID);
e.preventDefault();
setLinkwpform({WPT_APP_ID:WPT_APP_ID});
	{/*
	setLinkwpform();
if (navWpform.current)
	navWpform.state={WPT_APP_ID:WPT_APP_ID};
navWpform.current?.click();

	*/}

}//end func


function aftergettblinvoices(res){
if (res.length==undefined)
	return;

	
console.log('aftergettblinvoices, res.length: '+res.length);

let htmrows=[];
res.map((col, index) => {
	const appid=col['WPT_APP_ID'];
	const refnum=col['REF_NUM'];
	const trkey='tr_'+appid;
	//const rdkey='rd_'+appid;
	//const refnumLink=<a href="#" className="nodecor" onClick={(e)=>{handleinvoiceChange(e, appid, CORPORATE, CASE_WORKER);}} ><img src='img/folderyel.png' height='18' border='0' />&nbsp;{refnum}<span className='tooltiptext'>open this application</span></a>;
	//const voucherLink=<a href="#" className="nodecor" onClick={(e)=>{vouchersLink(e, appid);}} ><img src='img/folderyel.png' height='18' border='0' />&nbsp;{VOUCHERS_TOTAL_AMT}<span className='tooltiptext'>vouchers and payment</span></a>;
	
	//const pdflink=sessionStorage.getItem("UPLOADURL")+refnum+"/coverpage.pdf";	
	
	
	const htmrow=<tr key={trkey} >
	<td align='left' className='tooltip' >&nbsp;</td>
	<td align='left' >{col['VCHR_TYPE']}</td>
	<td align='left' >{col['DOC_NO']}</td>
	<td align='left' >{col['AMOUNT']}</td>
	<td align='left' >{col['MPE_NAME']}</td>
	<td align='left' >{col['MPR_NAME']}</td>
	<td align='left' >{col['STATUS']}</td>
		{/*<td align='right' className="tooltip" >{voucherLink}&nbsp;</td>*/}
	<td align='center' >{col['ACCT_DESCP_1']}</td>
	<td align='center' className="tooltip" >
	<span className='tooltiptext'>view the applicaiton form fields</span>
	<a href='#' onClick={(e)=>{viewwpform(e, appid);}} className="nodecor" >{col['STR_CREATE_DATE']}</a>   
	</td>
	<td align='left'  >{col['LBR_CLR_REF']}</td>
	</tr>;
	htmrows.push(htmrow);
});

setTblinvoices(htmrows);
}//end func


function vouchersLink(e, appid){
	console.log('setLinkvouchers '+appid);
	e.preventDefault();
	setLinkvouchers({WPT_APP_ID:appid});

}//end func


function handleinvoiceChange(e){
	e.preventDefault();
	

}//end func


function toggleModalsm() {
const modalsm = document.querySelector(".modalsm");
const trigger = document.querySelector(".trigger");
const closeButton = document.querySelector(".close-button");
	
console.log('modalsm is null: '+(modalsm==null));
modalsm.classList.toggle("show-modalsm");

if (document.querySelector(".trigger"))
	document.querySelector(".trigger").addEventListener("click", toggleModalsm);

if (document.querySelector(".close-button"))
	document.querySelector(".close-button").addEventListener("click", toggleModalsm);

window.addEventListener("click", windowOnClick);
}//end func

function windowOnClick(event) {
const modalsm = document.querySelector(".modalsm");
const trigger = document.querySelector(".trigger");
const closeButton = document.querySelector(".close-button");	
	
    if (event.target === modalsm) {
    	toggleModalsm();
    }
}//end func
	
  return (
    <>
	
<table align='center' width="75%" style={{marginTop:"10px", marginBottom:"10px"}}>
<tbody>
<tr>
<td align='center'>&nbsp;</td>
<td align='center'>search criteria:&nbsp;<input name="searchcrit" defaultValue={searchcrit.txt} autoComplete="off" onChange={(e)=>{searchcritChange(e);}} onFocus={(e)=>{e.target.select();}} placeholder='any name / ref # / job title' /></td>
<td align='center' className='tooltip' ><input size="2" name="inputminsremain" value={reload.mins} style={{textAlign:"center"}} readOnly className="gnsbro" /><span className='tooltiptext'>minutes before reload</span></td>
</tr>
</tbody>
</table>
	
	<table align='center' className='customers' style={{width:"90%"}} >
	<thead>
	<tr>
		<th>WP portal ref #</th>
		<th>agency</th>
		<th>employer</th>
		<th>employer phone #</th>
		<th>applicant</th>
		<th>job title</th>
		<th>vouchers<br/>status</th>
		<th>last update</th>
		<th>submitted to<br/>customer service</th>
		<th>immigration<br/>reference #</th>
	</tr>
	</thead>
	<tbody>
		{tblinvoices}
	</tbody>
	</table>


	<div className="modalsm">
		<div className="modalsm-content">
			<span className="close-button">&times;</span>
			<div align='center' id='modalsmtext' >
			
			<p>you are not currently the case worker assigned to this application</p>
			<p>click the second button below if you will be the case worker assigned</p>
			<p>
			<button className="button" onClick={(e)=>{ toggleModalsm(); }} >Cancel</button>
			&nbsp;&nbsp;
			
			</p>
			
			</div>
		</div>
	</div>

<div id="snackbar">&nbsp;</div>
          <NavLink
		  ref={navWpform}
          to={"/wpform"}
		  style={{display:"none"}}
          state={{
		  WPT_APP_ID:linkwpform.WPT_APP_ID
		  }} 
        >
		&nbsp;click here to wpform
        </NavLink>

          <NavLink
		  ref={navWpvouchers}
          to={"/wpvouchers"}
		  style={{display:"none"}}
          state={{
		  WPT_APP_ID:linkvouchers.WPT_APP_ID
		  }} 
        >
		&nbsp;click here to vouchers
        </NavLink>
		{/*
		
        <NavLink ref={navWpform} to={"/wpform"} style={{display:"none"}}
          state={{WPT_APP_ID:0}}  >wpform</NavLink>
		*/}



    </>
  );
  


};


export default RenderWpForm;